
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { OrderItemDto, ProductDto } from '@/castapi';
import CartItem from '@/components/shared/cart/CartItem.vue';
import { VForm } from '@/shared/rules/rules';

@Component({
  components: {
    CartItem,
  },
})
export default class RenewUpgradeDialog extends Vue {
  @Prop(Boolean) dialogVisible!: false;
  @Prop({ default: () => [] }) renewOptions!: Array<ProductDto>;
  @Prop({ default: () => [] }) upgradeOptions!: Array<ProductDto>;
  @Prop(Boolean) loading!: false;
  @Prop() error!: null;
  @Prop() currentDongleLicenseTooNew!: false;
  @Prop() currentDongleLicenseTooOld!: false;
  @Prop() currentDongleLicenseNeedsExpirationBeforeUpgrade!: false;
  @Prop() isRenewPossible!: boolean;
  @Prop() isUpgradePossible!: boolean;
  @Prop() renewalsRequired!: number;
  @Prop() renewalsAvailable!: number;
  @Prop() isLease!: boolean;
  @Prop() dongleCode!: string;
  @Prop() expireDateLoading!: boolean;
  @Prop() expireDateLoadError!: null | Error;
  @Prop() expireDate!: null | {
    newExpireDate: string;
    isObsolete: boolean;
  };

  private formValid = true;
  private selectedProductId: null | number = null;
  private selectedProduct: null | (OrderItemDto & { productId?: number }) = null;

  private optionsCount = {};

  get availableOptions() {
    return [
      ...this.renewOptions.map(option => ({
        item: {
          ...option,
          itemCount: this.optionsCount[option.productId],
        },
        countAvailable: this.renewalsAvailable,
        countChangeAvailable: true,
      })),
      ...this.upgradeOptions.map(option => ({
        item: {
          ...option,
          itemCount: this.optionsCount[option.productId],
        },
        countAvailable: 1,
        countChangeAvailable: false,
      })),
    ];
  }

  get availableProducts() {
    return [...this.renewOptions, ...this.upgradeOptions];
  }

  get showDialog(): boolean {
    return this.dialogVisible;
  }

  set showDialog(value: boolean) {
    if (!value) {
      this.$emit('close');
    }
  }

  get countAvailable() {
    return this.availableOptions.find(option => option.item.productId === this.selectedProduct?.productId)
      ?.countAvailable;
  }

  get countChangeAvailable() {
    return this.availableOptions.find(option => option.item.productId === this.selectedProduct?.productId)
      ?.countChangeAvailable;
  }

  @Watch('dialogVisible')
  visibilityChanged() {
    this.selectedProduct = null;
  }

  @Watch('availableProducts')
  availableOptionsChanged() {
    this.reCalculateOptionsCount();
    this.selectedProductId = this.availableProducts.length ? this.availableProducts[0].productId : null;
    this.selectedProduct = null;
  }

  @Watch('selectedProduct')
  selectedProductChanged() {
    this.$emit('productChanged', this.selectedProduct);
  }

  reCalculateOptionsCount() {
    this.optionsCount = {
      ...this.renewOptions.reduce((acc, curr) => ({ ...acc, [curr.productId]: this.renewalsRequired || 1 }), {}),
      ...this.upgradeOptions.reduce((acc, curr) => ({ ...acc, [curr.productId]: 1 }), {}),
    };
  }

  closeDialog(): void {
    (this.$refs.form as VForm).reset();
    this.showDialog = false;
  }

  changeProductCount(item) {
    this.optionsCount[item.productId] = item.itemCount;
    this.selectedProductChanged();
  }

  selectOption() {
    if (this.selectedProduct) {
      this.$emit('checkout', this.selectedProduct);
    }
  }

  selectItem() {
    this.selectedProduct =
      this.availableOptions.find(option => option.item.productId === this.selectedProductId)?.item || null;
  }

  removeItem() {
    this.selectedProduct = null;
  }

  created() {
    this.reCalculateOptionsCount();
  }
}
