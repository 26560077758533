
import { Component, Vue } from 'vue-property-decorator';
import ProductItem from './ProductItem.vue';
import { ProductWideDto } from '@/castapi';

@Component({
  components: {
    ProductItem,
  },
})
// eslint-disable-next-line vue/multi-word-component-names
export default class Front extends Vue {
  private tab = '';

  get productsMap(): Record<string, ProductWideDto[]> {
    return this.$store.getters['shop/productsMap'];
  }

  get productTypes(): Array<string> {
    return this.$store.getters['shop/productTypes'];
  }

  get productsLoading(): boolean {
    return this.$store.getters['shop/productsLoading'];
  }

  get productsLoadError() {
    return this.$store.getters['shop/productsLoadError'];
  }

  get user() {
    return this.$store.getters['login/user'];
  }

  get isOrderRenewUpgrade() {
    return this.$store.getters['shop/isOrderRenewUpgrade'];
  }

  async created() {
    if (this.isOrderRenewUpgrade) {
      this.$store.commit('shop/CLEAR_ORDER');
    }
    await this.$store.dispatch('shop/getProductsFromApi');
  }
}
