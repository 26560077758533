
import { Component, Prop, Vue } from 'vue-property-decorator';
import EventBus from '@/plugins/EventBus';
import { VForm } from '@/shared/rules/rules';
import { DiscountDto } from '@/castapi';

@Component({
  watch: {
    async grandTotalPrice(grandTotal) {
      if (this.$props.totalPrice && this.$props.actionOnTotalChange) {
        await this.$store.dispatch(this.$props.actionOnTotalChange, grandTotal);
      }
    },
  },
})
export default class Subtotal extends Vue {
  @Prop({ default: 0 }) totalPrice!: number;
  @Prop({ default: 0 }) tax!: number;
  @Prop({ default: 0 }) shipping!: number;
  @Prop({ default: 0 }) discount!: number;
  @Prop({ default: false }) disabled!: boolean;
  @Prop() actionOnTotalChange!: string;
  @Prop({ default: false }) hideDiscountInput!: boolean;
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: null }) message!: null | { isError: boolean; text: string };
  @Prop({ default: false }) isRenew!: boolean;
  @Prop({ default: true }) showShippingRate!: boolean;
  @Prop({ default: false }) isAdmin!: boolean;
  @Prop({ default: () => [] }) discounts!: DiscountDto[];

  private formValid = true;
  private discountCode = '';
  private promoCodeError = false;

  get grandTotalPrice(): number {
    return this.totalPrice + this.tax + this.shipping - this.discount;
  }

  get discountPromoCode() {
    if (this.isAdmin) {
      return this.$store.getters['adminOrders/discountPromoCode'];
    }
    return this.$store.getters['shop/discountPromoCode'];
  }

  async applyDiscountCode() {
    if (this.isAdmin) {
      await this.$store.dispatch('adminOrders/applyDiscount', this.discountCode);
    } else {
      await this.$store.dispatch('shop/applyDiscount', this.discountCode);
    }
  }

  async dropDiscount() {
    if (this.isAdmin) {
      await this.$store.dispatch('adminOrders/dropDiscount', null);
      this.discountCode = '';
    } else {
      await this.$store.dispatch('shop/dropDiscount', null);
    }
  }

  mounted() {
    EventBus.$on('clearDiscount', () => {
      (this.$refs.form as VForm).reset();
      this.discountCode = '';
      this.promoCodeError = false;
    });
  }

  beforeDestroy() {
    EventBus.$off('clearDiscount');
  }
}
