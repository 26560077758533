import Vue from 'vue';

const filters = Vue.filter('currency', (value: number) => {
  const val = value ? value / 100 : 0;
  return `${val.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
});

export default filters;
