/* tslint:disable */
/* eslint-disable */
/**
 * Cast.NEXT
 * The Cast.NEXT API description
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    addressId: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    city: string;
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    countryRef: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    apt: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    province: string;
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    organizationRef: number;
    /**
     * 
     * @type {boolean}
     * @memberof AddressDto
     */
    isBilling: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddressDto
     */
    isDefault: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AddressDto
     */
    isDeleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface AdminDemoDto
 */
export interface AdminDemoDto {
    /**
     * Id of admin user, that created discount
     * @type {number}
     * @memberof AdminDemoDto
     */
    createdBy: number;
    /**
     * Demo id
     * @type {number}
     * @memberof AdminDemoDto
     */
    demoId: number;
    /**
     * Description
     * @type {string}
     * @memberof AdminDemoDto
     */
    description: string;
    /**
     * Download Path
     * @type {string}
     * @memberof AdminDemoDto
     */
    downloadPath: string;
    /**
     * Display name
     * @type {string}
     * @memberof AdminDemoDto
     */
    name: string;
    /**
     * Product line id
     * @type {number}
     * @memberof AdminDemoDto
     */
    productLineRef: number;
}
/**
 * 
 * @export
 * @interface ApiUsersChangeUserRolesRoles
 */
export interface ApiUsersChangeUserRolesRoles {
    /**
     * 
     * @type {number}
     * @memberof ApiUsersChangeUserRolesRoles
     */
    userRoleId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiUsersChangeUserRolesRoles
     */
    value?: boolean;
}
/**
 * 
 * @export
 * @interface AssignDongleDto
 */
export interface AssignDongleDto {
    /**
     * user id
     * @type {number}
     * @memberof AssignDongleDto
     */
    userId: number;
    /**
     * dongle id
     * @type {number}
     * @memberof AssignDongleDto
     */
    dongleId: number;
}
/**
 * 
 * @export
 * @interface ChangeDonglesResponseDto
 */
export interface ChangeDonglesResponseDto {
    /**
     * Dongle not changed numbers
     * @type {Array<string>}
     * @memberof ChangeDonglesResponseDto
     */
    dongleNotChangedNumbers: Array<string>;
    /**
     * Dongle changed numbers
     * @type {Array<string>}
     * @memberof ChangeDonglesResponseDto
     */
    dongleChangedNumbers: Array<string>;
    /**
     * Duplicated numbers
     * @type {Array<string>}
     * @memberof ChangeDonglesResponseDto
     */
    duplicatedNumbers: Array<string>;
}
/**
 * 
 * @export
 * @interface ComplexDto
 */
export interface ComplexDto {
    /**
     * Not used
     * @type {string}
     * @memberof ComplexDto
     */
    previouscrypkey: string;
    /**
     * wysiwyg product Level
     * @type {string}
     * @memberof ComplexDto
     */
    productlvl: string;
    /**
     * Vivien product Level
     * @type {string}
     * @memberof ComplexDto
     */
    vivproductlvl: string;
    /**
     * Not used
     * @type {string}
     * @memberof ComplexDto
     */
    sd: string;
    /**
     * Dongle serial code
     * @type {string}
     * @memberof ComplexDto
     */
    serialcode: string;
    /**
     * Dongle serial number
     * @type {string}
     * @memberof ComplexDto
     */
    serialnumber: string;
    /**
     * Utility source
     * @type {string}
     * @memberof ComplexDto
     */
    source: string;
    /**
     * Not used
     * @type {string}
     * @memberof ComplexDto
     */
    sp66hwsupport: string;
    /**
     * Dongle type
     * @type {string}
     * @memberof ComplexDto
     */
    type: string;
    /**
     * Moa URL
     * @type {string}
     * @memberof ComplexDto
     */
    url: string;
    /**
     * Stepping
     * @type {string}
     * @memberof ComplexDto
     */
    expire: string;
    /**
     * Expiry date
     * @type {string}
     * @memberof ComplexDto
     */
    expiredate: string;
    /**
     * Cheat counter
     * @type {string}
     * @memberof ComplexDto
     */
    cc: string;
    /**
     * On lease
     * @type {string}
     * @memberof ComplexDto
     */
    onlease: string;
    /**
     * Dongle serial number
     * @type {string}
     * @memberof ComplexDto
     */
    number: string;
    /**
     * Dongle serial code
     * @type {string}
     * @memberof ComplexDto
     */
    code: string;
    /**
     * Request id
     * @type {string}
     * @memberof ComplexDto
     */
    req: string;
    /**
     * Dongle reauth code
     * @type {string}
     * @memberof ComplexDto
     */
    reauth: string;
    /**
     * Dongle stepping number
     * @type {string}
     * @memberof ComplexDto
     */
    stepping: string;
    /**
     * Dongle serial code
     * @type {string}
     * @memberof ComplexDto
     */
    serialCode: string;
    /**
     * Dongle serial number
     * @type {string}
     * @memberof ComplexDto
     */
    serialNumber: string;
    /**
     * wysiwyg level
     * @type {string}
     * @memberof ComplexDto
     */
    ProductLevel: string;
    /**
     * Dongle reauth code
     * @type {string}
     * @memberof ComplexDto
     */
    reauthCode: string;
    /**
     * Dongle stepping
     * @type {string}
     * @memberof ComplexDto
     */
    Stepping: string;
    /**
     * Dongle emergency code
     * @type {string}
     * @memberof ComplexDto
     */
    emergencyCode: string;
    /**
     * Is lease to perpetual?
     * @type {string}
     * @memberof ComplexDto
     */
    leasetofull: string;
    /**
     * Upgrade code
     * @type {string}
     * @memberof ComplexDto
     */
    activationcode: string;
    /**
     * email
     * @type {string}
     * @memberof ComplexDto
     */
    email: string;
    /**
     * Locking code
     * @type {string}
     * @memberof ComplexDto
     */
    lockingcode: string;
    /**
     * Membership renewal  code
     * @type {string}
     * @memberof ComplexDto
     */
    subscriptioncode: string;
    /**
     * Lease renewal  code
     * @type {string}
     * @memberof ComplexDto
     */
    timingvalcode: string;
    /**
     * Lease renewal type
     * @type {string}
     * @memberof ComplexDto
     */
    leaserenewaltype: string;
    /**
     * reset lease elvc count
     * @type {string}
     * @memberof ComplexDto
     */
    resetleaseelvccount: string;
    /**
     * type of upgrade
     * @type {string}
     * @memberof ComplexDto
     */
    wygactivationtype: string;
    /**
     * type of license change
     * @type {string}
     * @memberof ComplexDto
     */
    licensemodelchange: string;
}
/**
 * 
 * @export
 * @interface CountryDto
 */
export interface CountryDto {
    /**
     * Id of the country
     * @type {number}
     * @memberof CountryDto
     */
    id: number;
    /**
     * Native name of the country
     * @type {string}
     * @memberof CountryDto
     */
    _native: string;
    /**
     * Name of the country
     * @type {string}
     * @memberof CountryDto
     */
    name: string;
    /**
     * Region
     * @type {string}
     * @memberof CountryDto
     */
    region: string;
    /**
     * SubRegion
     * @type {string}
     * @memberof CountryDto
     */
    subregion: string;
    /**
     * Last updated
     * @type {string}
     * @memberof CountryDto
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface CreateAccountDto
 */
export interface CreateAccountDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountDto
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountDto
     */
    organizationEmail: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountDto
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountDto
     */
    organizationPhoneNumber: string;
    /**
     * User country id
     * @type {number}
     * @memberof CreateAccountDto
     */
    countryRef: number;
}
/**
 * 
 * @export
 * @interface CreateAddressDto
 */
export interface CreateAddressDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    city: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAddressDto
     */
    countryRef: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    apt: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    province: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAddressDto
     */
    organizationRef: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAddressDto
     */
    isBilling: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAddressDto
     */
    isDefault: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAddressDto
     */
    isDeleted: boolean;
}
/**
 * 
 * @export
 * @interface CreateDiscountDto
 */
export interface CreateDiscountDto {
    /**
     * Discount description
     * @type {string}
     * @memberof CreateDiscountDto
     */
    description: string;
    /**
     * Discount types are percentage or amount
     * @type {number}
     * @memberof CreateDiscountDto
     */
    discountValue: number;
    /**
     * End date
     * @type {string}
     * @memberof CreateDiscountDto
     */
    endDate: string;
    /**
     * Limit of discount usage 0-10 or unlimited
     * @type {number}
     * @memberof CreateDiscountDto
     */
    limit: number;
    /**
     * Product to apply the discount
     * @type {number}
     * @memberof CreateDiscountDto
     */
    productTypeId: number;
    /**
     * discount promotion code
     * @type {string}
     * @memberof CreateDiscountDto
     */
    promoCode: string;
    /**
     * Start date
     * @type {string}
     * @memberof CreateDiscountDto
     */
    startDate: string;
}
/**
 * 
 * @export
 * @interface CreateDongleDto
 */
export interface CreateDongleDto {
    /**
     * Dongle Serial Number
     * @type {string}
     * @memberof CreateDongleDto
     */
    serialNumber: string;
    /**
     * Dongle Serial Code
     * @type {string}
     * @memberof CreateDongleDto
     */
    serialCode: string;
    /**
     * Date of dongle purchase
     * @type {string}
     * @memberof CreateDongleDto
     */
    purchaseDate: string;
}
/**
 * 
 * @export
 * @interface CreateDongleEmergencyCodeDto
 */
export interface CreateDongleEmergencyCodeDto {
    /**
     * Dongle serial number
     * @type {string}
     * @memberof CreateDongleEmergencyCodeDto
     */
    serialNumber: string;
    /**
     * Dongle serial code
     * @type {string}
     * @memberof CreateDongleEmergencyCodeDto
     */
    serialCode: string;
}
/**
 * 
 * @export
 * @interface CreateFeedbackDto
 */
export interface CreateFeedbackDto {
    /**
     * Feedback title
     * @type {string}
     * @memberof CreateFeedbackDto
     */
    feedbackTitle: string;
    /**
     * Feedback body
     * @type {string}
     * @memberof CreateFeedbackDto
     */
    feedbackText: string;
}
/**
 * 
 * @export
 * @interface CreateOrderDto
 */
export interface CreateOrderDto {
    /**
     * orderId
     * @type {number}
     * @memberof CreateOrderDto
     */
    orderId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    apt?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    province?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderDto
     */
    countryRef?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    billingFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    billingLastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    billingPostalCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    billingAddress1: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    billingApt?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    billingCity: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    companyName: string;
    /**
     * Organization Id
     * @type {number}
     * @memberof CreateOrderDto
     */
    organizationRef: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderDto
     */
    billingCountryRef?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    billingProvince?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderDto
     */
    refPromo?: number;
    /**
     * Id of the user doing purchase
     * @type {number}
     * @memberof CreateOrderDto
     */
    userRef?: number;
    /**
     * Id of the admin user, creating order
     * @type {number}
     * @memberof CreateOrderDto
     */
    adminRef?: number;
    /**
     * Notes
     * @type {string}
     * @memberof CreateOrderDto
     */
    notes?: string;
    /**
     * Order for renew/upgrade dongle product
     * @type {boolean}
     * @memberof CreateOrderDto
     */
    isRenewUpgrade?: boolean;
    /**
     * Dongle id for renew/upgrade
     * @type {number}
     * @memberof CreateOrderDto
     */
    existingDongleRef?: number;
    /**
     * 
     * @type {Array<OrderItemDto>}
     * @memberof CreateOrderDto
     */
    orderItems: Array<OrderItemDto>;
}
/**
 * 
 * @export
 * @interface CreateReleaseDto
 */
export interface CreateReleaseDto {
    /**
     * Release name
     * @type {string}
     * @memberof CreateReleaseDto
     */
    name: string;
    /**
     * Reference to `product_line`
     * @type {number}
     * @memberof CreateReleaseDto
     */
    refProductType: number;
    /**
     * Reference to minimal stepping
     * @type {number}
     * @memberof CreateReleaseDto
     */
    refMinStepping: number;
}
/**
 * 
 * @export
 * @interface CreateUploadDto
 */
export interface CreateUploadDto {
    /**
     * Release id
     * @type {number}
     * @memberof CreateUploadDto
     */
    releaseId: number;
    /**
     * release title
     * @type {string}
     * @memberof CreateUploadDto
     */
    title: string;
    /**
     * Build number
     * @type {string}
     * @memberof CreateUploadDto
     */
    subtitle: string;
    /**
     * Build number
     * @type {string}
     * @memberof CreateUploadDto
     */
    url: string;
    /**
     * Description
     * @type {string}
     * @memberof CreateUploadDto
     */
    description: string;
    /**
     * Just open link (don\'t download)
     * @type {boolean}
     * @memberof CreateUploadDto
     */
    isJustOpen: boolean;
}
/**
 * 
 * @export
 * @interface DemoDto
 */
export interface DemoDto {
    /**
     * Id of admin user, that created discount
     * @type {number}
     * @memberof DemoDto
     */
    createdBy: number;
    /**
     * Demo id
     * @type {number}
     * @memberof DemoDto
     */
    demoId: number;
    /**
     * Description
     * @type {string}
     * @memberof DemoDto
     */
    description: string;
    /**
     * Display name
     * @type {string}
     * @memberof DemoDto
     */
    name: string;
    /**
     * Product line id
     * @type {number}
     * @memberof DemoDto
     */
    productLineRef: number;
}
/**
 * 
 * @export
 * @interface DemoLeadCreateDto
 */
export interface DemoLeadCreateDto {
    /**
     * Downloaded demo id
     * @type {number}
     * @memberof DemoLeadCreateDto
     */
    demoRef: number;
    /**
     * Agree to receive Blacktrax newsletter
     * @type {boolean}
     * @memberof DemoLeadCreateDto
     */
    isBlackTraxNewsLetter: boolean;
    /**
     * Agree to receive Cast newsletter
     * @type {boolean}
     * @memberof DemoLeadCreateDto
     */
    isCastNewsLetter: boolean;
    /**
     * Agree to receive Vivien/Wysiwyg newsletter
     * @type {boolean}
     * @memberof DemoLeadCreateDto
     */
    isPackageNewsLetter: boolean;
    /**
     * Lead organization id
     * @type {number}
     * @memberof DemoLeadCreateDto
     */
    organizationRef: number;
    /**
     * Profession Id
     * @type {number}
     * @memberof DemoLeadCreateDto
     */
    professionRef: number;
}
/**
 * 
 * @export
 * @interface DemoLeadDto
 */
export interface DemoLeadDto {
    /**
     * Lead id
     * @type {number}
     * @memberof DemoLeadDto
     */
    demoLeadId: number;
    /**
     * Downloaded demo id
     * @type {number}
     * @memberof DemoLeadDto
     */
    demoRef: number;
    /**
     * Download date
     * @type {string}
     * @memberof DemoLeadDto
     */
    entryDate: string;
    /**
     * Agree to receive Blacktrax newsletter
     * @type {boolean}
     * @memberof DemoLeadDto
     */
    isBlackTraxNewsLetter: boolean;
    /**
     * Agree to receive Cast newsletter
     * @type {boolean}
     * @memberof DemoLeadDto
     */
    isCastNewsLetter: boolean;
    /**
     * Agree to receive Vivien/Wysiwyg newsletter
     * @type {boolean}
     * @memberof DemoLeadDto
     */
    isPackageNewsLetter: boolean;
    /**
     * Lead organization id
     * @type {number}
     * @memberof DemoLeadDto
     */
    organizationRef: number;
    /**
     * Profession Id
     * @type {number}
     * @memberof DemoLeadDto
     */
    professionRef: number;
    /**
     * User id
     * @type {number}
     * @memberof DemoLeadDto
     */
    userRef: number;
}
/**
 * 
 * @export
 * @interface DemoUpdateDto
 */
export interface DemoUpdateDto {
    /**
     * Description
     * @type {string}
     * @memberof DemoUpdateDto
     */
    description: string;
    /**
     * Download Path
     * @type {string}
     * @memberof DemoUpdateDto
     */
    downloadPath: string;
}
/**
 * 
 * @export
 * @interface DiscountDto
 */
export interface DiscountDto {
    /**
     * Id of the discount
     * @type {number}
     * @memberof DiscountDto
     */
    discountId: number;
    /**
     * discount promotion code
     * @type {string}
     * @memberof DiscountDto
     */
    promoCode: string;
    /**
     * Start date
     * @type {string}
     * @memberof DiscountDto
     */
    startDate: string;
    /**
     * End date
     * @type {string}
     * @memberof DiscountDto
     */
    endDate: string;
    /**
     * Discount types are percentage or amount
     * @type {number}
     * @memberof DiscountDto
     */
    discountValue: number;
    /**
     * Limit of discount usage 0-10 or unlimited
     * @type {number}
     * @memberof DiscountDto
     */
    limit: number;
    /**
     * Product to apply the discount
     * @type {number}
     * @memberof DiscountDto
     */
    productTypeId: number;
    /**
     * Discount description
     * @type {string}
     * @memberof DiscountDto
     */
    description: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof DiscountDto
     */
    productsBinding: Array<object>;
    /**
     * Number of usage of discount
     * @type {number}
     * @memberof DiscountDto
     */
    usageCount: number;
}
/**
 * 
 * @export
 * @interface DiscountProductTypeDto
 */
export interface DiscountProductTypeDto {
    /**
     * Id of the discount product type
     * @type {number}
     * @memberof DiscountProductTypeDto
     */
    discountProductTypeId: number;
    /**
     * Description of the discount product type
     * @type {string}
     * @memberof DiscountProductTypeDto
     */
    description: string;
    /**
     * Code of the discount product type
     * @type {string}
     * @memberof DiscountProductTypeDto
     */
    discountProductTypeCode: string;
}
/**
 * 
 * @export
 * @interface DongleActivityCodeDto
 */
export interface DongleActivityCodeDto {
    /**
     * 
     * @type {string}
     * @memberof DongleActivityCodeDto
     */
    changeType: string;
    /**
     * 
     * @type {string}
     * @memberof DongleActivityCodeDto
     */
    changeCode: string;
}
/**
 * 
 * @export
 * @interface DongleActivityDto
 */
export interface DongleActivityDto {
    /**
     * Dongle id
     * @type {number}
     * @memberof DongleActivityDto
     */
    dongleId: number;
    /**
     * Dongle id
     * @type {number}
     * @memberof DongleActivityDto
     */
    order: number;
    /**
     * Dongle history id
     * @type {number}
     * @memberof DongleActivityDto
     */
    historyId: number;
    /**
     * Dongle change date
     * @type {string}
     * @memberof DongleActivityDto
     */
    changeDate: string;
    /**
     * Dongle renew/upgrade/reset code
     * @type {Array<DongleActivityCodeDto>}
     * @memberof DongleActivityDto
     */
    code: Array<DongleActivityCodeDto>;
    /**
     * Username of user who updated the dongle
     * @type {string}
     * @memberof DongleActivityDto
     */
    updatedByUsername: string;
    /**
     * Id of dongle update type
     * @type {number}
     * @memberof DongleActivityDto
     */
    updateTypeId: number;
    /**
     * Name of dongle update type
     * @type {string}
     * @memberof DongleActivityDto
     */
    updateTypeName: string;
    /**
     * Utility used to generate the code
     * @type {string}
     * @memberof DongleActivityDto
     */
    modifiedSource: string;
}
/**
 * 
 * @export
 * @interface DongleDto
 */
export interface DongleDto {
    /**
     * Dongle id
     * @type {number}
     * @memberof DongleDto
     */
    dongleId: number;
    /**
     * Dongle Serial Number
     * @type {string}
     * @memberof DongleDto
     */
    serialNumber: string;
    /**
     * Dongle Serial Code
     * @type {string}
     * @memberof DongleDto
     */
    serialCode: string;
    /**
     * Dongle status
     * @type {string}
     * @memberof DongleDto
     */
    status: string;
    /**
     * Date of dongle purchase
     * @type {string}
     * @memberof DongleDto
     */
    purchaseDate: string;
    /**
     * Is dongle loaned
     * @type {boolean}
     * @memberof DongleDto
     */
    isLoaned: boolean;
    /**
     * Is dongle retired
     * @type {boolean}
     * @memberof DongleDto
     */
    isRetired: boolean;
    /**
     * Dongle notes
     * @type {string}
     * @memberof DongleDto
     */
    notes?: string;
    /**
     * Is dongle active
     * @type {boolean}
     * @memberof DongleDto
     */
    isActive: boolean;
    /**
     * Dongle expiry date
     * @type {string}
     * @memberof DongleDto
     */
    expiryDate: string;
    /**
     * Assigned user
     * @type {number}
     * @memberof DongleDto
     */
    userRef: number;
    /**
     * Dongle owner id
     * @type {number}
     * @memberof DongleDto
     */
    ownerRef: number;
    /**
     * Dongle product reference
     * @type {number}
     * @memberof DongleDto
     */
    productRef?: number;
    /**
     * Dongle subproduct line reference
     * @type {number}
     * @memberof DongleDto
     */
    subProductLineRef?: number;
    /**
     * Dongle stepping reference
     * @type {number}
     * @memberof DongleDto
     */
    steppingRef?: number;
    /**
     * Vivien level
     * @type {number}
     * @memberof DongleDto
     */
    vivLevel?: number;
    /**
     * Cheat counter
     * @type {number}
     * @memberof DongleDto
     */
    cheatCounter?: number;
}
/**
 * 
 * @export
 * @interface DongleReauthCodeDto
 */
export interface DongleReauthCodeDto {
    /**
     * Reauth code id
     * @type {number}
     * @memberof DongleReauthCodeDto
     */
    reauthCodeId: number;
    /**
     * Reauth code
     * @type {string}
     * @memberof DongleReauthCodeDto
     */
    reauthCode: string;
    /**
     * Request code
     * @type {string}
     * @memberof DongleReauthCodeDto
     */
    requestCode: string;
    /**
     * Dongle reference
     * @type {number}
     * @memberof DongleReauthCodeDto
     */
    dongleRef: number;
}
/**
 * 
 * @export
 * @interface DongleUpgradeDto
 */
export interface DongleUpgradeDto {
    /**
     * Dongle id
     * @type {string}
     * @memberof DongleUpgradeDto
     */
    dongleId: string;
    /**
     * Upgrade id
     * @type {string}
     * @memberof DongleUpgradeDto
     */
    upgradeId: string;
    /**
     * Dongle serial number
     * @type {string}
     * @memberof DongleUpgradeDto
     */
    serialNumber: string;
    /**
     * New stepping number
     * @type {string}
     * @memberof DongleUpgradeDto
     */
    newStepping: string;
    /**
     * Lease expiry date
     * @type {string}
     * @memberof DongleUpgradeDto
     */
    expiryDate: string;
}
/**
 * 
 * @export
 * @interface DongleUsersInfoDto
 */
export interface DongleUsersInfoDto {
    /**
     * user id
     * @type {number}
     * @memberof DongleUsersInfoDto
     */
    userId: number;
    /**
     * Dongle Serial Number
     * @type {string}
     * @memberof DongleUsersInfoDto
     */
    serialNumber: string;
    /**
     * Dongle Serial Code
     * @type {string}
     * @memberof DongleUsersInfoDto
     */
    serialCode: string;
    /**
     * Is dongle retired
     * @type {boolean}
     * @memberof DongleUsersInfoDto
     */
    isRetired: boolean;
    /**
     * 
     * @type {string}
     * @memberof DongleUsersInfoDto
     */
    assignedFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof DongleUsersInfoDto
     */
    assignedAvatar?: string;
    /**
     * 
     * @type {string}
     * @memberof DongleUsersInfoDto
     */
    userEmail?: string;
    /**
     * 
     * @type {number}
     * @memberof DongleUsersInfoDto
     */
    ownerId?: number;
    /**
     * 
     * @type {string}
     * @memberof DongleUsersInfoDto
     */
    ownerFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof DongleUsersInfoDto
     */
    ownerAvatar?: string;
    /**
     * 
     * @type {number}
     * @memberof DongleUsersInfoDto
     */
    vendorId?: number;
    /**
     * 
     * @type {string}
     * @memberof DongleUsersInfoDto
     */
    vendorFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof DongleUsersInfoDto
     */
    vendorAvatar?: string;
    /**
     * 
     * @type {string}
     * @memberof DongleUsersInfoDto
     */
    wysiwygProduct?: string;
    /**
     * 
     * @type {string}
     * @memberof DongleUsersInfoDto
     */
    vivienProduct?: string;
    /**
     * 
     * @type {string}
     * @memberof DongleUsersInfoDto
     */
    licenseTyp?: string;
}
/**
 * 
 * @export
 * @interface EditingUserDto
 */
export interface EditingUserDto {
    /**
     * 
     * @type {string}
     * @memberof EditingUserDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof EditingUserDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof EditingUserDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof EditingUserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof EditingUserDto
     */
    avatar: string;
    /**
     * 
     * @type {string}
     * @memberof EditingUserDto
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof EditingUserDto
     */
    active: string;
    /**
     * 
     * @type {string}
     * @memberof EditingUserDto
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof EditingUserDto
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof EditingUserDto
     */
    userRoleName: string;
    /**
     * 
     * @type {string}
     * @memberof EditingUserDto
     */
    userRoleId: string;
}
/**
 * 
 * @export
 * @interface FeedbackResponseDto
 */
export interface FeedbackResponseDto {
    /**
     * 
     * @type {number}
     * @memberof FeedbackResponseDto
     */
    feedbackId: number;
    /**
     * 
     * @type {string}
     * @memberof FeedbackResponseDto
     */
    feedbackTitle: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackResponseDto
     */
    feedbackText: string;
    /**
     * 
     * @type {number}
     * @memberof FeedbackResponseDto
     */
    userRef: number;
    /**
     * 
     * @type {string}
     * @memberof FeedbackResponseDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackResponseDto
     */
    userEmail: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackResponseDto
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface FeedbackSearchResponseDto
 */
export interface FeedbackSearchResponseDto {
    /**
     * Id of the feedback
     * @type {number}
     * @memberof FeedbackSearchResponseDto
     */
    feedbackId: number;
    /**
     * Creation date
     * @type {string}
     * @memberof FeedbackSearchResponseDto
     */
    createdAt: string;
    /**
     * Id of the user
     * @type {number}
     * @memberof FeedbackSearchResponseDto
     */
    userId: number;
    /**
     * User email
     * @type {string}
     * @memberof FeedbackSearchResponseDto
     */
    email: string;
    /**
     * Feedback title
     * @type {string}
     * @memberof FeedbackSearchResponseDto
     */
    title: string;
    /**
     * Total count of feedback
     * @type {number}
     * @memberof FeedbackSearchResponseDto
     */
    totalCount: number;
}
/**
 * 
 * @export
 * @interface FileDto
 */
export interface FileDto {
    /**
     * File id
     * @type {number}
     * @memberof FileDto
     */
    id: number;
    /**
     * File name
     * @type {string}
     * @memberof FileDto
     */
    title: string;
    /**
     * File version
     * @type {string}
     * @memberof FileDto
     */
    subtitle: string;
    /**
     * File description
     * @type {string}
     * @memberof FileDto
     */
    description: string;
    /**
     * Release name
     * @type {string}
     * @memberof FileDto
     */
    name: string;
    /**
     * Product type
     * @type {string}
     * @memberof FileDto
     */
    product: string;
    /**
     * File download url or web page url
     * @type {string}
     * @memberof FileDto
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface HttpExceptionDataDto
 */
export interface HttpExceptionDataDto {
    /**
     * Freetext message
     * @type {string}
     * @memberof HttpExceptionDataDto
     */
    message: string;
}
/**
 * 
 * @export
 * @interface HttpExceptionDto
 */
export interface HttpExceptionDto {
    /**
     * HTTP response code
     * @type {number}
     * @memberof HttpExceptionDto
     */
    status: number;
    /**
     * Freetext message
     * @type {HttpExceptionDataDto}
     * @memberof HttpExceptionDto
     */
    data: HttpExceptionDataDto;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {number}
     * @memberof InlineObject
     */
    orderId?: number;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject1
     */
    days?: number;
}
/**
 * 
 * @export
 * @interface InlineObject10
 */
export interface InlineObject10 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject10
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject10
     */
    refreshToken?: string;
}
/**
 * 
 * @export
 * @interface InlineObject11
 */
export interface InlineObject11 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject11
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject11
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface InlineObject12
 */
export interface InlineObject12 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject12
     */
    token?: string;
}
/**
 * 
 * @export
 * @interface InlineObject13
 */
export interface InlineObject13 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject13
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof InlineObject13
     */
    organizationId?: number;
}
/**
 * 
 * @export
 * @interface InlineObject14
 */
export interface InlineObject14 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject14
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof InlineObject14
     */
    organizationId?: number;
}
/**
 * 
 * @export
 * @interface InlineObject15
 */
export interface InlineObject15 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject15
     */
    token?: string;
    /**
     * 
     * @type {object}
     * @memberof InlineObject15
     */
    user?: object;
}
/**
 * 
 * @export
 * @interface InlineObject16
 */
export interface InlineObject16 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject16
     */
    organizationId?: number;
    /**
     * 
     * @type {number}
     * @memberof InlineObject16
     */
    userId?: number;
    /**
     * 
     * @type {Array<ApiUsersChangeUserRolesRoles>}
     * @memberof InlineObject16
     */
    roles?: Array<ApiUsersChangeUserRolesRoles>;
}
/**
 * 
 * @export
 * @interface InlineObject17
 */
export interface InlineObject17 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject17
     */
    userId?: number;
}
/**
 * 
 * @export
 * @interface InlineObject18
 */
export interface InlineObject18 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject18
     */
    refreshToken?: string;
}
/**
 * 
 * @export
 * @interface InlineObject19
 */
export interface InlineObject19 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject19
     */
    userId?: number;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject2
     */
    orderId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject2
     */
    isFulfilled?: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject20
 */
export interface InlineObject20 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject20
     */
    oldPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject20
     */
    newPassword?: string;
}
/**
 * 
 * @export
 * @interface InlineObject21
 */
export interface InlineObject21 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject21
     */
    organizationId?: number;
}
/**
 * 
 * @export
 * @interface InlineObject22
 */
export interface InlineObject22 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject22
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject22
     */
    organizationName?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineObject22
     */
    countryRef?: number;
}
/**
 * 
 * @export
 * @interface InlineObject23
 */
export interface InlineObject23 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject23
     */
    addressId?: number;
}
/**
 * 
 * @export
 * @interface InlineObject24
 */
export interface InlineObject24 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject24
     */
    orderId?: number;
}
/**
 * 
 * @export
 * @interface InlineObject25
 */
export interface InlineObject25 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject25
     */
    orderId?: number;
}
/**
 * 
 * @export
 * @interface InlineObject26
 */
export interface InlineObject26 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject26
     */
    dongleId?: number;
}
/**
 * 
 * @export
 * @interface InlineObject27
 */
export interface InlineObject27 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject27
     */
    dongleId?: number;
}
/**
 * 
 * @export
 * @interface InlineObject28
 */
export interface InlineObject28 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject28
     */
    dongleId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject28
     */
    isRetired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject28
     */
    isLoaned?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InlineObject28
     */
    notes?: string;
}
/**
 * 
 * @export
 * @interface InlineObject29
 */
export interface InlineObject29 {
    /**
     * 
     * @type {object}
     * @memberof InlineObject29
     */
    file?: object;
    /**
     * 
     * @type {string}
     * @memberof InlineObject29
     */
    path?: string;
}
/**
 * 
 * @export
 * @interface InlineObject3
 */
export interface InlineObject3 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject3
     */
    orderId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InlineObject3
     */
    isFulfilled?: boolean;
}
/**
 * 
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject4
     */
    orderId?: number;
}
/**
 * 
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject5
     */
    orderId?: number;
}
/**
 * 
 * @export
 * @interface InlineObject6
 */
export interface InlineObject6 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject6
     */
    orderId?: number;
    /**
     * 
     * @type {string}
     * @memberof InlineObject6
     */
    notes?: string;
}
/**
 * 
 * @export
 * @interface InlineObject7
 */
export interface InlineObject7 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject7
     */
    orderId?: number;
}
/**
 * 
 * @export
 * @interface InlineObject8
 */
export interface InlineObject8 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject8
     */
    orderId?: number;
}
/**
 * 
 * @export
 * @interface InlineObject9
 */
export interface InlineObject9 {
    /**
     * 
     * @type {number}
     * @memberof InlineObject9
     */
    userId?: number;
}
/**
 * 
 * @export
 * @interface InsertDongleByAdminDto
 */
export interface InsertDongleByAdminDto {
    /**
     * Dongle Id
     * @type {number}
     * @memberof InsertDongleByAdminDto
     */
    dongleId: number;
    /**
     * Organization Id
     * @type {number}
     * @memberof InsertDongleByAdminDto
     */
    organizationId: number;
    /**
     * License duration
     * @type {number}
     * @memberof InsertDongleByAdminDto
     */
    duration: number;
    /**
     * License Type Id
     * @type {number}
     * @memberof InsertDongleByAdminDto
     */
    purchaseOptionsId: number;
}
/**
 * 
 * @export
 * @interface InsertDongleByOwnerDto
 */
export interface InsertDongleByOwnerDto {
    /**
     * Serial Code
     * @type {number}
     * @memberof InsertDongleByOwnerDto
     */
    serialCode: number;
    /**
     * Organization Id
     * @type {number}
     * @memberof InsertDongleByOwnerDto
     */
    organizationId: number;
}
/**
 * 
 * @export
 * @interface InventoryResponseDto
 */
export interface InventoryResponseDto {
    /**
     * Inventory items
     * @type {Array<string>}
     * @memberof InventoryResponseDto
     */
    items: Array<string>;
    /**
     * Items count
     * @type {number}
     * @memberof InventoryResponseDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface InvoiceDto
 */
export interface InvoiceDto {
    /**
     * invoice id
     * @type {number}
     * @memberof InvoiceDto
     */
    invoiceId: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    invoiceNumber: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    stripeInvoiceNumber: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    amountCaptured: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    amountRefunded: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    invoiceHtmlFile: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    invoicePdfFile: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDto
     */
    isPaid: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDto
     */
    isRefunded: boolean;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDto
     */
    orderRef: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    stripeChargeId: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    stripeInvoiceHtmlFile: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    stripeTransactionId: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDto
     */
    transactionTime: string;
}
/**
 * 
 * @export
 * @interface MemberInviteDto
 */
export interface MemberInviteDto {
    /**
     * Organization data
     * @type {OrganizationDto}
     * @memberof MemberInviteDto
     */
    organization: OrganizationDto;
    /**
     * User data
     * @type {object}
     * @memberof MemberInviteDto
     */
    user: object;
    /**
     * User roles
     * @type {Array<string>}
     * @memberof MemberInviteDto
     */
    roles?: Array<string>;
}
/**
 * 
 * @export
 * @interface OrderDto
 */
export interface OrderDto {
    /**
     * orderId
     * @type {number}
     * @memberof OrderDto
     */
    orderId: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    apt?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    province: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    countryRef: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    companyName: string;
    /**
     * Organization Id
     * @type {number}
     * @memberof OrderDto
     */
    organizationRef: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    billingFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    billingLastName: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    billingCountryRef: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    billingProvince: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    billingPostalCode: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    billingAddress1: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    billingApt?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    billingCity: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    refPromo: number;
    /**
     * Is order fulfilled
     * @type {boolean}
     * @memberof OrderDto
     */
    isFulfilled?: boolean;
    /**
     * 
     * @type {Array<OrderItemDto>}
     * @memberof OrderDto
     */
    orderItems: Array<OrderItemDto>;
    /**
     * Is order paid
     * @type {boolean}
     * @memberof OrderDto
     */
    isPaid?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    shipping: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    tax: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    stripePaymentIntentId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    stripeInvoiceId: string;
    /**
     * Stripe invoice has been voided
     * @type {boolean}
     * @memberof OrderDto
     */
    stripeInvoiceVoided: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    discount: number;
    /**
     * Id of the user doing purchase
     * @type {number}
     * @memberof OrderDto
     */
    userRef?: number;
    /**
     * Notes
     * @type {string}
     * @memberof OrderDto
     */
    notes?: string;
    /**
     * Id of the admin user, creating order
     * @type {number}
     * @memberof OrderDto
     */
    adminRef?: number;
    /**
     * Order has been paid by cash
     * @type {boolean}
     * @memberof OrderDto
     */
    paidByCash?: boolean;
    /**
     * Order has been paid via invoice
     * @type {boolean}
     * @memberof OrderDto
     */
    paidViaInvoice?: boolean;
    /**
     * Order for renew/upgrade dongle product
     * @type {boolean}
     * @memberof OrderDto
     */
    isRenewUpgrade?: boolean;
    /**
     * Dongle id for renew/upgrade
     * @type {number}
     * @memberof OrderDto
     */
    existingDongleRef?: number;
    /**
     * Order has been voided
     * @type {boolean}
     * @memberof OrderDto
     */
    isVoided?: boolean;
    /**
     * Order has been voided
     * @type {object}
     * @memberof OrderDto
     */
    isTestOrder?: object;
}
/**
 * 
 * @export
 * @interface OrderItemDto
 */
export interface OrderItemDto {
    /**
     * Count of items
     * @type {number}
     * @memberof OrderItemDto
     */
    itemCount: number;
    /**
     * Internal id of the order item
     * @type {number}
     * @memberof OrderItemDto
     */
    itemId?: number;
    /**
     * Internal id of the order item
     * @type {number}
     * @memberof OrderItemDto
     */
    itemOrderRef?: number;
    /**
     * Order reference
     * @type {number}
     * @memberof OrderItemDto
     */
    itemPrice?: number;
    /**
     * Product reference
     * @type {number}
     * @memberof OrderItemDto
     */
    itemProductRef?: number;
    /**
     * Discount
     * @type {number}
     * @memberof OrderItemDto
     */
    itemDiscount?: number;
    /**
     * Total price with discount
     * @type {number}
     * @memberof OrderItemDto
     */
    itemTotalPrice?: number;
}
/**
 * 
 * @export
 * @interface OrderItemWideDto
 */
export interface OrderItemWideDto {
    /**
     * Count of items
     * @type {number}
     * @memberof OrderItemWideDto
     */
    itemCount: number;
    /**
     * Internal id of the order item
     * @type {number}
     * @memberof OrderItemWideDto
     */
    itemId?: number;
    /**
     * Internal id of the order item
     * @type {number}
     * @memberof OrderItemWideDto
     */
    itemOrderRef?: number;
    /**
     * Order reference
     * @type {number}
     * @memberof OrderItemWideDto
     */
    itemPrice?: number;
    /**
     * Product reference
     * @type {number}
     * @memberof OrderItemWideDto
     */
    itemProductRef?: number;
    /**
     * Discount
     * @type {number}
     * @memberof OrderItemWideDto
     */
    itemDiscount?: number;
    /**
     * Total price with discount
     * @type {number}
     * @memberof OrderItemWideDto
     */
    itemTotalPrice?: number;
}
/**
 * 
 * @export
 * @interface OrderSearchResponseDto
 */
export interface OrderSearchResponseDto {
    /**
     * Id of the order
     * @type {number}
     * @memberof OrderSearchResponseDto
     */
    orderId: number;
    /**
     * Creation date
     * @type {string}
     * @memberof OrderSearchResponseDto
     */
    createdAt: string;
    /**
     * Id of the user
     * @type {number}
     * @memberof OrderSearchResponseDto
     */
    userId: number;
    /**
     * Customer email
     * @type {string}
     * @memberof OrderSearchResponseDto
     */
    email: string;
    /**
     * Order payment status
     * @type {boolean}
     * @memberof OrderSearchResponseDto
     */
    isPaid: boolean;
    /**
     * Order fulfilment status
     * @type {boolean}
     * @memberof OrderSearchResponseDto
     */
    isFulfilled: boolean;
    /**
     * Order is voided
     * @type {boolean}
     * @memberof OrderSearchResponseDto
     */
    isVoided: boolean;
    /**
     * Order total
     * @type {number}
     * @memberof OrderSearchResponseDto
     */
    total: number;
    /**
     * Order organization id
     * @type {number}
     * @memberof OrderSearchResponseDto
     */
    organizationRef: number;
    /**
     * Total count of orders
     * @type {number}
     * @memberof OrderSearchResponseDto
     */
    totalCount: number;
}
/**
 * 
 * @export
 * @interface OrderWideDto
 */
export interface OrderWideDto {
    /**
     * orderId
     * @type {number}
     * @memberof OrderWideDto
     */
    orderId: number;
    /**
     * 
     * @type {string}
     * @memberof OrderWideDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof OrderWideDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof OrderWideDto
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof OrderWideDto
     */
    apt?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderWideDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof OrderWideDto
     */
    province: string;
    /**
     * 
     * @type {number}
     * @memberof OrderWideDto
     */
    countryRef: number;
    /**
     * 
     * @type {string}
     * @memberof OrderWideDto
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof OrderWideDto
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderWideDto
     */
    companyName: string;
    /**
     * Organization Id
     * @type {number}
     * @memberof OrderWideDto
     */
    organizationRef: number;
    /**
     * 
     * @type {string}
     * @memberof OrderWideDto
     */
    billingFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof OrderWideDto
     */
    billingLastName: string;
    /**
     * 
     * @type {number}
     * @memberof OrderWideDto
     */
    billingCountryRef: number;
    /**
     * 
     * @type {string}
     * @memberof OrderWideDto
     */
    billingProvince: string;
    /**
     * 
     * @type {string}
     * @memberof OrderWideDto
     */
    billingPostalCode: string;
    /**
     * 
     * @type {string}
     * @memberof OrderWideDto
     */
    billingAddress1: string;
    /**
     * 
     * @type {string}
     * @memberof OrderWideDto
     */
    billingApt?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderWideDto
     */
    billingCity: string;
    /**
     * 
     * @type {number}
     * @memberof OrderWideDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof OrderWideDto
     */
    refPromo: number;
    /**
     * Is order fulfilled
     * @type {boolean}
     * @memberof OrderWideDto
     */
    isFulfilled?: boolean;
    /**
     * 
     * @type {Array<OrderItemDto>}
     * @memberof OrderWideDto
     */
    orderItems: Array<OrderItemDto>;
    /**
     * Is order paid
     * @type {boolean}
     * @memberof OrderWideDto
     */
    isPaid?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderWideDto
     */
    shipping: number;
    /**
     * 
     * @type {number}
     * @memberof OrderWideDto
     */
    tax: number;
    /**
     * 
     * @type {string}
     * @memberof OrderWideDto
     */
    stripePaymentIntentId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderWideDto
     */
    stripeInvoiceId: string;
    /**
     * Stripe invoice has been voided
     * @type {boolean}
     * @memberof OrderWideDto
     */
    stripeInvoiceVoided: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderWideDto
     */
    discount: number;
    /**
     * Id of the user doing purchase
     * @type {number}
     * @memberof OrderWideDto
     */
    userRef?: number;
    /**
     * Notes
     * @type {string}
     * @memberof OrderWideDto
     */
    notes?: string;
    /**
     * Id of the admin user, creating order
     * @type {number}
     * @memberof OrderWideDto
     */
    adminRef?: number;
    /**
     * Order has been paid by cash
     * @type {boolean}
     * @memberof OrderWideDto
     */
    paidByCash?: boolean;
    /**
     * Order has been paid via invoice
     * @type {boolean}
     * @memberof OrderWideDto
     */
    paidViaInvoice?: boolean;
    /**
     * Order for renew/upgrade dongle product
     * @type {boolean}
     * @memberof OrderWideDto
     */
    isRenewUpgrade?: boolean;
    /**
     * Dongle id for renew/upgrade
     * @type {number}
     * @memberof OrderWideDto
     */
    existingDongleRef?: number;
    /**
     * Order has been voided
     * @type {boolean}
     * @memberof OrderWideDto
     */
    isVoided?: boolean;
    /**
     * Order has been voided
     * @type {object}
     * @memberof OrderWideDto
     */
    isTestOrder?: object;
}
/**
 * 
 * @export
 * @interface OrganizationDto
 */
export interface OrganizationDto {
    /**
     * 
     * @type {number}
     * @memberof OrganizationDto
     */
    organizationId: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    organizationAvatar: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    organizationEmail: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationDto
     */
    isDeleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    notes: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    organizationPhoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    website: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationDto
     */
    isDealer: boolean;
    /**
     * 
     * @type {Array<AddressDto>}
     * @memberof OrganizationDto
     */
    addresses: Array<AddressDto>;
    /**
     * 
     * @type {number}
     * @memberof OrganizationDto
     */
    totalCount: number;
}
/**
 * 
 * @export
 * @interface OrganizationExtendedDto
 */
export interface OrganizationExtendedDto {
    /**
     * Organization data
     * @type {OrganizationDto}
     * @memberof OrganizationExtendedDto
     */
    organization: OrganizationDto;
    /**
     * User roles binding array
     * @type {Array<string>}
     * @memberof OrganizationExtendedDto
     */
    rolesBinding: Array<string>;
    /**
     * Organization addresses array
     * @type {Array<string>}
     * @memberof OrganizationExtendedDto
     */
    addresses: Array<string>;
    /**
     * Organization users
     * @type {Array<string>}
     * @memberof OrganizationExtendedDto
     */
    users: Array<string>;
}
/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * active
     * @type {boolean}
     * @memberof ProductDto
     */
    active: boolean;
    /**
     * duration
     * @type {number}
     * @memberof ProductDto
     */
    duration: number;
    /**
     * moa
     * @type {boolean}
     * @memberof ProductDto
     */
    moa: boolean;
    /**
     * onlineStore
     * @type {boolean}
     * @memberof ProductDto
     */
    onlineStore: boolean;
    /**
     * productCode
     * @type {string}
     * @memberof ProductDto
     */
    productCode: string;
    /**
     * productDescription
     * @type {string}
     * @memberof ProductDto
     */
    productDescription: string;
    /**
     * productId
     * @type {number}
     * @memberof ProductDto
     */
    productId: number;
    /**
     * productImage
     * @type {string}
     * @memberof ProductDto
     */
    productImage: string;
    /**
     * productName
     * @type {string}
     * @memberof ProductDto
     */
    productName: string;
    /**
     * productPrice
     * @type {number}
     * @memberof ProductDto
     */
    productPrice: number;
    /**
     * purchaseOptions
     * @type {number}
     * @memberof ProductDto
     */
    purchaseOptions: number;
    /**
     * shippable
     * @type {boolean}
     * @memberof ProductDto
     */
    shippable: boolean;
    /**
     * Subline Id
     * @type {number}
     * @memberof ProductDto
     */
    subProductLineId: number;
    /**
     * versionNumber
     * @type {string}
     * @memberof ProductDto
     */
    versionNumber: string;
}
/**
 * 
 * @export
 * @interface ProductInfoDto
 */
export interface ProductInfoDto {
    /**
     * organization id
     * @type {number}
     * @memberof ProductInfoDto
     */
    productId: number;
    /**
     * Dongle id
     * @type {number}
     * @memberof ProductInfoDto
     */
    dongleId: number;
    /**
     * Dongle Serial Number
     * @type {string}
     * @memberof ProductInfoDto
     */
    serialNumber: string;
    /**
     * Dongle Serial Code
     * @type {string}
     * @memberof ProductInfoDto
     */
    serialCode: string;
    /**
     * Dongle status
     * @type {string}
     * @memberof ProductInfoDto
     */
    status: string;
    /**
     * Date of dongle purchase
     * @type {string}
     * @memberof ProductInfoDto
     */
    purchaseDate: string;
    /**
     * Is dongle loaned
     * @type {boolean}
     * @memberof ProductInfoDto
     */
    isLoaned: boolean;
    /**
     * Is dongle retired
     * @type {boolean}
     * @memberof ProductInfoDto
     */
    isRetired: boolean;
    /**
     * Dongle notes
     * @type {string}
     * @memberof ProductInfoDto
     */
    notes?: string;
    /**
     * Is dongle active
     * @type {boolean}
     * @memberof ProductInfoDto
     */
    isActive: boolean;
    /**
     * Dongle expiry date
     * @type {string}
     * @memberof ProductInfoDto
     */
    expiryDate: string;
    /**
     * Assigned user
     * @type {number}
     * @memberof ProductInfoDto
     */
    userRef: number;
    /**
     * Dongle owner id
     * @type {number}
     * @memberof ProductInfoDto
     */
    ownerRef: number;
    /**
     * Dongle latest upgrade code
     * @type {string}
     * @memberof ProductInfoDto
     */
    upgradeCode: string;
    /**
     * Dongle latest renewCode
     * @type {string}
     * @memberof ProductInfoDto
     */
    renewCode: string;
    /**
     * Dongle stepping number
     * @type {number}
     * @memberof ProductInfoDto
     */
    stepping: number;
    /**
     * Dongle product type
     * @type {string}
     * @memberof ProductInfoDto
     */
    productType: string;
    /**
     * Dongle product level
     * @type {string}
     * @memberof ProductInfoDto
     */
    productLevel: string;
    /**
     * Dongle Vivien level
     * @type {string}
     * @memberof ProductInfoDto
     */
    vivLevel: string;
    /**
     * Dongle product reference
     * @type {number}
     * @memberof ProductInfoDto
     */
    productRef?: number;
    /**
     * Dongle subproduct line reference
     * @type {number}
     * @memberof ProductInfoDto
     */
    subProductLineRef?: number;
    /**
     * Dongle stepping reference
     * @type {number}
     * @memberof ProductInfoDto
     */
    steppingRef?: number;
    /**
     * Dongle stepping date
     * @type {string}
     * @memberof ProductInfoDto
     */
    steppingDate: string;
    /**
     * Dongle cheat counter
     * @type {number}
     * @memberof ProductInfoDto
     */
    cheatCounter: number;
    /**
     * Flag Request status
     * @type {boolean}
     * @memberof ProductInfoDto
     */
    isFlagRequest: boolean;
}
/**
 * 
 * @export
 * @interface ProductOptionDto
 */
export interface ProductOptionDto {
    /**
     * productId
     * @type {number}
     * @memberof ProductOptionDto
     */
    productId: number;
    /**
     * productCode
     * @type {string}
     * @memberof ProductOptionDto
     */
    productCode: string;
    /**
     * productName
     * @type {string}
     * @memberof ProductOptionDto
     */
    productName: string;
    /**
     * productPrice
     * @type {number}
     * @memberof ProductOptionDto
     */
    productPrice: number;
    /**
     * duration
     * @type {number}
     * @memberof ProductOptionDto
     */
    duration: number;
    /**
     * productDescription
     * @type {string}
     * @memberof ProductOptionDto
     */
    productDescription: string;
}
/**
 * 
 * @export
 * @interface ProductWideDto
 */
export interface ProductWideDto {
    /**
     * productLineId
     * @type {number}
     * @memberof ProductWideDto
     */
    productLineId: number;
    /**
     * productImage
     * @type {string}
     * @memberof ProductWideDto
     */
    productImage: string;
    /**
     * Product line name
     * @type {string}
     * @memberof ProductWideDto
     */
    productLineName: string;
    /**
     * purchaseOptionsId
     * @type {number}
     * @memberof ProductWideDto
     */
    purchaseOptionsId: number;
    /**
     * purchaseOptionsName
     * @type {string}
     * @memberof ProductWideDto
     */
    purchaseOptionsName: string;
    /**
     * purchaseOptionsDescription
     * @type {string}
     * @memberof ProductWideDto
     */
    purchaseOptionsDescription: string;
    /**
     * purchaseType
     * @type {string}
     * @memberof ProductWideDto
     */
    purchaseType: string;
    /**
     * shippable
     * @type {number}
     * @memberof ProductWideDto
     */
    shippable: number;
    /**
     * subProductLineId
     * @type {number}
     * @memberof ProductWideDto
     */
    subProductLineId: number;
    /**
     * subProductLineName
     * @type {string}
     * @memberof ProductWideDto
     */
    subProductLineName: string;
    /**
     * subProductLineDescription
     * @type {string}
     * @memberof ProductWideDto
     */
    subProductLineDescription: string;
    /**
     * startingPrice
     * @type {number}
     * @memberof ProductWideDto
     */
    startingPrice: number;
    /**
     * leaseStartingPrice
     * @type {number}
     * @memberof ProductWideDto
     */
    leaseStartingPrice: number;
    /**
     * 
     * @type {Array<ProductOptionDto>}
     * @memberof ProductWideDto
     */
    productOptions: Array<ProductOptionDto>;
    /**
     * productId
     * @type {number}
     * @memberof ProductWideDto
     */
    productId: number;
    /**
     * productCode
     * @type {string}
     * @memberof ProductWideDto
     */
    productCode: string;
    /**
     * productName
     * @type {string}
     * @memberof ProductWideDto
     */
    productName: string;
    /**
     * productPrice
     * @type {number}
     * @memberof ProductWideDto
     */
    productPrice: number;
}
/**
 * 
 * @export
 * @interface RegionDto
 */
export interface RegionDto {
    /**
     * Id of the region
     * @type {number}
     * @memberof RegionDto
     */
    id: number;
    /**
     * Name of the region
     * @type {string}
     * @memberof RegionDto
     */
    name: string;
    /**
     * Name of the iso2
     * @type {string}
     * @memberof RegionDto
     */
    iso2: string;
}
/**
 * 
 * @export
 * @interface ReleaseDto
 */
export interface ReleaseDto {
    /**
     * Release id
     * @type {number}
     * @memberof ReleaseDto
     */
    id: number;
    /**
     * Release name
     * @type {string}
     * @memberof ReleaseDto
     */
    name: string;
    /**
     * Reference to `product_line`
     * @type {number}
     * @memberof ReleaseDto
     */
    refProductType: number;
    /**
     * Reference to minimal stepping
     * @type {number}
     * @memberof ReleaseDto
     */
    refMinStepping: number;
    /**
     * Reference to maximal stepping
     * @type {number}
     * @memberof ReleaseDto
     */
    refMaxStepping: number;
    /**
     * Is release locked
     * @type {boolean}
     * @memberof ReleaseDto
     */
    isLocked: boolean;
}
/**
 * 
 * @export
 * @interface ReleaseForDownloadsDto
 */
export interface ReleaseForDownloadsDto {
    /**
     * Release id
     * @type {number}
     * @memberof ReleaseForDownloadsDto
     */
    id: number;
    /**
     * Release name
     * @type {string}
     * @memberof ReleaseForDownloadsDto
     */
    name: string;
    /**
     * Release Product Type
     * @type {number}
     * @memberof ReleaseForDownloadsDto
     */
    refProductType: number;
}
/**
 * 
 * @export
 * @interface RenewLeaseDto
 */
export interface RenewLeaseDto {
    /**
     * Dongle id
     * @type {string}
     * @memberof RenewLeaseDto
     */
    dongleId: string;
    /**
     * Dongle serial number
     * @type {string}
     * @memberof RenewLeaseDto
     */
    serialNumber: string;
    /**
     * Dongle serial code
     * @type {string}
     * @memberof RenewLeaseDto
     */
    serialCode: string;
    /**
     * New expiry date
     * @type {string}
     * @memberof RenewLeaseDto
     */
    date: string;
}
/**
 * 
 * @export
 * @interface RenewMembershipDto
 */
export interface RenewMembershipDto {
    /**
     * Dongle id
     * @type {string}
     * @memberof RenewMembershipDto
     */
    dongleId: string;
    /**
     * Dongle serial number
     * @type {string}
     * @memberof RenewMembershipDto
     */
    serialNumber: string;
    /**
     * New stepping number
     * @type {string}
     * @memberof RenewMembershipDto
     */
    newStepping: string;
}
/**
 * 
 * @export
 * @interface RenewUpgradePossibilityDto
 */
export interface RenewUpgradePossibilityDto {
    /**
     * Products to upgrade
     * @type {Array<string>}
     * @memberof RenewUpgradePossibilityDto
     */
    upgradeProducts: Array<string>;
    /**
     * Products to renew
     * @type {Array<string>}
     * @memberof RenewUpgradePossibilityDto
     */
    renewProducts: Array<string>;
    /**
     * Renew possibility
     * @type {boolean}
     * @memberof RenewUpgradePossibilityDto
     */
    isRenewPossible: boolean;
    /**
     * Upgrade possibility
     * @type {boolean}
     * @memberof RenewUpgradePossibilityDto
     */
    isUpgradePossible: boolean;
    /**
     * Number of renewals that required to renew membership
     * @type {number}
     * @memberof RenewUpgradePossibilityDto
     */
    renewalsRequired: number;
    /**
     * Number of available renewals
     * @type {number}
     * @memberof RenewUpgradePossibilityDto
     */
    maxRenewalsAvailable: number;
    /**
     * Future renew possibility
     * @type {boolean}
     * @memberof RenewUpgradePossibilityDto
     */
    isTooNew: boolean;
    /**
     * Too old to renew
     * @type {boolean}
     * @memberof RenewUpgradePossibilityDto
     */
    isTooOld: boolean;
    /**
     * Needs license expiration to upgrade
     * @type {boolean}
     * @memberof RenewUpgradePossibilityDto
     */
    needsExpirationBeforeUpgrade: boolean;
}
/**
 * 
 * @export
 * @interface SteppingDto
 */
export interface SteppingDto {
    /**
     * Stepping id
     * @type {number}
     * @memberof SteppingDto
     */
    steppingId: number;
    /**
     * Stepping Number
     * @type {number}
     * @memberof SteppingDto
     */
    steppingNumber: number;
    /**
     * Date of stepping
     * @type {string}
     * @memberof SteppingDto
     */
    date: string;
}
/**
 * 
 * @export
 * @interface StripeInvoiceDto
 */
export interface StripeInvoiceDto {
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceDto
     */
    status: string;
    /**
     * 
     * @type {boolean}
     * @memberof StripeInvoiceDto
     */
    paid: boolean;
    /**
     * 
     * @type {StripeInvoiceMetadataDto}
     * @memberof StripeInvoiceDto
     */
    metadata: StripeInvoiceMetadataDto;
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceDto
     */
    payment_intent: string;
}
/**
 * 
 * @export
 * @interface StripeInvoiceMetadataDto
 */
export interface StripeInvoiceMetadataDto {
    /**
     * 
     * @type {number}
     * @memberof StripeInvoiceMetadataDto
     */
    orderId: number;
}
/**
 * 
 * @export
 * @interface StripeSessionDto
 */
export interface StripeSessionDto {
    /**
     * Id of the Stripe session
     * @type {string}
     * @memberof StripeSessionDto
     */
    id: string;
    /**
     * Session payment intent id
     * @type {string}
     * @memberof StripeSessionDto
     */
    payment_intent: string;
}
/**
 * 
 * @export
 * @interface TransferDongleDto
 */
export interface TransferDongleDto {
    /**
     * Dongle Id
     * @type {number}
     * @memberof TransferDongleDto
     */
    dongleId: number;
    /**
     * Email
     * @type {number}
     * @memberof TransferDongleDto
     */
    email: number;
}
/**
 * 
 * @export
 * @interface UpdateAddressDto
 */
export interface UpdateAddressDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    city: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAddressDto
     */
    countryRef: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    apt: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    province: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAddressDto
     */
    organizationRef: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateAddressDto
     */
    addressId: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAddressDto
     */
    isBilling: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAddressDto
     */
    isDefault: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAddressDto
     */
    isDeleted: boolean;
}
/**
 * 
 * @export
 * @interface UpdateDiscountDto
 */
export interface UpdateDiscountDto {
    /**
     * Id of the discount
     * @type {number}
     * @memberof UpdateDiscountDto
     */
    discountId: number;
    /**
     * Start date
     * @type {string}
     * @memberof UpdateDiscountDto
     */
    startDate: string;
    /**
     * End date
     * @type {string}
     * @memberof UpdateDiscountDto
     */
    endDate: string;
    /**
     * Discount types are percentage or amount
     * @type {number}
     * @memberof UpdateDiscountDto
     */
    discountValue: number;
    /**
     * Limit of discount usage 0-10 or unlimited
     * @type {number}
     * @memberof UpdateDiscountDto
     */
    limit: number;
    /**
     * Product to apply the discount
     * @type {number}
     * @memberof UpdateDiscountDto
     */
    productTypeId: number;
    /**
     * Discount description
     * @type {string}
     * @memberof UpdateDiscountDto
     */
    description: string;
}
/**
 * 
 * @export
 * @interface UpdateOrganizationDto
 */
export interface UpdateOrganizationDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateOrganizationDto
     */
    organizationId: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    organizationAvatar: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    organizationEmail: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOrganizationDto
     */
    isDeleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    notes: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    organizationPhoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDto
     */
    website: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOrganizationDto
     */
    isDealer: boolean;
}
/**
 * 
 * @export
 * @interface UpdateReleaseDto
 */
export interface UpdateReleaseDto {
    /**
     * Release id
     * @type {number}
     * @memberof UpdateReleaseDto
     */
    id: number;
    /**
     * Release name
     * @type {string}
     * @memberof UpdateReleaseDto
     */
    name: string;
    /**
     * Reference to minimal stepping
     * @type {number}
     * @memberof UpdateReleaseDto
     */
    refMinStepping: number;
}
/**
 * 
 * @export
 * @interface UpdateUploadDto
 */
export interface UpdateUploadDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateUploadDto
     */
    id: number;
    /**
     * Release id
     * @type {number}
     * @memberof UpdateUploadDto
     */
    releaseId: number;
    /**
     * release title
     * @type {string}
     * @memberof UpdateUploadDto
     */
    title: string;
    /**
     * Build number
     * @type {string}
     * @memberof UpdateUploadDto
     */
    subtitle: string;
    /**
     * Build number
     * @type {string}
     * @memberof UpdateUploadDto
     */
    url: string;
    /**
     * Description
     * @type {string}
     * @memberof UpdateUploadDto
     */
    description: string;
    /**
     * Just open link (don\'t download)
     * @type {boolean}
     * @memberof UpdateUploadDto
     */
    isJustOpen: boolean;
}
/**
 * 
 * @export
 * @interface UploadDto
 */
export interface UploadDto {
    /**
     * 
     * @type {number}
     * @memberof UploadDto
     */
    id: number;
    /**
     * Reference to `product_line`
     * @type {number}
     * @memberof UploadDto
     */
    refProductType: number;
    /**
     * Release name
     * @type {string}
     * @memberof UploadDto
     */
    release: string;
    /**
     * Release id
     * @type {number}
     * @memberof UploadDto
     */
    releaseId: number;
    /**
     * release title
     * @type {string}
     * @memberof UploadDto
     */
    title: string;
    /**
     * Build number
     * @type {string}
     * @memberof UploadDto
     */
    subtitle: string;
    /**
     * Build number
     * @type {string}
     * @memberof UploadDto
     */
    url: string;
    /**
     * Description
     * @type {string}
     * @memberof UploadDto
     */
    description: string;
    /**
     * Just open link (don\'t download)
     * @type {boolean}
     * @memberof UploadDto
     */
    isJustOpen: boolean;
}
/**
 * 
 * @export
 * @interface UserCreateDto
 */
export interface UserCreateDto {
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    avatar: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    password: string;
    /**
     * User country id
     * @type {number}
     * @memberof UserCreateDto
     */
    countryRef: number;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    token: string;
    /**
     * 
     * @type {number}
     * @memberof UserCreateDto
     */
    invitedBy: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCreateDto
     */
    reCaptchaToken: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateDto
     */
    agreeWithPolicy: boolean;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * Whenever user is not disabled
     * @type {boolean}
     * @memberof UserDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    phoneNumber: string;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    avatar: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    reCaptchaToken: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    viewedTours: string;
}
/**
 * 
 * @export
 * @interface UserLoginRequestDto
 */
export interface UserLoginRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequestDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequestDto
     */
    password: string;
}
/**
 * 
 * @export
 * @interface UserLoginResponseDto
 */
export interface UserLoginResponseDto {
    /**
     * JWT Token, if login request succeeded
     * @type {string}
     * @memberof UserLoginResponseDto
     */
    accessToken: string;
    /**
     * Refresh token
     * @type {string}
     * @memberof UserLoginResponseDto
     */
    refreshToken: string;
}
/**
 * 
 * @export
 * @interface UserRoleBindingDto
 */
export interface UserRoleBindingDto {
    /**
     * 
     * @type {number}
     * @memberof UserRoleBindingDto
     */
    userRoleBindingId: number;
    /**
     * 
     * @type {number}
     * @memberof UserRoleBindingDto
     */
    userRef: number;
    /**
     * 
     * @type {number}
     * @memberof UserRoleBindingDto
     */
    userRoleRef: number;
    /**
     * 
     * @type {number}
     * @memberof UserRoleBindingDto
     */
    organizationRef: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserRoleBindingDto
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRoleBindingDto
     */
    isDeleted: boolean;
}
/**
 * 
 * @export
 * @interface UserRoleDto
 */
export interface UserRoleDto {
    /**
     * 
     * @type {number}
     * @memberof UserRoleDto
     */
    userGroupRef: number;
    /**
     * 
     * @type {number}
     * @memberof UserRoleDto
     */
    userRoleId: number;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDto
     */
    userRoleName: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserRoleDto
     */
    canManageOrganization: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRoleDto
     */
    canBuy: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserRoleDto
     */
    priority: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserRoleDto
     */
    canEditDongles: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRoleDto
     */
    canRenewUpgradeDongles: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRoleDto
     */
    canManageUsers: boolean;
}
/**
 * 
 * @export
 * @interface UserUpdateDto
 */
export interface UserUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    avatar: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateDto
     */
    viewedTours: string;
}
/**
 * 
 * @export
 * @interface UsersItemDto
 */
export interface UsersItemDto {
    /**
     * 
     * @type {string}
     * @memberof UsersItemDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UsersItemDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UsersItemDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UsersItemDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UsersItemDto
     */
    avatar: string;
    /**
     * 
     * @type {string}
     * @memberof UsersItemDto
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof UsersItemDto
     */
    organizationAvatar: string;
    /**
     * 
     * @type {string}
     * @memberof UsersItemDto
     */
    userRoleName: string;
    /**
     * 
     * @type {string}
     * @memberof UsersItemDto
     */
    userRoleRef: string;
    /**
     * 
     * @type {number}
     * @memberof UsersItemDto
     */
    totalCount: number;
}

/**
 * AddressApi - axios parameter creator
 * @export
 */
export const AddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerCreateAddress: async (createAddressDto: CreateAddressDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAddressDto' is not null or undefined
            if (createAddressDto === null || createAddressDto === undefined) {
                throw new RequiredError('createAddressDto','Required parameter createAddressDto was null or undefined when calling addressControllerCreateAddress.');
            }
            const localVarPath = `/api/address/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createAddressDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createAddressDto !== undefined ? createAddressDto : {})
                : (createAddressDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject23} inlineObject23 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerDeleteAddress: async (inlineObject23: InlineObject23, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject23' is not null or undefined
            if (inlineObject23 === null || inlineObject23 === undefined) {
                throw new RequiredError('inlineObject23','Required parameter inlineObject23 was null or undefined when calling addressControllerDeleteAddress.');
            }
            const localVarPath = `/api/address/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject23 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject23 !== undefined ? inlineObject23 : {})
                : (inlineObject23 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerGetOrganizationAddresses: async (organizationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling addressControllerGetOrganizationAddresses.');
            }
            const localVarPath = `/api/address/organization-addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerUpdateAddress: async (updateAddressDto: UpdateAddressDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAddressDto' is not null or undefined
            if (updateAddressDto === null || updateAddressDto === undefined) {
                throw new RequiredError('updateAddressDto','Required parameter updateAddressDto was null or undefined when calling addressControllerUpdateAddress.');
            }
            const localVarPath = `/api/address/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateAddressDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateAddressDto !== undefined ? updateAddressDto : {})
                : (updateAddressDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressApi - functional programming interface
 * @export
 */
export const AddressApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerCreateAddress(createAddressDto: CreateAddressDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressDto>> {
            const localVarAxiosArgs = await AddressApiAxiosParamCreator(configuration).addressControllerCreateAddress(createAddressDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject23} inlineObject23 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerDeleteAddress(inlineObject23: InlineObject23, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await AddressApiAxiosParamCreator(configuration).addressControllerDeleteAddress(inlineObject23, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerGetOrganizationAddresses(organizationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressDto>>> {
            const localVarAxiosArgs = await AddressApiAxiosParamCreator(configuration).addressControllerGetOrganizationAddresses(organizationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerUpdateAddress(updateAddressDto: UpdateAddressDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await AddressApiAxiosParamCreator(configuration).addressControllerUpdateAddress(updateAddressDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AddressApi - factory interface
 * @export
 */
export const AddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerCreateAddress(createAddressDto: CreateAddressDto, options?: any): AxiosPromise<AddressDto> {
            return AddressApiFp(configuration).addressControllerCreateAddress(createAddressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject23} inlineObject23 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerDeleteAddress(inlineObject23: InlineObject23, options?: any): AxiosPromise<number> {
            return AddressApiFp(configuration).addressControllerDeleteAddress(inlineObject23, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerGetOrganizationAddresses(organizationId: string, options?: any): AxiosPromise<Array<AddressDto>> {
            return AddressApiFp(configuration).addressControllerGetOrganizationAddresses(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerUpdateAddress(updateAddressDto: UpdateAddressDto, options?: any): AxiosPromise<number> {
            return AddressApiFp(configuration).addressControllerUpdateAddress(updateAddressDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddressApi - object-oriented interface
 * @export
 * @class AddressApi
 * @extends {BaseAPI}
 */
export class AddressApi extends BaseAPI {
    /**
     * 
     * @param {CreateAddressDto} createAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public addressControllerCreateAddress(createAddressDto: CreateAddressDto, options?: any) {
        return AddressApiFp(this.configuration).addressControllerCreateAddress(createAddressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject23} inlineObject23 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public addressControllerDeleteAddress(inlineObject23: InlineObject23, options?: any) {
        return AddressApiFp(this.configuration).addressControllerDeleteAddress(inlineObject23, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public addressControllerGetOrganizationAddresses(organizationId: string, options?: any) {
        return AddressApiFp(this.configuration).addressControllerGetOrganizationAddresses(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAddressDto} updateAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public addressControllerUpdateAddress(updateAddressDto: UpdateAddressDto, options?: any) {
        return AddressApiFp(this.configuration).addressControllerUpdateAddress(updateAddressDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BucketApi - axios parameter creator
 * @export
 */
export const BucketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InlineObject29} inlineObject29 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bucketControllerUploadFile: async (inlineObject29: InlineObject29, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject29' is not null or undefined
            if (inlineObject29 === null || inlineObject29 === undefined) {
                throw new RequiredError('inlineObject29','Required parameter inlineObject29 was null or undefined when calling bucketControllerUploadFile.');
            }
            const localVarPath = `/api/bucket/upload-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject29 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject29 !== undefined ? inlineObject29 : {})
                : (inlineObject29 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BucketApi - functional programming interface
 * @export
 */
export const BucketApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InlineObject29} inlineObject29 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bucketControllerUploadFile(inlineObject29: InlineObject29, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await BucketApiAxiosParamCreator(configuration).bucketControllerUploadFile(inlineObject29, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BucketApi - factory interface
 * @export
 */
export const BucketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {InlineObject29} inlineObject29 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bucketControllerUploadFile(inlineObject29: InlineObject29, options?: any): AxiosPromise<string> {
            return BucketApiFp(configuration).bucketControllerUploadFile(inlineObject29, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BucketApi - object-oriented interface
 * @export
 * @class BucketApi
 * @extends {BaseAPI}
 */
export class BucketApi extends BaseAPI {
    /**
     * 
     * @param {InlineObject29} inlineObject29 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BucketApi
     */
    public bucketControllerUploadFile(inlineObject29: InlineObject29, options?: any) {
        return BucketApiFp(this.configuration).bucketControllerUploadFile(inlineObject29, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeWebhookControllerWebhook: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stripe-webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetHello(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).appControllerGetHello(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeWebhookControllerWebhook(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).stripeWebhookControllerWebhook(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello(options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).appControllerGetHello(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeWebhookControllerWebhook(options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).stripeWebhookControllerWebhook(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerGetHello(options?: any) {
        return DefaultApiFp(this.configuration).appControllerGetHello(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public stripeWebhookControllerWebhook(options?: any) {
        return DefaultApiFp(this.configuration).stripeWebhookControllerWebhook(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemoApi - axios parameter creator
 * @export
 */
export const DemoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoControllerAdminFindAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/demo/admin-find-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoControllerAdminGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling demoControllerAdminGet.');
            }
            const localVarPath = `/api/demo/admin-get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DemoLeadCreateDto} demoLeadCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoControllerDownloadDemo: async (demoLeadCreateDto: DemoLeadCreateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'demoLeadCreateDto' is not null or undefined
            if (demoLeadCreateDto === null || demoLeadCreateDto === undefined) {
                throw new RequiredError('demoLeadCreateDto','Required parameter demoLeadCreateDto was null or undefined when calling demoControllerDownloadDemo.');
            }
            const localVarPath = `/api/demo/download-demo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof demoLeadCreateDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(demoLeadCreateDto !== undefined ? demoLeadCreateDto : {})
                : (demoLeadCreateDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoControllerFindAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/demo/find-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DemoLeadCreateDto} demoLeadCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoControllerGetDemoDownloadLink: async (demoLeadCreateDto: DemoLeadCreateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'demoLeadCreateDto' is not null or undefined
            if (demoLeadCreateDto === null || demoLeadCreateDto === undefined) {
                throw new RequiredError('demoLeadCreateDto','Required parameter demoLeadCreateDto was null or undefined when calling demoControllerGetDemoDownloadLink.');
            }
            const localVarPath = `/api/demo/get-demo-download-link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof demoLeadCreateDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(demoLeadCreateDto !== undefined ? demoLeadCreateDto : {})
                : (demoLeadCreateDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoControllerGetDemoLeadsForCurrentUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/demo/demo-leads-for-current-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productLine 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoControllerGetProfessions: async (productLine: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productLine' is not null or undefined
            if (productLine === null || productLine === undefined) {
                throw new RequiredError('productLine','Required parameter productLine was null or undefined when calling demoControllerGetProfessions.');
            }
            const localVarPath = `/api/demo/professions/{productLine}`
                .replace(`{${"productLine"}}`, encodeURIComponent(String(productLine)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DemoUpdateDto} demoUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoControllerUpdate: async (id: number, demoUpdateDto: DemoUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling demoControllerUpdate.');
            }
            // verify required parameter 'demoUpdateDto' is not null or undefined
            if (demoUpdateDto === null || demoUpdateDto === undefined) {
                throw new RequiredError('demoUpdateDto','Required parameter demoUpdateDto was null or undefined when calling demoControllerUpdate.');
            }
            const localVarPath = `/api/demo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof demoUpdateDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(demoUpdateDto !== undefined ? demoUpdateDto : {})
                : (demoUpdateDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemoApi - functional programming interface
 * @export
 */
export const DemoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demoControllerAdminFindAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminDemoDto>>> {
            const localVarAxiosArgs = await DemoApiAxiosParamCreator(configuration).demoControllerAdminFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demoControllerAdminGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminDemoDto>>> {
            const localVarAxiosArgs = await DemoApiAxiosParamCreator(configuration).demoControllerAdminGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DemoLeadCreateDto} demoLeadCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demoControllerDownloadDemo(demoLeadCreateDto: DemoLeadCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await DemoApiAxiosParamCreator(configuration).demoControllerDownloadDemo(demoLeadCreateDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demoControllerFindAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemoDto>>> {
            const localVarAxiosArgs = await DemoApiAxiosParamCreator(configuration).demoControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DemoLeadCreateDto} demoLeadCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demoControllerGetDemoDownloadLink(demoLeadCreateDto: DemoLeadCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DemoApiAxiosParamCreator(configuration).demoControllerGetDemoDownloadLink(demoLeadCreateDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demoControllerGetDemoLeadsForCurrentUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemoLeadDto>>> {
            const localVarAxiosArgs = await DemoApiAxiosParamCreator(configuration).demoControllerGetDemoLeadsForCurrentUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} productLine 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demoControllerGetProfessions(productLine: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DemoApiAxiosParamCreator(configuration).demoControllerGetProfessions(productLine, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {DemoUpdateDto} demoUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demoControllerUpdate(id: number, demoUpdateDto: DemoUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DemoApiAxiosParamCreator(configuration).demoControllerUpdate(id, demoUpdateDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DemoApi - factory interface
 * @export
 */
export const DemoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoControllerAdminFindAll(options?: any): AxiosPromise<Array<AdminDemoDto>> {
            return DemoApiFp(configuration).demoControllerAdminFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoControllerAdminGet(id: number, options?: any): AxiosPromise<Array<AdminDemoDto>> {
            return DemoApiFp(configuration).demoControllerAdminGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DemoLeadCreateDto} demoLeadCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoControllerDownloadDemo(demoLeadCreateDto: DemoLeadCreateDto, options?: any): AxiosPromise<object> {
            return DemoApiFp(configuration).demoControllerDownloadDemo(demoLeadCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoControllerFindAll(options?: any): AxiosPromise<Array<DemoDto>> {
            return DemoApiFp(configuration).demoControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DemoLeadCreateDto} demoLeadCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoControllerGetDemoDownloadLink(demoLeadCreateDto: DemoLeadCreateDto, options?: any): AxiosPromise<string> {
            return DemoApiFp(configuration).demoControllerGetDemoDownloadLink(demoLeadCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoControllerGetDemoLeadsForCurrentUser(options?: any): AxiosPromise<Array<DemoLeadDto>> {
            return DemoApiFp(configuration).demoControllerGetDemoLeadsForCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productLine 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoControllerGetProfessions(productLine: number, options?: any): AxiosPromise<void> {
            return DemoApiFp(configuration).demoControllerGetProfessions(productLine, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {DemoUpdateDto} demoUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoControllerUpdate(id: number, demoUpdateDto: DemoUpdateDto, options?: any): AxiosPromise<void> {
            return DemoApiFp(configuration).demoControllerUpdate(id, demoUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemoApi - object-oriented interface
 * @export
 * @class DemoApi
 * @extends {BaseAPI}
 */
export class DemoApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoApi
     */
    public demoControllerAdminFindAll(options?: any) {
        return DemoApiFp(this.configuration).demoControllerAdminFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoApi
     */
    public demoControllerAdminGet(id: number, options?: any) {
        return DemoApiFp(this.configuration).demoControllerAdminGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DemoLeadCreateDto} demoLeadCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoApi
     */
    public demoControllerDownloadDemo(demoLeadCreateDto: DemoLeadCreateDto, options?: any) {
        return DemoApiFp(this.configuration).demoControllerDownloadDemo(demoLeadCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoApi
     */
    public demoControllerFindAll(options?: any) {
        return DemoApiFp(this.configuration).demoControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DemoLeadCreateDto} demoLeadCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoApi
     */
    public demoControllerGetDemoDownloadLink(demoLeadCreateDto: DemoLeadCreateDto, options?: any) {
        return DemoApiFp(this.configuration).demoControllerGetDemoDownloadLink(demoLeadCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoApi
     */
    public demoControllerGetDemoLeadsForCurrentUser(options?: any) {
        return DemoApiFp(this.configuration).demoControllerGetDemoLeadsForCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productLine 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoApi
     */
    public demoControllerGetProfessions(productLine: number, options?: any) {
        return DemoApiFp(this.configuration).demoControllerGetProfessions(productLine, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {DemoUpdateDto} demoUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoApi
     */
    public demoControllerUpdate(id: number, demoUpdateDto: DemoUpdateDto, options?: any) {
        return DemoApiFp(this.configuration).demoControllerUpdate(id, demoUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DictionaryApi - axios parameter creator
 * @export
 */
export const DictionaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryControllerFindAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/country`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} shippingCountryId 
         * @param {string} postalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fedexControllerGetShippingRate: async (shippingCountryId: number, postalCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'shippingCountryId' is not null or undefined
            if (shippingCountryId === null || shippingCountryId === undefined) {
                throw new RequiredError('shippingCountryId','Required parameter shippingCountryId was null or undefined when calling fedexControllerGetShippingRate.');
            }
            // verify required parameter 'postalCode' is not null or undefined
            if (postalCode === null || postalCode === undefined) {
                throw new RequiredError('postalCode','Required parameter postalCode was null or undefined when calling fedexControllerGetShippingRate.');
            }
            const localVarPath = `/api/fedex/rate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (shippingCountryId !== undefined) {
                localVarQueryParameter['shippingCountryId'] = shippingCountryId;
            }

            if (postalCode !== undefined) {
                localVarQueryParameter['postalCode'] = postalCode;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionControllerFindCountryRegions: async (countryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryId' is not null or undefined
            if (countryId === null || countryId === undefined) {
                throw new RequiredError('countryId','Required parameter countryId was null or undefined when calling regionControllerFindCountryRegions.');
            }
            const localVarPath = `/api/region/country-regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (countryId !== undefined) {
                localVarQueryParameter['countryId'] = countryId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictionaryApi - functional programming interface
 * @export
 */
export const DictionaryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countryControllerFindAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CountryDto>>> {
            const localVarAxiosArgs = await DictionaryApiAxiosParamCreator(configuration).countryControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} shippingCountryId 
         * @param {string} postalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fedexControllerGetShippingRate(shippingCountryId: number, postalCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DictionaryApiAxiosParamCreator(configuration).fedexControllerGetShippingRate(shippingCountryId, postalCode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regionControllerFindCountryRegions(countryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RegionDto>>> {
            const localVarAxiosArgs = await DictionaryApiAxiosParamCreator(configuration).regionControllerFindCountryRegions(countryId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DictionaryApi - factory interface
 * @export
 */
export const DictionaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countryControllerFindAll(options?: any): AxiosPromise<Array<CountryDto>> {
            return DictionaryApiFp(configuration).countryControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} shippingCountryId 
         * @param {string} postalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fedexControllerGetShippingRate(shippingCountryId: number, postalCode: string, options?: any): AxiosPromise<number> {
            return DictionaryApiFp(configuration).fedexControllerGetShippingRate(shippingCountryId, postalCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} countryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionControllerFindCountryRegions(countryId: string, options?: any): AxiosPromise<Array<RegionDto>> {
            return DictionaryApiFp(configuration).regionControllerFindCountryRegions(countryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DictionaryApi - object-oriented interface
 * @export
 * @class DictionaryApi
 * @extends {BaseAPI}
 */
export class DictionaryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public countryControllerFindAll(options?: any) {
        return DictionaryApiFp(this.configuration).countryControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} shippingCountryId 
     * @param {string} postalCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public fedexControllerGetShippingRate(shippingCountryId: number, postalCode: string, options?: any) {
        return DictionaryApiFp(this.configuration).fedexControllerGetShippingRate(shippingCountryId, postalCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} countryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public regionControllerFindCountryRegions(countryId: string, options?: any) {
        return DictionaryApiFp(this.configuration).regionControllerFindCountryRegions(countryId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DiscountsApi - axios parameter creator
 * @export
 */
export const DiscountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDiscountDto} createDiscountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerCreate: async (createDiscountDto: CreateDiscountDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDiscountDto' is not null or undefined
            if (createDiscountDto === null || createDiscountDto === undefined) {
                throw new RequiredError('createDiscountDto','Required parameter createDiscountDto was null or undefined when calling discountsControllerCreate.');
            }
            const localVarPath = `/api/discounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createDiscountDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createDiscountDto !== undefined ? createDiscountDto : {})
                : (createDiscountDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerFindAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/discounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerFindAvailable: async (userId: number, orderId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling discountsControllerFindAvailable.');
            }
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling discountsControllerFindAvailable.');
            }
            const localVarPath = `/api/discounts/find-available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orderId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerFindByCode: async (orderId: number, code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling discountsControllerFindByCode.');
            }
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling discountsControllerFindByCode.');
            }
            const localVarPath = `/api/discounts/find-by-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerFindOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling discountsControllerFindOne.');
            }
            const localVarPath = `/api/discounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerGetDiscountProductTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/discounts/get-discount-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerRemove: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling discountsControllerRemove.');
            }
            const localVarPath = `/api/discounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDiscountDto} updateDiscountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerUpdate: async (id: string, updateDiscountDto: UpdateDiscountDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling discountsControllerUpdate.');
            }
            // verify required parameter 'updateDiscountDto' is not null or undefined
            if (updateDiscountDto === null || updateDiscountDto === undefined) {
                throw new RequiredError('updateDiscountDto','Required parameter updateDiscountDto was null or undefined when calling discountsControllerUpdate.');
            }
            const localVarPath = `/api/discounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateDiscountDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateDiscountDto !== undefined ? updateDiscountDto : {})
                : (updateDiscountDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscountsApi - functional programming interface
 * @export
 */
export const DiscountsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDiscountDto} createDiscountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsControllerCreate(createDiscountDto: CreateDiscountDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountDto>> {
            const localVarAxiosArgs = await DiscountsApiAxiosParamCreator(configuration).discountsControllerCreate(createDiscountDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsControllerFindAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiscountDto>>> {
            const localVarAxiosArgs = await DiscountsApiAxiosParamCreator(configuration).discountsControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsControllerFindAvailable(userId: number, orderId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiscountDto>>> {
            const localVarAxiosArgs = await DiscountsApiAxiosParamCreator(configuration).discountsControllerFindAvailable(userId, orderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} orderId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsControllerFindByCode(orderId: number, code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountDto>> {
            const localVarAxiosArgs = await DiscountsApiAxiosParamCreator(configuration).discountsControllerFindByCode(orderId, code, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsControllerFindOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscountDto>> {
            const localVarAxiosArgs = await DiscountsApiAxiosParamCreator(configuration).discountsControllerFindOne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsControllerGetDiscountProductTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiscountProductTypeDto>>> {
            const localVarAxiosArgs = await DiscountsApiAxiosParamCreator(configuration).discountsControllerGetDiscountProductTypes(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsControllerRemove(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DiscountsApiAxiosParamCreator(configuration).discountsControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDiscountDto} updateDiscountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsControllerUpdate(id: string, updateDiscountDto: UpdateDiscountDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDiscountDto>> {
            const localVarAxiosArgs = await DiscountsApiAxiosParamCreator(configuration).discountsControllerUpdate(id, updateDiscountDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DiscountsApi - factory interface
 * @export
 */
export const DiscountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateDiscountDto} createDiscountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerCreate(createDiscountDto: CreateDiscountDto, options?: any): AxiosPromise<DiscountDto> {
            return DiscountsApiFp(configuration).discountsControllerCreate(createDiscountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerFindAll(options?: any): AxiosPromise<Array<DiscountDto>> {
            return DiscountsApiFp(configuration).discountsControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerFindAvailable(userId: number, orderId: number, options?: any): AxiosPromise<Array<DiscountDto>> {
            return DiscountsApiFp(configuration).discountsControllerFindAvailable(userId, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orderId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerFindByCode(orderId: number, code: string, options?: any): AxiosPromise<DiscountDto> {
            return DiscountsApiFp(configuration).discountsControllerFindByCode(orderId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerFindOne(id: number, options?: any): AxiosPromise<DiscountDto> {
            return DiscountsApiFp(configuration).discountsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerGetDiscountProductTypes(options?: any): AxiosPromise<Array<DiscountProductTypeDto>> {
            return DiscountsApiFp(configuration).discountsControllerGetDiscountProductTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return DiscountsApiFp(configuration).discountsControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDiscountDto} updateDiscountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsControllerUpdate(id: string, updateDiscountDto: UpdateDiscountDto, options?: any): AxiosPromise<UpdateDiscountDto> {
            return DiscountsApiFp(configuration).discountsControllerUpdate(id, updateDiscountDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DiscountsApi - object-oriented interface
 * @export
 * @class DiscountsApi
 * @extends {BaseAPI}
 */
export class DiscountsApi extends BaseAPI {
    /**
     * 
     * @param {CreateDiscountDto} createDiscountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountsControllerCreate(createDiscountDto: CreateDiscountDto, options?: any) {
        return DiscountsApiFp(this.configuration).discountsControllerCreate(createDiscountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountsControllerFindAll(options?: any) {
        return DiscountsApiFp(this.configuration).discountsControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountsControllerFindAvailable(userId: number, orderId: number, options?: any) {
        return DiscountsApiFp(this.configuration).discountsControllerFindAvailable(userId, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orderId 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountsControllerFindByCode(orderId: number, code: string, options?: any) {
        return DiscountsApiFp(this.configuration).discountsControllerFindByCode(orderId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountsControllerFindOne(id: number, options?: any) {
        return DiscountsApiFp(this.configuration).discountsControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountsControllerGetDiscountProductTypes(options?: any) {
        return DiscountsApiFp(this.configuration).discountsControllerGetDiscountProductTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountsControllerRemove(id: string, options?: any) {
        return DiscountsApiFp(this.configuration).discountsControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateDiscountDto} updateDiscountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsApi
     */
    public discountsControllerUpdate(id: string, updateDiscountDto: UpdateDiscountDto, options?: any) {
        return DiscountsApiFp(this.configuration).discountsControllerUpdate(id, updateDiscountDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DongleUtilitiesApi - axios parameter creator
 * @export
 */
export const DongleUtilitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ComplexDto} complexDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dongleUtilsControllerCreate: async (complexDto: ComplexDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'complexDto' is not null or undefined
            if (complexDto === null || complexDto === undefined) {
                throw new RequiredError('complexDto','Required parameter complexDto was null or undefined when calling dongleUtilsControllerCreate.');
            }
            const localVarPath = `/api/dongle-utils/moa/connect/handle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof complexDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(complexDto !== undefined ? complexDto : {})
                : (complexDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateDongleEmergencyCodeDto} createDongleEmergencyCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dongleUtilsControllerGetEmergencyCode: async (createDongleEmergencyCodeDto: CreateDongleEmergencyCodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDongleEmergencyCodeDto' is not null or undefined
            if (createDongleEmergencyCodeDto === null || createDongleEmergencyCodeDto === undefined) {
                throw new RequiredError('createDongleEmergencyCodeDto','Required parameter createDongleEmergencyCodeDto was null or undefined when calling dongleUtilsControllerGetEmergencyCode.');
            }
            const localVarPath = `/api/dongle-utils/get-emergency-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createDongleEmergencyCodeDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createDongleEmergencyCodeDto !== undefined ? createDongleEmergencyCodeDto : {})
                : (createDongleEmergencyCodeDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DongleUpgradeDto} dongleUpgradeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dongleUtilsControllerGetUpgradeCode: async (dongleUpgradeDto: DongleUpgradeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dongleUpgradeDto' is not null or undefined
            if (dongleUpgradeDto === null || dongleUpgradeDto === undefined) {
                throw new RequiredError('dongleUpgradeDto','Required parameter dongleUpgradeDto was null or undefined when calling dongleUtilsControllerGetUpgradeCode.');
            }
            const localVarPath = `/api/dongle-utils/upgrade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof dongleUpgradeDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(dongleUpgradeDto !== undefined ? dongleUpgradeDto : {})
                : (dongleUpgradeDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RenewMembershipDto} renewMembershipDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dongleUtilsControllerRenewMembership: async (renewMembershipDto: RenewMembershipDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'renewMembershipDto' is not null or undefined
            if (renewMembershipDto === null || renewMembershipDto === undefined) {
                throw new RequiredError('renewMembershipDto','Required parameter renewMembershipDto was null or undefined when calling dongleUtilsControllerRenewMembership.');
            }
            const localVarPath = `/api/dongle-utils/renewMembership`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof renewMembershipDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(renewMembershipDto !== undefined ? renewMembershipDto : {})
                : (renewMembershipDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DongleUtilitiesApi - functional programming interface
 * @export
 */
export const DongleUtilitiesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ComplexDto} complexDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dongleUtilsControllerCreate(complexDto: ComplexDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DongleUtilitiesApiAxiosParamCreator(configuration).dongleUtilsControllerCreate(complexDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CreateDongleEmergencyCodeDto} createDongleEmergencyCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dongleUtilsControllerGetEmergencyCode(createDongleEmergencyCodeDto: CreateDongleEmergencyCodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DongleUtilitiesApiAxiosParamCreator(configuration).dongleUtilsControllerGetEmergencyCode(createDongleEmergencyCodeDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DongleUpgradeDto} dongleUpgradeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dongleUtilsControllerGetUpgradeCode(dongleUpgradeDto: DongleUpgradeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DongleUtilitiesApiAxiosParamCreator(configuration).dongleUtilsControllerGetUpgradeCode(dongleUpgradeDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RenewMembershipDto} renewMembershipDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dongleUtilsControllerRenewMembership(renewMembershipDto: RenewMembershipDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DongleUtilitiesApiAxiosParamCreator(configuration).dongleUtilsControllerRenewMembership(renewMembershipDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DongleUtilitiesApi - factory interface
 * @export
 */
export const DongleUtilitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {ComplexDto} complexDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dongleUtilsControllerCreate(complexDto: ComplexDto, options?: any): AxiosPromise<void> {
            return DongleUtilitiesApiFp(configuration).dongleUtilsControllerCreate(complexDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateDongleEmergencyCodeDto} createDongleEmergencyCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dongleUtilsControllerGetEmergencyCode(createDongleEmergencyCodeDto: CreateDongleEmergencyCodeDto, options?: any): AxiosPromise<void> {
            return DongleUtilitiesApiFp(configuration).dongleUtilsControllerGetEmergencyCode(createDongleEmergencyCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DongleUpgradeDto} dongleUpgradeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dongleUtilsControllerGetUpgradeCode(dongleUpgradeDto: DongleUpgradeDto, options?: any): AxiosPromise<void> {
            return DongleUtilitiesApiFp(configuration).dongleUtilsControllerGetUpgradeCode(dongleUpgradeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RenewMembershipDto} renewMembershipDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dongleUtilsControllerRenewMembership(renewMembershipDto: RenewMembershipDto, options?: any): AxiosPromise<void> {
            return DongleUtilitiesApiFp(configuration).dongleUtilsControllerRenewMembership(renewMembershipDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DongleUtilitiesApi - object-oriented interface
 * @export
 * @class DongleUtilitiesApi
 * @extends {BaseAPI}
 */
export class DongleUtilitiesApi extends BaseAPI {
    /**
     * 
     * @param {ComplexDto} complexDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DongleUtilitiesApi
     */
    public dongleUtilsControllerCreate(complexDto: ComplexDto, options?: any) {
        return DongleUtilitiesApiFp(this.configuration).dongleUtilsControllerCreate(complexDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateDongleEmergencyCodeDto} createDongleEmergencyCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DongleUtilitiesApi
     */
    public dongleUtilsControllerGetEmergencyCode(createDongleEmergencyCodeDto: CreateDongleEmergencyCodeDto, options?: any) {
        return DongleUtilitiesApiFp(this.configuration).dongleUtilsControllerGetEmergencyCode(createDongleEmergencyCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DongleUpgradeDto} dongleUpgradeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DongleUtilitiesApi
     */
    public dongleUtilsControllerGetUpgradeCode(dongleUpgradeDto: DongleUpgradeDto, options?: any) {
        return DongleUtilitiesApiFp(this.configuration).dongleUtilsControllerGetUpgradeCode(dongleUpgradeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RenewMembershipDto} renewMembershipDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DongleUtilitiesApi
     */
    public dongleUtilsControllerRenewMembership(renewMembershipDto: RenewMembershipDto, options?: any) {
        return DongleUtilitiesApiFp(this.configuration).dongleUtilsControllerRenewMembership(renewMembershipDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DonglesApi - axios parameter creator
 * @export
 */
export const DonglesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AssignDongleDto} assignDongleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerAssignDongle: async (assignDongleDto: AssignDongleDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignDongleDto' is not null or undefined
            if (assignDongleDto === null || assignDongleDto === undefined) {
                throw new RequiredError('assignDongleDto','Required parameter assignDongleDto was null or undefined when calling donglesControllerAssignDongle.');
            }
            const localVarPath = `/api/dongles/assign-dongle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof assignDongleDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(assignDongleDto !== undefined ? assignDongleDto : {})
                : (assignDongleDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject28} inlineObject28 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerChangeDongleStatus: async (inlineObject28: InlineObject28, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject28' is not null or undefined
            if (inlineObject28 === null || inlineObject28 === undefined) {
                throw new RequiredError('inlineObject28','Required parameter inlineObject28 was null or undefined when calling donglesControllerChangeDongleStatus.');
            }
            const localVarPath = `/api/dongles/change-dongle-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject28 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject28 !== undefined ? inlineObject28 : {})
                : (inlineObject28 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerFindAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dongles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerFindAllAvailable: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dongles/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerFindAllByOrganization: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling donglesControllerFindAllByOrganization.');
            }
            const localVarPath = `/api/dongles/findAllByOrganization/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerFindAllByUser: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling donglesControllerFindAllByUser.');
            }
            const localVarPath = `/api/dongles/findAllByUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Search limit
         * @param {number} [offset] Search offset
         * @param {string} [searchText] Search text
         * @param {string} [sortBy] Sorting field
         * @param {boolean} [sortDesc] Descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerGetAllInventory: async (limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dongles/get-all-inventory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerGetDongleActivities: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling donglesControllerGetDongleActivities.');
            }
            const localVarPath = `/api/dongles/get-dongle-activities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerGetDongleEmergencyCode: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling donglesControllerGetDongleEmergencyCode.');
            }
            const localVarPath = `/api/dongles/get-dongle-emergency-code/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerGetDongleLatestReauthCode: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling donglesControllerGetDongleLatestReauthCode.');
            }
            const localVarPath = `/api/dongles/get-dongle-latest-reauth-code/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serialCode 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerGetDongleReauthCode: async (serialCode: string, password: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serialCode' is not null or undefined
            if (serialCode === null || serialCode === undefined) {
                throw new RequiredError('serialCode','Required parameter serialCode was null or undefined when calling donglesControllerGetDongleReauthCode.');
            }
            // verify required parameter 'password' is not null or undefined
            if (password === null || password === undefined) {
                throw new RequiredError('password','Required parameter password was null or undefined when calling donglesControllerGetDongleReauthCode.');
            }
            const localVarPath = `/api/dongles/get-dongle-reauth-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (serialCode !== undefined) {
                localVarQueryParameter['serialCode'] = serialCode;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DongleUpgradeDto} dongleUpgradeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerGetUpgradeCode: async (dongleUpgradeDto: DongleUpgradeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dongleUpgradeDto' is not null or undefined
            if (dongleUpgradeDto === null || dongleUpgradeDto === undefined) {
                throw new RequiredError('dongleUpgradeDto','Required parameter dongleUpgradeDto was null or undefined when calling donglesControllerGetUpgradeCode.');
            }
            const localVarPath = `/api/dongles/upgrade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof dongleUpgradeDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(dongleUpgradeDto !== undefined ? dongleUpgradeDto : {})
                : (dongleUpgradeDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerGetUpgradeTypes: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling donglesControllerGetUpgradeTypes.');
            }
            const localVarPath = `/api/dongles/upgradeTypes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertDongleByAdminDto} insertDongleByAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerInsertDongleByAdmin: async (insertDongleByAdminDto: InsertDongleByAdminDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertDongleByAdminDto' is not null or undefined
            if (insertDongleByAdminDto === null || insertDongleByAdminDto === undefined) {
                throw new RequiredError('insertDongleByAdminDto','Required parameter insertDongleByAdminDto was null or undefined when calling donglesControllerInsertDongleByAdmin.');
            }
            const localVarPath = `/api/dongles/insert-dongle-by-admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof insertDongleByAdminDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(insertDongleByAdminDto !== undefined ? insertDongleByAdminDto : {})
                : (insertDongleByAdminDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertDongleByOwnerDto} insertDongleByOwnerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerInsertDongleByOwner: async (insertDongleByOwnerDto: InsertDongleByOwnerDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertDongleByOwnerDto' is not null or undefined
            if (insertDongleByOwnerDto === null || insertDongleByOwnerDto === undefined) {
                throw new RequiredError('insertDongleByOwnerDto','Required parameter insertDongleByOwnerDto was null or undefined when calling donglesControllerInsertDongleByOwner.');
            }
            const localVarPath = `/api/dongles/insert-dongle-by-owner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof insertDongleByOwnerDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(insertDongleByOwnerDto !== undefined ? insertDongleByOwnerDto : {})
                : (insertDongleByOwnerDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<CreateDongleDto>} createDongleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerLoadInventory: async (createDongleDto: Array<CreateDongleDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDongleDto' is not null or undefined
            if (createDongleDto === null || createDongleDto === undefined) {
                throw new RequiredError('createDongleDto','Required parameter createDongleDto was null or undefined when calling donglesControllerLoadInventory.');
            }
            const localVarPath = `/api/dongles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createDongleDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createDongleDto !== undefined ? createDongleDto : {})
                : (createDongleDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject27} inlineObject27 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerRemoveDongle: async (inlineObject27: InlineObject27, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject27' is not null or undefined
            if (inlineObject27 === null || inlineObject27 === undefined) {
                throw new RequiredError('inlineObject27','Required parameter inlineObject27 was null or undefined when calling donglesControllerRemoveDongle.');
            }
            const localVarPath = `/api/dongles/remove-dongle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject27 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject27 !== undefined ? inlineObject27 : {})
                : (inlineObject27 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerRemoveInventoryItem: async (requestBody: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling donglesControllerRemoveInventoryItem.');
            }
            const localVarPath = `/api/dongles/remove-inventory-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof requestBody !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : (requestBody || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RenewLeaseDto} renewLeaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerRenewLease: async (renewLeaseDto: RenewLeaseDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'renewLeaseDto' is not null or undefined
            if (renewLeaseDto === null || renewLeaseDto === undefined) {
                throw new RequiredError('renewLeaseDto','Required parameter renewLeaseDto was null or undefined when calling donglesControllerRenewLease.');
            }
            const localVarPath = `/api/dongles/renewLease`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof renewLeaseDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(renewLeaseDto !== undefined ? renewLeaseDto : {})
                : (renewLeaseDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RenewMembershipDto} renewMembershipDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerRenewMembership: async (renewMembershipDto: RenewMembershipDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'renewMembershipDto' is not null or undefined
            if (renewMembershipDto === null || renewMembershipDto === undefined) {
                throw new RequiredError('renewMembershipDto','Required parameter renewMembershipDto was null or undefined when calling donglesControllerRenewMembership.');
            }
            const localVarPath = `/api/dongles/renewMembership`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof renewMembershipDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(renewMembershipDto !== undefined ? renewMembershipDto : {})
                : (renewMembershipDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerResetDongleCheatCounter: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling donglesControllerResetDongleCheatCounter.');
            }
            const localVarPath = `/api/dongles/reset-dongle-cheat-counter/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TransferDongleDto} transferDongleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerTransferDongle: async (transferDongleDto: TransferDongleDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transferDongleDto' is not null or undefined
            if (transferDongleDto === null || transferDongleDto === undefined) {
                throw new RequiredError('transferDongleDto','Required parameter transferDongleDto was null or undefined when calling donglesControllerTransferDongle.');
            }
            const localVarPath = `/api/dongles/transfer-dongle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof transferDongleDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(transferDongleDto !== undefined ? transferDongleDto : {})
                : (transferDongleDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject26} inlineObject26 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerUnAssignDongle: async (inlineObject26: InlineObject26, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject26' is not null or undefined
            if (inlineObject26 === null || inlineObject26 === undefined) {
                throw new RequiredError('inlineObject26','Required parameter inlineObject26 was null or undefined when calling donglesControllerUnAssignDongle.');
            }
            const localVarPath = `/api/dongles/un-assign-dongle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject26 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject26 !== undefined ? inlineObject26 : {})
                : (inlineObject26 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerUpdateFlagRequest: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dongles/update-flag-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerUpdateOneFlagRequest: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling donglesControllerUpdateOneFlagRequest.');
            }
            const localVarPath = `/api/dongles/update-one-flag-request/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DonglesApi - functional programming interface
 * @export
 */
export const DonglesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AssignDongleDto} assignDongleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerAssignDongle(assignDongleDto: AssignDongleDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerAssignDongle(assignDongleDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject28} inlineObject28 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerChangeDongleStatus(inlineObject28: InlineObject28, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerChangeDongleStatus(inlineObject28, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerFindAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DongleUsersInfoDto>>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerFindAllAvailable(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DongleDto>>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerFindAllAvailable(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerFindAllByOrganization(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductInfoDto>>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerFindAllByOrganization(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerFindAllByUser(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductInfoDto>>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerFindAllByUser(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [limit] Search limit
         * @param {number} [offset] Search offset
         * @param {string} [searchText] Search text
         * @param {string} [sortBy] Sorting field
         * @param {boolean} [sortDesc] Descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerGetAllInventory(limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InventoryResponseDto>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerGetAllInventory(limit, offset, searchText, sortBy, sortDesc, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerGetDongleActivities(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DongleActivityDto>>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerGetDongleActivities(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerGetDongleEmergencyCode(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerGetDongleEmergencyCode(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerGetDongleLatestReauthCode(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DongleReauthCodeDto>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerGetDongleLatestReauthCode(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} serialCode 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerGetDongleReauthCode(serialCode: string, password: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerGetDongleReauthCode(serialCode, password, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {DongleUpgradeDto} dongleUpgradeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerGetUpgradeCode(dongleUpgradeDto: DongleUpgradeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerGetUpgradeCode(dongleUpgradeDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerGetUpgradeTypes(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerGetUpgradeTypes(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InsertDongleByAdminDto} insertDongleByAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerInsertDongleByAdmin(insertDongleByAdminDto: InsertDongleByAdminDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerInsertDongleByAdmin(insertDongleByAdminDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InsertDongleByOwnerDto} insertDongleByOwnerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerInsertDongleByOwner(insertDongleByOwnerDto: InsertDongleByOwnerDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerInsertDongleByOwner(insertDongleByOwnerDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<CreateDongleDto>} createDongleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerLoadInventory(createDongleDto: Array<CreateDongleDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeDonglesResponseDto>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerLoadInventory(createDongleDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject27} inlineObject27 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerRemoveDongle(inlineObject27: InlineObject27, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerRemoveDongle(inlineObject27, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerRemoveInventoryItem(requestBody: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeDonglesResponseDto>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerRemoveInventoryItem(requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RenewLeaseDto} renewLeaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerRenewLease(renewLeaseDto: RenewLeaseDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerRenewLease(renewLeaseDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RenewMembershipDto} renewMembershipDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerRenewMembership(renewMembershipDto: RenewMembershipDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerRenewMembership(renewMembershipDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerResetDongleCheatCounter(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerResetDongleCheatCounter(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {TransferDongleDto} transferDongleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerTransferDongle(transferDongleDto: TransferDongleDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerTransferDongle(transferDongleDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject26} inlineObject26 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerUnAssignDongle(inlineObject26: InlineObject26, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerUnAssignDongle(inlineObject26, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerUpdateFlagRequest(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerUpdateFlagRequest(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donglesControllerUpdateOneFlagRequest(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DonglesApiAxiosParamCreator(configuration).donglesControllerUpdateOneFlagRequest(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DonglesApi - factory interface
 * @export
 */
export const DonglesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {AssignDongleDto} assignDongleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerAssignDongle(assignDongleDto: AssignDongleDto, options?: any): AxiosPromise<void> {
            return DonglesApiFp(configuration).donglesControllerAssignDongle(assignDongleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject28} inlineObject28 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerChangeDongleStatus(inlineObject28: InlineObject28, options?: any): AxiosPromise<void> {
            return DonglesApiFp(configuration).donglesControllerChangeDongleStatus(inlineObject28, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerFindAll(options?: any): AxiosPromise<Array<DongleUsersInfoDto>> {
            return DonglesApiFp(configuration).donglesControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerFindAllAvailable(options?: any): AxiosPromise<Array<DongleDto>> {
            return DonglesApiFp(configuration).donglesControllerFindAllAvailable(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerFindAllByOrganization(id: number, options?: any): AxiosPromise<Array<ProductInfoDto>> {
            return DonglesApiFp(configuration).donglesControllerFindAllByOrganization(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerFindAllByUser(id: number, options?: any): AxiosPromise<Array<ProductInfoDto>> {
            return DonglesApiFp(configuration).donglesControllerFindAllByUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Search limit
         * @param {number} [offset] Search offset
         * @param {string} [searchText] Search text
         * @param {string} [sortBy] Sorting field
         * @param {boolean} [sortDesc] Descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerGetAllInventory(limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options?: any): AxiosPromise<InventoryResponseDto> {
            return DonglesApiFp(configuration).donglesControllerGetAllInventory(limit, offset, searchText, sortBy, sortDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerGetDongleActivities(id: number, options?: any): AxiosPromise<Array<DongleActivityDto>> {
            return DonglesApiFp(configuration).donglesControllerGetDongleActivities(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerGetDongleEmergencyCode(id: number, options?: any): AxiosPromise<void> {
            return DonglesApiFp(configuration).donglesControllerGetDongleEmergencyCode(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerGetDongleLatestReauthCode(id: number, options?: any): AxiosPromise<DongleReauthCodeDto> {
            return DonglesApiFp(configuration).donglesControllerGetDongleLatestReauthCode(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} serialCode 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerGetDongleReauthCode(serialCode: string, password: string, options?: any): AxiosPromise<string> {
            return DonglesApiFp(configuration).donglesControllerGetDongleReauthCode(serialCode, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DongleUpgradeDto} dongleUpgradeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerGetUpgradeCode(dongleUpgradeDto: DongleUpgradeDto, options?: any): AxiosPromise<void> {
            return DonglesApiFp(configuration).donglesControllerGetUpgradeCode(dongleUpgradeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerGetUpgradeTypes(id: number, options?: any): AxiosPromise<void> {
            return DonglesApiFp(configuration).donglesControllerGetUpgradeTypes(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertDongleByAdminDto} insertDongleByAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerInsertDongleByAdmin(insertDongleByAdminDto: InsertDongleByAdminDto, options?: any): AxiosPromise<void> {
            return DonglesApiFp(configuration).donglesControllerInsertDongleByAdmin(insertDongleByAdminDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertDongleByOwnerDto} insertDongleByOwnerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerInsertDongleByOwner(insertDongleByOwnerDto: InsertDongleByOwnerDto, options?: any): AxiosPromise<void> {
            return DonglesApiFp(configuration).donglesControllerInsertDongleByOwner(insertDongleByOwnerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<CreateDongleDto>} createDongleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerLoadInventory(createDongleDto: Array<CreateDongleDto>, options?: any): AxiosPromise<ChangeDonglesResponseDto> {
            return DonglesApiFp(configuration).donglesControllerLoadInventory(createDongleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject27} inlineObject27 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerRemoveDongle(inlineObject27: InlineObject27, options?: any): AxiosPromise<void> {
            return DonglesApiFp(configuration).donglesControllerRemoveDongle(inlineObject27, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerRemoveInventoryItem(requestBody: Array<string>, options?: any): AxiosPromise<ChangeDonglesResponseDto> {
            return DonglesApiFp(configuration).donglesControllerRemoveInventoryItem(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RenewLeaseDto} renewLeaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerRenewLease(renewLeaseDto: RenewLeaseDto, options?: any): AxiosPromise<void> {
            return DonglesApiFp(configuration).donglesControllerRenewLease(renewLeaseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RenewMembershipDto} renewMembershipDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerRenewMembership(renewMembershipDto: RenewMembershipDto, options?: any): AxiosPromise<void> {
            return DonglesApiFp(configuration).donglesControllerRenewMembership(renewMembershipDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerResetDongleCheatCounter(id: number, options?: any): AxiosPromise<void> {
            return DonglesApiFp(configuration).donglesControllerResetDongleCheatCounter(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TransferDongleDto} transferDongleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerTransferDongle(transferDongleDto: TransferDongleDto, options?: any): AxiosPromise<void> {
            return DonglesApiFp(configuration).donglesControllerTransferDongle(transferDongleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject26} inlineObject26 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerUnAssignDongle(inlineObject26: InlineObject26, options?: any): AxiosPromise<void> {
            return DonglesApiFp(configuration).donglesControllerUnAssignDongle(inlineObject26, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerUpdateFlagRequest(options?: any): AxiosPromise<void> {
            return DonglesApiFp(configuration).donglesControllerUpdateFlagRequest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donglesControllerUpdateOneFlagRequest(id: number, options?: any): AxiosPromise<void> {
            return DonglesApiFp(configuration).donglesControllerUpdateOneFlagRequest(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DonglesApi - object-oriented interface
 * @export
 * @class DonglesApi
 * @extends {BaseAPI}
 */
export class DonglesApi extends BaseAPI {
    /**
     * 
     * @param {AssignDongleDto} assignDongleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerAssignDongle(assignDongleDto: AssignDongleDto, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerAssignDongle(assignDongleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject28} inlineObject28 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerChangeDongleStatus(inlineObject28: InlineObject28, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerChangeDongleStatus(inlineObject28, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerFindAll(options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerFindAllAvailable(options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerFindAllAvailable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerFindAllByOrganization(id: number, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerFindAllByOrganization(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerFindAllByUser(id: number, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerFindAllByUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Search limit
     * @param {number} [offset] Search offset
     * @param {string} [searchText] Search text
     * @param {string} [sortBy] Sorting field
     * @param {boolean} [sortDesc] Descending sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerGetAllInventory(limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerGetAllInventory(limit, offset, searchText, sortBy, sortDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerGetDongleActivities(id: number, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerGetDongleActivities(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerGetDongleEmergencyCode(id: number, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerGetDongleEmergencyCode(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerGetDongleLatestReauthCode(id: number, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerGetDongleLatestReauthCode(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} serialCode 
     * @param {string} password 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerGetDongleReauthCode(serialCode: string, password: string, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerGetDongleReauthCode(serialCode, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DongleUpgradeDto} dongleUpgradeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerGetUpgradeCode(dongleUpgradeDto: DongleUpgradeDto, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerGetUpgradeCode(dongleUpgradeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerGetUpgradeTypes(id: number, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerGetUpgradeTypes(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertDongleByAdminDto} insertDongleByAdminDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerInsertDongleByAdmin(insertDongleByAdminDto: InsertDongleByAdminDto, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerInsertDongleByAdmin(insertDongleByAdminDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertDongleByOwnerDto} insertDongleByOwnerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerInsertDongleByOwner(insertDongleByOwnerDto: InsertDongleByOwnerDto, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerInsertDongleByOwner(insertDongleByOwnerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<CreateDongleDto>} createDongleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerLoadInventory(createDongleDto: Array<CreateDongleDto>, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerLoadInventory(createDongleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject27} inlineObject27 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerRemoveDongle(inlineObject27: InlineObject27, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerRemoveDongle(inlineObject27, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerRemoveInventoryItem(requestBody: Array<string>, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerRemoveInventoryItem(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RenewLeaseDto} renewLeaseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerRenewLease(renewLeaseDto: RenewLeaseDto, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerRenewLease(renewLeaseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RenewMembershipDto} renewMembershipDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerRenewMembership(renewMembershipDto: RenewMembershipDto, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerRenewMembership(renewMembershipDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerResetDongleCheatCounter(id: number, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerResetDongleCheatCounter(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TransferDongleDto} transferDongleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerTransferDongle(transferDongleDto: TransferDongleDto, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerTransferDongle(transferDongleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject26} inlineObject26 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerUnAssignDongle(inlineObject26: InlineObject26, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerUnAssignDongle(inlineObject26, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerUpdateFlagRequest(options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerUpdateFlagRequest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonglesApi
     */
    public donglesControllerUpdateOneFlagRequest(id: number, options?: any) {
        return DonglesApiFp(this.configuration).donglesControllerUpdateOneFlagRequest(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DownloadsApi - axios parameter creator
 * @export
 */
export const DownloadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadsControllerDownloadFile: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadsControllerDownloadFile.');
            }
            const localVarPath = `/api/downloads/download-file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} release 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadsControllerFindFilesByRelease: async (release: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'release' is not null or undefined
            if (release === null || release === undefined) {
                throw new RequiredError('release','Required parameter release was null or undefined when calling downloadsControllerFindFilesByRelease.');
            }
            const localVarPath = `/api/downloads/files/{release}`
                .replace(`{${"release"}}`, encodeURIComponent(String(release)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadsControllerFindUserReleases: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/downloads/userReleases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadsControllerGetFileDownloadUrl: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadsControllerGetFileDownloadUrl.');
            }
            const localVarPath = `/api/downloads/get-file-download-url/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DownloadsApi - functional programming interface
 * @export
 */
export const DownloadsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadsControllerDownloadFile(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await DownloadsApiAxiosParamCreator(configuration).downloadsControllerDownloadFile(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} release 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadsControllerFindFilesByRelease(release: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileDto>>> {
            const localVarAxiosArgs = await DownloadsApiAxiosParamCreator(configuration).downloadsControllerFindFilesByRelease(release, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadsControllerFindUserReleases(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReleaseForDownloadsDto>>> {
            const localVarAxiosArgs = await DownloadsApiAxiosParamCreator(configuration).downloadsControllerFindUserReleases(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadsControllerGetFileDownloadUrl(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DownloadsApiAxiosParamCreator(configuration).downloadsControllerGetFileDownloadUrl(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DownloadsApi - factory interface
 * @export
 */
export const DownloadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadsControllerDownloadFile(id: number, options?: any): AxiosPromise<object> {
            return DownloadsApiFp(configuration).downloadsControllerDownloadFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} release 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadsControllerFindFilesByRelease(release: number, options?: any): AxiosPromise<Array<FileDto>> {
            return DownloadsApiFp(configuration).downloadsControllerFindFilesByRelease(release, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadsControllerFindUserReleases(options?: any): AxiosPromise<Array<ReleaseForDownloadsDto>> {
            return DownloadsApiFp(configuration).downloadsControllerFindUserReleases(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadsControllerGetFileDownloadUrl(id: number, options?: any): AxiosPromise<string> {
            return DownloadsApiFp(configuration).downloadsControllerGetFileDownloadUrl(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DownloadsApi - object-oriented interface
 * @export
 * @class DownloadsApi
 * @extends {BaseAPI}
 */
export class DownloadsApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadsApi
     */
    public downloadsControllerDownloadFile(id: number, options?: any) {
        return DownloadsApiFp(this.configuration).downloadsControllerDownloadFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} release 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadsApi
     */
    public downloadsControllerFindFilesByRelease(release: number, options?: any) {
        return DownloadsApiFp(this.configuration).downloadsControllerFindFilesByRelease(release, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadsApi
     */
    public downloadsControllerFindUserReleases(options?: any) {
        return DownloadsApiFp(this.configuration).downloadsControllerFindUserReleases(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DownloadsApi
     */
    public downloadsControllerGetFileDownloadUrl(id: number, options?: any) {
        return DownloadsApiFp(this.configuration).downloadsControllerGetFileDownloadUrl(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeedbackApi - axios parameter creator
 * @export
 */
export const FeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFeedbackDto} createFeedbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackControllerAddFeedback: async (createFeedbackDto: CreateFeedbackDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFeedbackDto' is not null or undefined
            if (createFeedbackDto === null || createFeedbackDto === undefined) {
                throw new RequiredError('createFeedbackDto','Required parameter createFeedbackDto was null or undefined when calling feedbackControllerAddFeedback.');
            }
            const localVarPath = `/api/feedback/add-feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createFeedbackDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createFeedbackDto !== undefined ? createFeedbackDto : {})
                : (createFeedbackDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Search limit
         * @param {number} [offset] Search offset
         * @param {string} [searchText] Search text
         * @param {string} [sortBy] Sorting field
         * @param {boolean} [sortDesc] Descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackControllerFindAll: async (limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/feedback/find-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} feedbackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackControllerGetFeedbackById: async (feedbackId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'feedbackId' is not null or undefined
            if (feedbackId === null || feedbackId === undefined) {
                throw new RequiredError('feedbackId','Required parameter feedbackId was null or undefined when calling feedbackControllerGetFeedbackById.');
            }
            const localVarPath = `/api/feedback/:/feedbackId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (feedbackId !== undefined) {
                localVarQueryParameter['feedbackId'] = feedbackId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedbackApi - functional programming interface
 * @export
 */
export const FeedbackApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFeedbackDto} createFeedbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackControllerAddFeedback(createFeedbackDto: CreateFeedbackDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await FeedbackApiAxiosParamCreator(configuration).feedbackControllerAddFeedback(createFeedbackDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [limit] Search limit
         * @param {number} [offset] Search offset
         * @param {string} [searchText] Search text
         * @param {string} [sortBy] Sorting field
         * @param {boolean} [sortDesc] Descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackControllerFindAll(limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeedbackSearchResponseDto>>> {
            const localVarAxiosArgs = await FeedbackApiAxiosParamCreator(configuration).feedbackControllerFindAll(limit, offset, searchText, sortBy, sortDesc, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} feedbackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackControllerGetFeedbackById(feedbackId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeedbackResponseDto>>> {
            const localVarAxiosArgs = await FeedbackApiAxiosParamCreator(configuration).feedbackControllerGetFeedbackById(feedbackId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FeedbackApi - factory interface
 * @export
 */
export const FeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateFeedbackDto} createFeedbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackControllerAddFeedback(createFeedbackDto: CreateFeedbackDto, options?: any): AxiosPromise<void> {
            return FeedbackApiFp(configuration).feedbackControllerAddFeedback(createFeedbackDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Search limit
         * @param {number} [offset] Search offset
         * @param {string} [searchText] Search text
         * @param {string} [sortBy] Sorting field
         * @param {boolean} [sortDesc] Descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackControllerFindAll(limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options?: any): AxiosPromise<Array<FeedbackSearchResponseDto>> {
            return FeedbackApiFp(configuration).feedbackControllerFindAll(limit, offset, searchText, sortBy, sortDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} feedbackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackControllerGetFeedbackById(feedbackId: string, options?: any): AxiosPromise<Array<FeedbackResponseDto>> {
            return FeedbackApiFp(configuration).feedbackControllerGetFeedbackById(feedbackId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedbackApi - object-oriented interface
 * @export
 * @class FeedbackApi
 * @extends {BaseAPI}
 */
export class FeedbackApi extends BaseAPI {
    /**
     * 
     * @param {CreateFeedbackDto} createFeedbackDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackControllerAddFeedback(createFeedbackDto: CreateFeedbackDto, options?: any) {
        return FeedbackApiFp(this.configuration).feedbackControllerAddFeedback(createFeedbackDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Search limit
     * @param {number} [offset] Search offset
     * @param {string} [searchText] Search text
     * @param {string} [sortBy] Sorting field
     * @param {boolean} [sortDesc] Descending sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackControllerFindAll(limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options?: any) {
        return FeedbackApiFp(this.configuration).feedbackControllerFindAll(limit, offset, searchText, sortBy, sortDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} feedbackId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackControllerGetFeedbackById(feedbackId: string, options?: any) {
        return FeedbackApiFp(this.configuration).feedbackControllerGetFeedbackById(feedbackId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoicesApi - axios parameter creator
 * @export
 */
export const InvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesControllerCreateInvoicesForPaidOrder: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoices/create-invoices-for-paid-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesControllerDownloadInvoicePdf: async (invoiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            if (invoiceId === null || invoiceId === undefined) {
                throw new RequiredError('invoiceId','Required parameter invoiceId was null or undefined when calling invoicesControllerDownloadInvoicePdf.');
            }
            const localVarPath = `/api/invoices/download-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesControllerDownloadInvoiceWithToken: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling invoicesControllerDownloadInvoiceWithToken.');
            }
            const localVarPath = `/api/invoices/download-invoice-with-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesControllerGetOrganizationInvoices: async (organizationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling invoicesControllerGetOrganizationInvoices.');
            }
            const localVarPath = `/api/invoices/organization-invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesControllerGetUserInvoices: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoices/user-invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesControllerSendInvoiceDownloadLinksToEmail: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoices/send-invoice-download-links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoicesApi - functional programming interface
 * @export
 */
export const InvoicesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesControllerCreateInvoicesForPaidOrder(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).invoicesControllerCreateInvoicesForPaidOrder(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesControllerDownloadInvoicePdf(invoiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).invoicesControllerDownloadInvoicePdf(invoiceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesControllerDownloadInvoiceWithToken(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).invoicesControllerDownloadInvoiceWithToken(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesControllerGetOrganizationInvoices(organizationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceDto>>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).invoicesControllerGetOrganizationInvoices(organizationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesControllerGetUserInvoices(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceDto>>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).invoicesControllerGetUserInvoices(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesControllerSendInvoiceDownloadLinksToEmail(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await InvoicesApiAxiosParamCreator(configuration).invoicesControllerSendInvoiceDownloadLinksToEmail(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InvoicesApi - factory interface
 * @export
 */
export const InvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesControllerCreateInvoicesForPaidOrder(options?: any): AxiosPromise<void> {
            return InvoicesApiFp(configuration).invoicesControllerCreateInvoicesForPaidOrder(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesControllerDownloadInvoicePdf(invoiceId: string, options?: any): AxiosPromise<object> {
            return InvoicesApiFp(configuration).invoicesControllerDownloadInvoicePdf(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesControllerDownloadInvoiceWithToken(token: string, options?: any): AxiosPromise<object> {
            return InvoicesApiFp(configuration).invoicesControllerDownloadInvoiceWithToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesControllerGetOrganizationInvoices(organizationId: string, options?: any): AxiosPromise<Array<InvoiceDto>> {
            return InvoicesApiFp(configuration).invoicesControllerGetOrganizationInvoices(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesControllerGetUserInvoices(options?: any): AxiosPromise<Array<InvoiceDto>> {
            return InvoicesApiFp(configuration).invoicesControllerGetUserInvoices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesControllerSendInvoiceDownloadLinksToEmail(options?: any): AxiosPromise<void> {
            return InvoicesApiFp(configuration).invoicesControllerSendInvoiceDownloadLinksToEmail(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoicesApi - object-oriented interface
 * @export
 * @class InvoicesApi
 * @extends {BaseAPI}
 */
export class InvoicesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesControllerCreateInvoicesForPaidOrder(options?: any) {
        return InvoicesApiFp(this.configuration).invoicesControllerCreateInvoicesForPaidOrder(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesControllerDownloadInvoicePdf(invoiceId: string, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesControllerDownloadInvoicePdf(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesControllerDownloadInvoiceWithToken(token: string, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesControllerDownloadInvoiceWithToken(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesControllerGetOrganizationInvoices(organizationId: string, options?: any) {
        return InvoicesApiFp(this.configuration).invoicesControllerGetOrganizationInvoices(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesControllerGetUserInvoices(options?: any) {
        return InvoicesApiFp(this.configuration).invoicesControllerGetUserInvoices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public invoicesControllerSendInvoiceDownloadLinksToEmail(options?: any) {
        return InvoicesApiFp(this.configuration).invoicesControllerSendInvoiceDownloadLinksToEmail(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrderDto} createOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerAddOrder: async (createOrderDto: CreateOrderDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderDto' is not null or undefined
            if (createOrderDto === null || createOrderDto === undefined) {
                throw new RequiredError('createOrderDto','Required parameter createOrderDto was null or undefined when calling ordersControllerAddOrder.');
            }
            const localVarPath = `/api/orders/addOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createOrderDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createOrderDto !== undefined ? createOrderDto : {})
                : (createOrderDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject6} inlineObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerChangeOrderNotes: async (inlineObject6: InlineObject6, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject6' is not null or undefined
            if (inlineObject6 === null || inlineObject6 === undefined) {
                throw new RequiredError('inlineObject6','Required parameter inlineObject6 was null or undefined when calling ordersControllerChangeOrderNotes.');
            }
            const localVarPath = `/api/orders/change-order-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject6 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject6 !== undefined ? inlineObject6 : {})
                : (inlineObject6 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerClearDiscountsForUnpaidOrders: async (inlineObject1: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject1' is not null or undefined
            if (inlineObject1 === null || inlineObject1 === undefined) {
                throw new RequiredError('inlineObject1','Required parameter inlineObject1 was null or undefined when calling ordersControllerClearDiscountsForUnpaidOrders.');
            }
            const localVarPath = `/api/orders/clear-discounts-for-unpaid-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject1 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject1 !== undefined ? inlineObject1 : {})
                : (inlineObject1 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerConfirmMissedPaymentOrders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/confirm-missed-payment-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerCreateSession: async (inlineObject: InlineObject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject' is not null or undefined
            if (inlineObject === null || inlineObject === undefined) {
                throw new RequiredError('inlineObject','Required parameter inlineObject was null or undefined when calling ordersControllerCreateSession.');
            }
            const localVarPath = `/api/orders/create-checkout-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject !== undefined ? inlineObject : {})
                : (inlineObject || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject5} inlineObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerDeleteOrder: async (inlineObject5: InlineObject5, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject5' is not null or undefined
            if (inlineObject5 === null || inlineObject5 === undefined) {
                throw new RequiredError('inlineObject5','Required parameter inlineObject5 was null or undefined when calling ordersControllerDeleteOrder.');
            }
            const localVarPath = `/api/orders/delete-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject5 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject5 !== undefined ? inlineObject5 : {})
                : (inlineObject5 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject9} inlineObject9 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerDeleteUserUnfinishedOrders: async (inlineObject9: InlineObject9, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject9' is not null or undefined
            if (inlineObject9 === null || inlineObject9 === undefined) {
                throw new RequiredError('inlineObject9','Required parameter inlineObject9 was null or undefined when calling ordersControllerDeleteUserUnfinishedOrders.');
            }
            const localVarPath = `/api/orders/delete-user-unfinished-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject9 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject9 !== undefined ? inlineObject9 : {})
                : (inlineObject9 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Search limit
         * @param {number} [offset] Search offset
         * @param {string} [searchText] Search text
         * @param {string} [sortBy] Sorting field
         * @param {boolean} [sortDesc] Descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerGetAllOrders: async (limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/all-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerGetOrder: async (orderId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling ordersControllerGetOrder.');
            }
            const localVarPath = `/api/orders/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerGetUnfinishedOrder: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/unfinished-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerGetUserOrder: async (orderId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling ordersControllerGetUserOrder.');
            }
            const localVarPath = `/api/orders/user-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerGetUserUnfinishedOrder: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling ordersControllerGetUserUnfinishedOrder.');
            }
            const localVarPath = `/api/orders/user-unfinished-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerMarkOrderAsPaid: async (inlineObject2: InlineObject2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject2' is not null or undefined
            if (inlineObject2 === null || inlineObject2 === undefined) {
                throw new RequiredError('inlineObject2','Required parameter inlineObject2 was null or undefined when calling ordersControllerMarkOrderAsPaid.');
            }
            const localVarPath = `/api/orders/mark-order-as-paid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject2 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject2 !== undefined ? inlineObject2 : {})
                : (inlineObject2 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerProcessRenewUpgradeOrders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/process-renew-upgrade-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject8} inlineObject8 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerProcessTestOrder: async (inlineObject8: InlineObject8, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject8' is not null or undefined
            if (inlineObject8 === null || inlineObject8 === undefined) {
                throw new RequiredError('inlineObject8','Required parameter inlineObject8 was null or undefined when calling ordersControllerProcessTestOrder.');
            }
            const localVarPath = `/api/orders/process-test-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject8 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject8 !== undefined ? inlineObject8 : {})
                : (inlineObject8 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerRemoveEmptyOrders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/orders/remove-empty-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject7} inlineObject7 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerRemovePaymentIntentDataFromOrder: async (inlineObject7: InlineObject7, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject7' is not null or undefined
            if (inlineObject7 === null || inlineObject7 === undefined) {
                throw new RequiredError('inlineObject7','Required parameter inlineObject7 was null or undefined when calling ordersControllerRemovePaymentIntentDataFromOrder.');
            }
            const localVarPath = `/api/orders/remove-payment-intent-data-from-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject7 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject7 !== undefined ? inlineObject7 : {})
                : (inlineObject7 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject3} inlineObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerUpdateOrderFulfilmentStatus: async (inlineObject3: InlineObject3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject3' is not null or undefined
            if (inlineObject3 === null || inlineObject3 === undefined) {
                throw new RequiredError('inlineObject3','Required parameter inlineObject3 was null or undefined when calling ordersControllerUpdateOrderFulfilmentStatus.');
            }
            const localVarPath = `/api/orders/update-order-fulfilment-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject3 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject3 !== undefined ? inlineObject3 : {})
                : (inlineObject3 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject4} inlineObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerVoidOrder: async (inlineObject4: InlineObject4, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject4' is not null or undefined
            if (inlineObject4 === null || inlineObject4 === undefined) {
                throw new RequiredError('inlineObject4','Required parameter inlineObject4 was null or undefined when calling ordersControllerVoidOrder.');
            }
            const localVarPath = `/api/orders/void-order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject4 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject4 !== undefined ? inlineObject4 : {})
                : (inlineObject4 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrderDto} createOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerAddOrder(createOrderDto: CreateOrderDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerAddOrder(createOrderDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject6} inlineObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerChangeOrderNotes(inlineObject6: InlineObject6, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerChangeOrderNotes(inlineObject6, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerClearDiscountsForUnpaidOrders(inlineObject1: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerClearDiscountsForUnpaidOrders(inlineObject1, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerConfirmMissedPaymentOrders(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerConfirmMissedPaymentOrders(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerCreateSession(inlineObject: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeSessionDto>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerCreateSession(inlineObject, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject5} inlineObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerDeleteOrder(inlineObject5: InlineObject5, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerDeleteOrder(inlineObject5, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject9} inlineObject9 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerDeleteUserUnfinishedOrders(inlineObject9: InlineObject9, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerDeleteUserUnfinishedOrders(inlineObject9, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [limit] Search limit
         * @param {number} [offset] Search offset
         * @param {string} [searchText] Search text
         * @param {string} [sortBy] Sorting field
         * @param {boolean} [sortDesc] Descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerGetAllOrders(limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderSearchResponseDto>>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerGetAllOrders(limit, offset, searchText, sortBy, sortDesc, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerGetOrder(orderId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerGetOrder(orderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerGetUnfinishedOrder(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerGetUnfinishedOrder(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerGetUserOrder(orderId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerGetUserOrder(orderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerGetUserUnfinishedOrder(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerGetUserUnfinishedOrder(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerMarkOrderAsPaid(inlineObject2: InlineObject2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerMarkOrderAsPaid(inlineObject2, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerProcessRenewUpgradeOrders(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerProcessRenewUpgradeOrders(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject8} inlineObject8 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerProcessTestOrder(inlineObject8: InlineObject8, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerProcessTestOrder(inlineObject8, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerRemoveEmptyOrders(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerRemoveEmptyOrders(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject7} inlineObject7 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerRemovePaymentIntentDataFromOrder(inlineObject7: InlineObject7, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerRemovePaymentIntentDataFromOrder(inlineObject7, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject3} inlineObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerUpdateOrderFulfilmentStatus(inlineObject3: InlineObject3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDto>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerUpdateOrderFulfilmentStatus(inlineObject3, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject4} inlineObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersControllerVoidOrder(inlineObject4: InlineObject4, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrdersApiAxiosParamCreator(configuration).ordersControllerVoidOrder(inlineObject4, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateOrderDto} createOrderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerAddOrder(createOrderDto: CreateOrderDto, options?: any): AxiosPromise<OrderDto> {
            return OrdersApiFp(configuration).ordersControllerAddOrder(createOrderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject6} inlineObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerChangeOrderNotes(inlineObject6: InlineObject6, options?: any): AxiosPromise<OrderDto> {
            return OrdersApiFp(configuration).ordersControllerChangeOrderNotes(inlineObject6, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerClearDiscountsForUnpaidOrders(inlineObject1: InlineObject1, options?: any): AxiosPromise<void> {
            return OrdersApiFp(configuration).ordersControllerClearDiscountsForUnpaidOrders(inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerConfirmMissedPaymentOrders(options?: any): AxiosPromise<void> {
            return OrdersApiFp(configuration).ordersControllerConfirmMissedPaymentOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerCreateSession(inlineObject: InlineObject, options?: any): AxiosPromise<StripeSessionDto> {
            return OrdersApiFp(configuration).ordersControllerCreateSession(inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject5} inlineObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerDeleteOrder(inlineObject5: InlineObject5, options?: any): AxiosPromise<void> {
            return OrdersApiFp(configuration).ordersControllerDeleteOrder(inlineObject5, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject9} inlineObject9 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerDeleteUserUnfinishedOrders(inlineObject9: InlineObject9, options?: any): AxiosPromise<void> {
            return OrdersApiFp(configuration).ordersControllerDeleteUserUnfinishedOrders(inlineObject9, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Search limit
         * @param {number} [offset] Search offset
         * @param {string} [searchText] Search text
         * @param {string} [sortBy] Sorting field
         * @param {boolean} [sortDesc] Descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerGetAllOrders(limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options?: any): AxiosPromise<Array<OrderSearchResponseDto>> {
            return OrdersApiFp(configuration).ordersControllerGetAllOrders(limit, offset, searchText, sortBy, sortDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerGetOrder(orderId: string, options?: any): AxiosPromise<OrderDto> {
            return OrdersApiFp(configuration).ordersControllerGetOrder(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerGetUnfinishedOrder(options?: any): AxiosPromise<OrderDto> {
            return OrdersApiFp(configuration).ordersControllerGetUnfinishedOrder(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerGetUserOrder(orderId: string, options?: any): AxiosPromise<OrderDto> {
            return OrdersApiFp(configuration).ordersControllerGetUserOrder(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerGetUserUnfinishedOrder(userId: string, options?: any): AxiosPromise<OrderDto> {
            return OrdersApiFp(configuration).ordersControllerGetUserUnfinishedOrder(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject2} inlineObject2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerMarkOrderAsPaid(inlineObject2: InlineObject2, options?: any): AxiosPromise<OrderDto> {
            return OrdersApiFp(configuration).ordersControllerMarkOrderAsPaid(inlineObject2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerProcessRenewUpgradeOrders(options?: any): AxiosPromise<void> {
            return OrdersApiFp(configuration).ordersControllerProcessRenewUpgradeOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject8} inlineObject8 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerProcessTestOrder(inlineObject8: InlineObject8, options?: any): AxiosPromise<string> {
            return OrdersApiFp(configuration).ordersControllerProcessTestOrder(inlineObject8, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerRemoveEmptyOrders(options?: any): AxiosPromise<void> {
            return OrdersApiFp(configuration).ordersControllerRemoveEmptyOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject7} inlineObject7 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerRemovePaymentIntentDataFromOrder(inlineObject7: InlineObject7, options?: any): AxiosPromise<OrderDto> {
            return OrdersApiFp(configuration).ordersControllerRemovePaymentIntentDataFromOrder(inlineObject7, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject3} inlineObject3 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerUpdateOrderFulfilmentStatus(inlineObject3: InlineObject3, options?: any): AxiosPromise<OrderDto> {
            return OrdersApiFp(configuration).ordersControllerUpdateOrderFulfilmentStatus(inlineObject3, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject4} inlineObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersControllerVoidOrder(inlineObject4: InlineObject4, options?: any): AxiosPromise<void> {
            return OrdersApiFp(configuration).ordersControllerVoidOrder(inlineObject4, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * 
     * @param {CreateOrderDto} createOrderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerAddOrder(createOrderDto: CreateOrderDto, options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerAddOrder(createOrderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject6} inlineObject6 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerChangeOrderNotes(inlineObject6: InlineObject6, options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerChangeOrderNotes(inlineObject6, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject1} inlineObject1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerClearDiscountsForUnpaidOrders(inlineObject1: InlineObject1, options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerClearDiscountsForUnpaidOrders(inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerConfirmMissedPaymentOrders(options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerConfirmMissedPaymentOrders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject} inlineObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerCreateSession(inlineObject: InlineObject, options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerCreateSession(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject5} inlineObject5 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerDeleteOrder(inlineObject5: InlineObject5, options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerDeleteOrder(inlineObject5, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject9} inlineObject9 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerDeleteUserUnfinishedOrders(inlineObject9: InlineObject9, options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerDeleteUserUnfinishedOrders(inlineObject9, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Search limit
     * @param {number} [offset] Search offset
     * @param {string} [searchText] Search text
     * @param {string} [sortBy] Sorting field
     * @param {boolean} [sortDesc] Descending sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerGetAllOrders(limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerGetAllOrders(limit, offset, searchText, sortBy, sortDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerGetOrder(orderId: string, options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerGetOrder(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerGetUnfinishedOrder(options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerGetUnfinishedOrder(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerGetUserOrder(orderId: string, options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerGetUserOrder(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerGetUserUnfinishedOrder(userId: string, options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerGetUserUnfinishedOrder(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject2} inlineObject2 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerMarkOrderAsPaid(inlineObject2: InlineObject2, options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerMarkOrderAsPaid(inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerProcessRenewUpgradeOrders(options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerProcessRenewUpgradeOrders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject8} inlineObject8 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerProcessTestOrder(inlineObject8: InlineObject8, options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerProcessTestOrder(inlineObject8, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerRemoveEmptyOrders(options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerRemoveEmptyOrders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject7} inlineObject7 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerRemovePaymentIntentDataFromOrder(inlineObject7: InlineObject7, options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerRemovePaymentIntentDataFromOrder(inlineObject7, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject3} inlineObject3 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerUpdateOrderFulfilmentStatus(inlineObject3: InlineObject3, options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerUpdateOrderFulfilmentStatus(inlineObject3, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject4} inlineObject4 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public ordersControllerVoidOrder(inlineObject4: InlineObject4, options?: any) {
        return OrdersApiFp(this.configuration).ordersControllerVoidOrder(inlineObject4, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationsApi - axios parameter creator
 * @export
 */
export const OrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAccountDto} createAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateAccount: async (createAccountDto: CreateAccountDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAccountDto' is not null or undefined
            if (createAccountDto === null || createAccountDto === undefined) {
                throw new RequiredError('createAccountDto','Required parameter createAccountDto was null or undefined when calling organizationsControllerCreateAccount.');
            }
            const localVarPath = `/api/organizations/create-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createAccountDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createAccountDto !== undefined ? createAccountDto : {})
                : (createAccountDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject22} inlineObject22 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateOrganization: async (inlineObject22: InlineObject22, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject22' is not null or undefined
            if (inlineObject22 === null || inlineObject22 === undefined) {
                throw new RequiredError('inlineObject22','Required parameter inlineObject22 was null or undefined when calling organizationsControllerCreateOrganization.');
            }
            const localVarPath = `/api/organizations/create-organization-for-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject22 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject22 !== undefined ? inlineObject22 : {})
                : (inlineObject22 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject21} inlineObject21 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteOrganization: async (inlineObject21: InlineObject21, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject21' is not null or undefined
            if (inlineObject21 === null || inlineObject21 === undefined) {
                throw new RequiredError('inlineObject21','Required parameter inlineObject21 was null or undefined when calling organizationsControllerDeleteOrganization.');
            }
            const localVarPath = `/api/organizations/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject21 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject21 !== undefined ? inlineObject21 : {})
                : (inlineObject21 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Search limit
         * @param {number} [offset] Search offset
         * @param {string} [searchText] Search text
         * @param {string} [sortBy] Sorting field
         * @param {boolean} [sortDesc] Descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetAllOrganizations: async (limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations/all-organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetOrganizationWithUserRoles: async (organizationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling organizationsControllerGetOrganizationWithUserRoles.');
            }
            const localVarPath = `/api/organizations/organization-with-user-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetUserOrganizations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations/user-organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetUserRoleTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations/user-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateOrganizationDto} updateOrganizationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateOrganization: async (updateOrganizationDto: UpdateOrganizationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateOrganizationDto' is not null or undefined
            if (updateOrganizationDto === null || updateOrganizationDto === undefined) {
                throw new RequiredError('updateOrganizationDto','Required parameter updateOrganizationDto was null or undefined when calling organizationsControllerUpdateOrganization.');
            }
            const localVarPath = `/api/organizations/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateOrganizationDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateOrganizationDto !== undefined ? updateOrganizationDto : {})
                : (updateOrganizationDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAccountDto} createAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerCreateAccount(createAccountDto: CreateAccountDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationsApiAxiosParamCreator(configuration).organizationsControllerCreateAccount(createAccountDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject22} inlineObject22 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerCreateOrganization(inlineObject22: InlineObject22, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationsApiAxiosParamCreator(configuration).organizationsControllerCreateOrganization(inlineObject22, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject21} inlineObject21 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerDeleteOrganization(inlineObject21: InlineObject21, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await OrganizationsApiAxiosParamCreator(configuration).organizationsControllerDeleteOrganization(inlineObject21, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [limit] Search limit
         * @param {number} [offset] Search offset
         * @param {string} [searchText] Search text
         * @param {string} [sortBy] Sorting field
         * @param {boolean} [sortDesc] Descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetAllOrganizations(limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationDto>>> {
            const localVarAxiosArgs = await OrganizationsApiAxiosParamCreator(configuration).organizationsControllerGetAllOrganizations(limit, offset, searchText, sortBy, sortDesc, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetOrganizationWithUserRoles(organizationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationExtendedDto>> {
            const localVarAxiosArgs = await OrganizationsApiAxiosParamCreator(configuration).organizationsControllerGetOrganizationWithUserRoles(organizationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetUserOrganizations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationExtendedDto>>> {
            const localVarAxiosArgs = await OrganizationsApiAxiosParamCreator(configuration).organizationsControllerGetUserOrganizations(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerGetUserRoleTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRoleDto>>> {
            const localVarAxiosArgs = await OrganizationsApiAxiosParamCreator(configuration).organizationsControllerGetUserRoleTypes(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateOrganizationDto} updateOrganizationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsControllerUpdateOrganization(updateOrganizationDto: UpdateOrganizationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationDto>> {
            const localVarAxiosArgs = await OrganizationsApiAxiosParamCreator(configuration).organizationsControllerUpdateOrganization(updateOrganizationDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateAccountDto} createAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateAccount(createAccountDto: CreateAccountDto, options?: any): AxiosPromise<void> {
            return OrganizationsApiFp(configuration).organizationsControllerCreateAccount(createAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject22} inlineObject22 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerCreateOrganization(inlineObject22: InlineObject22, options?: any): AxiosPromise<void> {
            return OrganizationsApiFp(configuration).organizationsControllerCreateOrganization(inlineObject22, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject21} inlineObject21 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerDeleteOrganization(inlineObject21: InlineObject21, options?: any): AxiosPromise<void> {
            return OrganizationsApiFp(configuration).organizationsControllerDeleteOrganization(inlineObject21, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Search limit
         * @param {number} [offset] Search offset
         * @param {string} [searchText] Search text
         * @param {string} [sortBy] Sorting field
         * @param {boolean} [sortDesc] Descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetAllOrganizations(limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options?: any): AxiosPromise<Array<OrganizationDto>> {
            return OrganizationsApiFp(configuration).organizationsControllerGetAllOrganizations(limit, offset, searchText, sortBy, sortDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetOrganizationWithUserRoles(organizationId: number, options?: any): AxiosPromise<OrganizationExtendedDto> {
            return OrganizationsApiFp(configuration).organizationsControllerGetOrganizationWithUserRoles(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetUserOrganizations(options?: any): AxiosPromise<Array<OrganizationExtendedDto>> {
            return OrganizationsApiFp(configuration).organizationsControllerGetUserOrganizations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerGetUserRoleTypes(options?: any): AxiosPromise<Array<UserRoleDto>> {
            return OrganizationsApiFp(configuration).organizationsControllerGetUserRoleTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateOrganizationDto} updateOrganizationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsControllerUpdateOrganization(updateOrganizationDto: UpdateOrganizationDto, options?: any): AxiosPromise<OrganizationDto> {
            return OrganizationsApiFp(configuration).organizationsControllerUpdateOrganization(updateOrganizationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI {
    /**
     * 
     * @param {CreateAccountDto} createAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsControllerCreateAccount(createAccountDto: CreateAccountDto, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsControllerCreateAccount(createAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject22} inlineObject22 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsControllerCreateOrganization(inlineObject22: InlineObject22, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsControllerCreateOrganization(inlineObject22, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject21} inlineObject21 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsControllerDeleteOrganization(inlineObject21: InlineObject21, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsControllerDeleteOrganization(inlineObject21, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Search limit
     * @param {number} [offset] Search offset
     * @param {string} [searchText] Search text
     * @param {string} [sortBy] Sorting field
     * @param {boolean} [sortDesc] Descending sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsControllerGetAllOrganizations(limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsControllerGetAllOrganizations(limit, offset, searchText, sortBy, sortDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsControllerGetOrganizationWithUserRoles(organizationId: number, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsControllerGetOrganizationWithUserRoles(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsControllerGetUserOrganizations(options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsControllerGetUserOrganizations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsControllerGetUserRoleTypes(options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsControllerGetUserRoleTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateOrganizationDto} updateOrganizationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public organizationsControllerUpdateOrganization(updateOrganizationDto: UpdateOrganizationDto, options?: any) {
        return OrganizationsApiFp(this.configuration).organizationsControllerUpdateOrganization(updateOrganizationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsControllerCumulativeProducts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsControllerFindOne: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling productsControllerFindOne.');
            }
            const localVarPath = `/api/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} itemCount 
         * @param {number} duration 
         * @param {number} purchaseOption 
         * @param {string} expiryDate 
         * @param {number} steppingRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsControllerGetNewExpireDate: async (itemCount: number, duration: number, purchaseOption: number, expiryDate: string, steppingRef: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemCount' is not null or undefined
            if (itemCount === null || itemCount === undefined) {
                throw new RequiredError('itemCount','Required parameter itemCount was null or undefined when calling productsControllerGetNewExpireDate.');
            }
            // verify required parameter 'duration' is not null or undefined
            if (duration === null || duration === undefined) {
                throw new RequiredError('duration','Required parameter duration was null or undefined when calling productsControllerGetNewExpireDate.');
            }
            // verify required parameter 'purchaseOption' is not null or undefined
            if (purchaseOption === null || purchaseOption === undefined) {
                throw new RequiredError('purchaseOption','Required parameter purchaseOption was null or undefined when calling productsControllerGetNewExpireDate.');
            }
            // verify required parameter 'expiryDate' is not null or undefined
            if (expiryDate === null || expiryDate === undefined) {
                throw new RequiredError('expiryDate','Required parameter expiryDate was null or undefined when calling productsControllerGetNewExpireDate.');
            }
            // verify required parameter 'steppingRef' is not null or undefined
            if (steppingRef === null || steppingRef === undefined) {
                throw new RequiredError('steppingRef','Required parameter steppingRef was null or undefined when calling productsControllerGetNewExpireDate.');
            }
            const localVarPath = `/api/products/new-expire-date`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (itemCount !== undefined) {
                localVarQueryParameter['itemCount'] = itemCount;
            }

            if (duration !== undefined) {
                localVarQueryParameter['duration'] = duration;
            }

            if (purchaseOption !== undefined) {
                localVarQueryParameter['purchaseOption'] = purchaseOption;
            }

            if (expiryDate !== undefined) {
                localVarQueryParameter['expiryDate'] = expiryDate;
            }

            if (steppingRef !== undefined) {
                localVarQueryParameter['steppingRef'] = steppingRef;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsControllerGetRenewUpgradeProducts: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling productsControllerGetRenewUpgradeProducts.');
            }
            const localVarPath = `/api/products/renew-upgrade-products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsControllerCumulativeProducts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductWideDto>> {
            const localVarAxiosArgs = await ProductsApiAxiosParamCreator(configuration).productsControllerCumulativeProducts(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsControllerFindOne(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDto>> {
            const localVarAxiosArgs = await ProductsApiAxiosParamCreator(configuration).productsControllerFindOne(productId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} itemCount 
         * @param {number} duration 
         * @param {number} purchaseOption 
         * @param {string} expiryDate 
         * @param {number} steppingRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsControllerGetNewExpireDate(itemCount: number, duration: number, purchaseOption: number, expiryDate: string, steppingRef: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductsApiAxiosParamCreator(configuration).productsControllerGetNewExpireDate(itemCount, duration, purchaseOption, expiryDate, steppingRef, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsControllerGetRenewUpgradeProducts(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RenewUpgradePossibilityDto>> {
            const localVarAxiosArgs = await ProductsApiAxiosParamCreator(configuration).productsControllerGetRenewUpgradeProducts(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsControllerCumulativeProducts(options?: any): AxiosPromise<ProductWideDto> {
            return ProductsApiFp(configuration).productsControllerCumulativeProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsControllerFindOne(productId: number, options?: any): AxiosPromise<ProductDto> {
            return ProductsApiFp(configuration).productsControllerFindOne(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} itemCount 
         * @param {number} duration 
         * @param {number} purchaseOption 
         * @param {string} expiryDate 
         * @param {number} steppingRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsControllerGetNewExpireDate(itemCount: number, duration: number, purchaseOption: number, expiryDate: string, steppingRef: number, options?: any): AxiosPromise<void> {
            return ProductsApiFp(configuration).productsControllerGetNewExpireDate(itemCount, duration, purchaseOption, expiryDate, steppingRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsControllerGetRenewUpgradeProducts(id: number, options?: any): AxiosPromise<RenewUpgradePossibilityDto> {
            return ProductsApiFp(configuration).productsControllerGetRenewUpgradeProducts(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsControllerCumulativeProducts(options?: any) {
        return ProductsApiFp(this.configuration).productsControllerCumulativeProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsControllerFindOne(productId: number, options?: any) {
        return ProductsApiFp(this.configuration).productsControllerFindOne(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} itemCount 
     * @param {number} duration 
     * @param {number} purchaseOption 
     * @param {string} expiryDate 
     * @param {number} steppingRef 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsControllerGetNewExpireDate(itemCount: number, duration: number, purchaseOption: number, expiryDate: string, steppingRef: number, options?: any) {
        return ProductsApiFp(this.configuration).productsControllerGetNewExpireDate(itemCount, duration, purchaseOption, expiryDate, steppingRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsControllerGetRenewUpgradeProducts(id: number, options?: any) {
        return ProductsApiFp(this.configuration).productsControllerGetRenewUpgradeProducts(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReleasesApi - axios parameter creator
 * @export
 */
export const ReleasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateReleaseDto} createReleaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releaseControllerCreate: async (createReleaseDto: CreateReleaseDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createReleaseDto' is not null or undefined
            if (createReleaseDto === null || createReleaseDto === undefined) {
                throw new RequiredError('createReleaseDto','Required parameter createReleaseDto was null or undefined when calling releaseControllerCreate.');
            }
            const localVarPath = `/api/releases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createReleaseDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createReleaseDto !== undefined ? createReleaseDto : {})
                : (createReleaseDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releaseControllerFindAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/releases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releaseControllerRemove: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling releaseControllerRemove.');
            }
            const localVarPath = `/api/releases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateReleaseDto} updateReleaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releaseControllerUpdate: async (id: number, updateReleaseDto: UpdateReleaseDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling releaseControllerUpdate.');
            }
            // verify required parameter 'updateReleaseDto' is not null or undefined
            if (updateReleaseDto === null || updateReleaseDto === undefined) {
                throw new RequiredError('updateReleaseDto','Required parameter updateReleaseDto was null or undefined when calling releaseControllerUpdate.');
            }
            const localVarPath = `/api/releases/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateReleaseDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateReleaseDto !== undefined ? updateReleaseDto : {})
                : (updateReleaseDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReleasesApi - functional programming interface
 * @export
 */
export const ReleasesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateReleaseDto} createReleaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async releaseControllerCreate(createReleaseDto: CreateReleaseDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleaseDto>> {
            const localVarAxiosArgs = await ReleasesApiAxiosParamCreator(configuration).releaseControllerCreate(createReleaseDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async releaseControllerFindAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReleaseDto>>> {
            const localVarAxiosArgs = await ReleasesApiAxiosParamCreator(configuration).releaseControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async releaseControllerRemove(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await ReleasesApiAxiosParamCreator(configuration).releaseControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateReleaseDto} updateReleaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async releaseControllerUpdate(id: number, updateReleaseDto: UpdateReleaseDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReleaseDto>> {
            const localVarAxiosArgs = await ReleasesApiAxiosParamCreator(configuration).releaseControllerUpdate(id, updateReleaseDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ReleasesApi - factory interface
 * @export
 */
export const ReleasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateReleaseDto} createReleaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releaseControllerCreate(createReleaseDto: CreateReleaseDto, options?: any): AxiosPromise<ReleaseDto> {
            return ReleasesApiFp(configuration).releaseControllerCreate(createReleaseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releaseControllerFindAll(options?: any): AxiosPromise<Array<ReleaseDto>> {
            return ReleasesApiFp(configuration).releaseControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releaseControllerRemove(id: number, options?: any): AxiosPromise<number> {
            return ReleasesApiFp(configuration).releaseControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateReleaseDto} updateReleaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        releaseControllerUpdate(id: number, updateReleaseDto: UpdateReleaseDto, options?: any): AxiosPromise<ReleaseDto> {
            return ReleasesApiFp(configuration).releaseControllerUpdate(id, updateReleaseDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReleasesApi - object-oriented interface
 * @export
 * @class ReleasesApi
 * @extends {BaseAPI}
 */
export class ReleasesApi extends BaseAPI {
    /**
     * 
     * @param {CreateReleaseDto} createReleaseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public releaseControllerCreate(createReleaseDto: CreateReleaseDto, options?: any) {
        return ReleasesApiFp(this.configuration).releaseControllerCreate(createReleaseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public releaseControllerFindAll(options?: any) {
        return ReleasesApiFp(this.configuration).releaseControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public releaseControllerRemove(id: number, options?: any) {
        return ReleasesApiFp(this.configuration).releaseControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateReleaseDto} updateReleaseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReleasesApi
     */
    public releaseControllerUpdate(id: number, updateReleaseDto: UpdateReleaseDto, options?: any) {
        return ReleasesApiFp(this.configuration).releaseControllerUpdate(id, updateReleaseDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SteppingsApi - axios parameter creator
 * @export
 */
export const SteppingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        steppingControllerFindAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/steppings/find-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SteppingsApi - functional programming interface
 * @export
 */
export const SteppingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async steppingControllerFindAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SteppingDto>>> {
            const localVarAxiosArgs = await SteppingsApiAxiosParamCreator(configuration).steppingControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SteppingsApi - factory interface
 * @export
 */
export const SteppingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        steppingControllerFindAll(options?: any): AxiosPromise<Array<SteppingDto>> {
            return SteppingsApiFp(configuration).steppingControllerFindAll(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SteppingsApi - object-oriented interface
 * @export
 * @class SteppingsApi
 * @extends {BaseAPI}
 */
export class SteppingsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SteppingsApi
     */
    public steppingControllerFindAll(options?: any) {
        return SteppingsApiFp(this.configuration).steppingControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StripeApi - axios parameter creator
 * @export
 */
export const StripeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCheckStripeInvoicesStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stripe/check-stripe-invoices-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerGetStripeInvoiceForOrder: async (orderId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling stripeControllerGetStripeInvoiceForOrder.');
            }
            const localVarPath = `/api/stripe/get-stripe-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject24} inlineObject24 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerSendStripeInvoiceForOrder: async (inlineObject24: InlineObject24, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject24' is not null or undefined
            if (inlineObject24 === null || inlineObject24 === undefined) {
                throw new RequiredError('inlineObject24','Required parameter inlineObject24 was null or undefined when calling stripeControllerSendStripeInvoiceForOrder.');
            }
            const localVarPath = `/api/stripe/send-stripe-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject24 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject24 !== undefined ? inlineObject24 : {})
                : (inlineObject24 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject25} inlineObject25 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerVoidStripeInvoiceForOrder: async (inlineObject25: InlineObject25, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject25' is not null or undefined
            if (inlineObject25 === null || inlineObject25 === undefined) {
                throw new RequiredError('inlineObject25','Required parameter inlineObject25 was null or undefined when calling stripeControllerVoidStripeInvoiceForOrder.');
            }
            const localVarPath = `/api/stripe/void-stripe-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject25 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject25 !== undefined ? inlineObject25 : {})
                : (inlineObject25 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StripeApi - functional programming interface
 * @export
 */
export const StripeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerCheckStripeInvoicesStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StripeApiAxiosParamCreator(configuration).stripeControllerCheckStripeInvoicesStatus(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerGetStripeInvoiceForOrder(orderId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeInvoiceDto>> {
            const localVarAxiosArgs = await StripeApiAxiosParamCreator(configuration).stripeControllerGetStripeInvoiceForOrder(orderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject24} inlineObject24 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerSendStripeInvoiceForOrder(inlineObject24: InlineObject24, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StripeApiAxiosParamCreator(configuration).stripeControllerSendStripeInvoiceForOrder(inlineObject24, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject25} inlineObject25 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerVoidStripeInvoiceForOrder(inlineObject25: InlineObject25, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StripeApiAxiosParamCreator(configuration).stripeControllerVoidStripeInvoiceForOrder(inlineObject25, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StripeApi - factory interface
 * @export
 */
export const StripeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCheckStripeInvoicesStatus(options?: any): AxiosPromise<void> {
            return StripeApiFp(configuration).stripeControllerCheckStripeInvoicesStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerGetStripeInvoiceForOrder(orderId: number, options?: any): AxiosPromise<StripeInvoiceDto> {
            return StripeApiFp(configuration).stripeControllerGetStripeInvoiceForOrder(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject24} inlineObject24 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerSendStripeInvoiceForOrder(inlineObject24: InlineObject24, options?: any): AxiosPromise<void> {
            return StripeApiFp(configuration).stripeControllerSendStripeInvoiceForOrder(inlineObject24, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject25} inlineObject25 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerVoidStripeInvoiceForOrder(inlineObject25: InlineObject25, options?: any): AxiosPromise<void> {
            return StripeApiFp(configuration).stripeControllerVoidStripeInvoiceForOrder(inlineObject25, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StripeApi - object-oriented interface
 * @export
 * @class StripeApi
 * @extends {BaseAPI}
 */
export class StripeApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerCheckStripeInvoicesStatus(options?: any) {
        return StripeApiFp(this.configuration).stripeControllerCheckStripeInvoicesStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerGetStripeInvoiceForOrder(orderId: number, options?: any) {
        return StripeApiFp(this.configuration).stripeControllerGetStripeInvoiceForOrder(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject24} inlineObject24 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerSendStripeInvoiceForOrder(inlineObject24: InlineObject24, options?: any) {
        return StripeApiFp(this.configuration).stripeControllerSendStripeInvoiceForOrder(inlineObject24, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject25} inlineObject25 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerVoidStripeInvoiceForOrder(inlineObject25: InlineObject25, options?: any) {
        return StripeApiFp(this.configuration).stripeControllerVoidStripeInvoiceForOrder(inlineObject25, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UploadsApi - axios parameter creator
 * @export
 */
export const UploadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUploadDto} createUploadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerCreate: async (createUploadDto: CreateUploadDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUploadDto' is not null or undefined
            if (createUploadDto === null || createUploadDto === undefined) {
                throw new RequiredError('createUploadDto','Required parameter createUploadDto was null or undefined when calling uploadsControllerCreate.');
            }
            const localVarPath = `/api/uploads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createUploadDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createUploadDto !== undefined ? createUploadDto : {})
                : (createUploadDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerFindAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/uploads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerRemove: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling uploadsControllerRemove.');
            }
            const localVarPath = `/api/uploads/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateUploadDto} updateUploadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerUpdate: async (id: number, updateUploadDto: UpdateUploadDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling uploadsControllerUpdate.');
            }
            // verify required parameter 'updateUploadDto' is not null or undefined
            if (updateUploadDto === null || updateUploadDto === undefined) {
                throw new RequiredError('updateUploadDto','Required parameter updateUploadDto was null or undefined when calling uploadsControllerUpdate.');
            }
            const localVarPath = `/api/uploads/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateUploadDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateUploadDto !== undefined ? updateUploadDto : {})
                : (updateUploadDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadsApi - functional programming interface
 * @export
 */
export const UploadsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUploadDto} createUploadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerCreate(createUploadDto: CreateUploadDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUploadDto>> {
            const localVarAxiosArgs = await UploadsApiAxiosParamCreator(configuration).uploadsControllerCreate(createUploadDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerFindAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UploadDto>>> {
            const localVarAxiosArgs = await UploadsApiAxiosParamCreator(configuration).uploadsControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerRemove(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UploadsApiAxiosParamCreator(configuration).uploadsControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateUploadDto} updateUploadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadsControllerUpdate(id: number, updateUploadDto: UpdateUploadDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUploadDto>> {
            const localVarAxiosArgs = await UploadsApiAxiosParamCreator(configuration).uploadsControllerUpdate(id, updateUploadDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UploadsApi - factory interface
 * @export
 */
export const UploadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateUploadDto} createUploadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerCreate(createUploadDto: CreateUploadDto, options?: any): AxiosPromise<CreateUploadDto> {
            return UploadsApiFp(configuration).uploadsControllerCreate(createUploadDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerFindAll(options?: any): AxiosPromise<Array<UploadDto>> {
            return UploadsApiFp(configuration).uploadsControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerRemove(id: number, options?: any): AxiosPromise<void> {
            return UploadsApiFp(configuration).uploadsControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateUploadDto} updateUploadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadsControllerUpdate(id: number, updateUploadDto: UpdateUploadDto, options?: any): AxiosPromise<UpdateUploadDto> {
            return UploadsApiFp(configuration).uploadsControllerUpdate(id, updateUploadDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadsApi - object-oriented interface
 * @export
 * @class UploadsApi
 * @extends {BaseAPI}
 */
export class UploadsApi extends BaseAPI {
    /**
     * 
     * @param {CreateUploadDto} createUploadDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public uploadsControllerCreate(createUploadDto: CreateUploadDto, options?: any) {
        return UploadsApiFp(this.configuration).uploadsControllerCreate(createUploadDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public uploadsControllerFindAll(options?: any) {
        return UploadsApiFp(this.configuration).uploadsControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public uploadsControllerRemove(id: number, options?: any) {
        return UploadsApiFp(this.configuration).uploadsControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateUploadDto} updateUploadDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadsApi
     */
    public uploadsControllerUpdate(id: number, updateUploadDto: UpdateUploadDto, options?: any) {
        return UploadsApiFp(this.configuration).uploadsControllerUpdate(id, updateUploadDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InlineObject14} inlineObject14 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCancelUserInvitation: async (inlineObject14: InlineObject14, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject14' is not null or undefined
            if (inlineObject14 === null || inlineObject14 === undefined) {
                throw new RequiredError('inlineObject14','Required parameter inlineObject14 was null or undefined when calling usersControllerCancelUserInvitation.');
            }
            const localVarPath = `/api/users/cancel-user-invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject14 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject14 !== undefined ? inlineObject14 : {})
                : (inlineObject14 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject20} inlineObject20 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerChangeUserPassword: async (inlineObject20: InlineObject20, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject20' is not null or undefined
            if (inlineObject20 === null || inlineObject20 === undefined) {
                throw new RequiredError('inlineObject20','Required parameter inlineObject20 was null or undefined when calling usersControllerChangeUserPassword.');
            }
            const localVarPath = `/api/users/change-user-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject20 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject20 !== undefined ? inlineObject20 : {})
                : (inlineObject20 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject16} inlineObject16 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerChangeUserRoles: async (inlineObject16: InlineObject16, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject16' is not null or undefined
            if (inlineObject16 === null || inlineObject16 === undefined) {
                throw new RequiredError('inlineObject16','Required parameter inlineObject16 was null or undefined when calling usersControllerChangeUserRoles.');
            }
            const localVarPath = `/api/users/change-user-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject16 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject16 !== undefined ? inlineObject16 : {})
                : (inlineObject16 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCheckPasswordResetToken: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling usersControllerCheckPasswordResetToken.');
            }
            const localVarPath = `/api/users/check-password-reset-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject17} inlineObject17 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeleteUser: async (inlineObject17: InlineObject17, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject17' is not null or undefined
            if (inlineObject17 === null || inlineObject17 === undefined) {
                throw new RequiredError('inlineObject17','Required parameter inlineObject17 was null or undefined when calling usersControllerDeleteUser.');
            }
            const localVarPath = `/api/users/delete-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject17 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject17 !== undefined ? inlineObject17 : {})
                : (inlineObject17 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Search limit
         * @param {number} [offset] Search offset
         * @param {string} [searchText] Search text
         * @param {string} [sortBy] Sorting field
         * @param {boolean} [sortDesc] Descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetAllUsers: async (limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/all-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetCanCreateOrganization: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/can-create-organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetEditingUser: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling usersControllerGetEditingUser.');
            }
            const localVarPath = `/api/users/editing-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject12} inlineObject12 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUserByInvitation: async (inlineObject12: InlineObject12, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject12' is not null or undefined
            if (inlineObject12 === null || inlineObject12 === undefined) {
                throw new RequiredError('inlineObject12','Required parameter inlineObject12 was null or undefined when calling usersControllerGetUserByInvitation.');
            }
            const localVarPath = `/api/users/get-user-by-invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject12 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject12 !== undefined ? inlineObject12 : {})
                : (inlineObject12 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject19} inlineObject19 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerInvalidateAllUserTokens: async (inlineObject19: InlineObject19, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject19' is not null or undefined
            if (inlineObject19 === null || inlineObject19 === undefined) {
                throw new RequiredError('inlineObject19','Required parameter inlineObject19 was null or undefined when calling usersControllerInvalidateAllUserTokens.');
            }
            const localVarPath = `/api/users/invalidate-all-user-tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject19 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject19 !== undefined ? inlineObject19 : {})
                : (inlineObject19 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MemberInviteDto} memberInviteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerInviteMember: async (memberInviteDto: MemberInviteDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberInviteDto' is not null or undefined
            if (memberInviteDto === null || memberInviteDto === undefined) {
                throw new RequiredError('memberInviteDto','Required parameter memberInviteDto was null or undefined when calling usersControllerInviteMember.');
            }
            const localVarPath = `/api/users/invite-member`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof memberInviteDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(memberInviteDto !== undefined ? memberInviteDto : {})
                : (memberInviteDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserLoginRequestDto} userLoginRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLogin: async (userLoginRequestDto: UserLoginRequestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userLoginRequestDto' is not null or undefined
            if (userLoginRequestDto === null || userLoginRequestDto === undefined) {
                throw new RequiredError('userLoginRequestDto','Required parameter userLoginRequestDto was null or undefined when calling usersControllerLogin.');
            }
            const localVarPath = `/api/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userLoginRequestDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userLoginRequestDto !== undefined ? userLoginRequestDto : {})
                : (userLoginRequestDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject10} inlineObject10 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLogout: async (inlineObject10: InlineObject10, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject10' is not null or undefined
            if (inlineObject10 === null || inlineObject10 === undefined) {
                throw new RequiredError('inlineObject10','Required parameter inlineObject10 was null or undefined when calling usersControllerLogout.');
            }
            const localVarPath = `/api/users/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject10 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject10 !== undefined ? inlineObject10 : {})
                : (inlineObject10 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject18} inlineObject18 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRefreshToken: async (inlineObject18: InlineObject18, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject18' is not null or undefined
            if (inlineObject18 === null || inlineObject18 === undefined) {
                throw new RequiredError('inlineObject18','Required parameter inlineObject18 was null or undefined when calling usersControllerRefreshToken.');
            }
            const localVarPath = `/api/users/refresh-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject18 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject18 !== undefined ? inlineObject18 : {})
                : (inlineObject18 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserCreateDto} userCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRegister: async (userCreateDto: UserCreateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateDto' is not null or undefined
            if (userCreateDto === null || userCreateDto === undefined) {
                throw new RequiredError('userCreateDto','Required parameter userCreateDto was null or undefined when calling usersControllerRegister.');
            }
            const localVarPath = `/api/users/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userCreateDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userCreateDto !== undefined ? userCreateDto : {})
                : (userCreateDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject13} inlineObject13 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRemoveUserFromOrganization: async (inlineObject13: InlineObject13, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject13' is not null or undefined
            if (inlineObject13 === null || inlineObject13 === undefined) {
                throw new RequiredError('inlineObject13','Required parameter inlineObject13 was null or undefined when calling usersControllerRemoveUserFromOrganization.');
            }
            const localVarPath = `/api/users/remove-user-from-organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject13 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject13 !== undefined ? inlineObject13 : {})
                : (inlineObject13 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MemberInviteDto} memberInviteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResendMemberInvitation: async (memberInviteDto: MemberInviteDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberInviteDto' is not null or undefined
            if (memberInviteDto === null || memberInviteDto === undefined) {
                throw new RequiredError('memberInviteDto','Required parameter memberInviteDto was null or undefined when calling usersControllerResendMemberInvitation.');
            }
            const localVarPath = `/api/users/resend-member-invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof memberInviteDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(memberInviteDto !== undefined ? memberInviteDto : {})
                : (memberInviteDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResetUserPassword: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling usersControllerResetUserPassword.');
            }
            const localVarPath = `/api/users/reset-user-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSendResetPasswordEmail: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling usersControllerSendResetPasswordEmail.');
            }
            const localVarPath = `/api/users/send-reset-password-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject11} inlineObject11 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSetNewPassword: async (inlineObject11: InlineObject11, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject11' is not null or undefined
            if (inlineObject11 === null || inlineObject11 === undefined) {
                throw new RequiredError('inlineObject11','Required parameter inlineObject11 was null or undefined when calling usersControllerSetNewPassword.');
            }
            const localVarPath = `/api/users/set-new-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject11 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject11 !== undefined ? inlineObject11 : {})
                : (inlineObject11 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerTransferOrganization: async (body: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling usersControllerTransferOrganization.');
            }
            const localVarPath = `/api/users/transfer-organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof body !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} organizationId 
         * @param {UserUpdateDto} userUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdate: async (id: number, organizationId: number, userUpdateDto: UserUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling usersControllerUpdate.');
            }
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling usersControllerUpdate.');
            }
            // verify required parameter 'userUpdateDto' is not null or undefined
            if (userUpdateDto === null || userUpdateDto === undefined) {
                throw new RequiredError('userUpdateDto','Required parameter userUpdateDto was null or undefined when calling usersControllerUpdate.');
            }
            const localVarPath = `/api/users/{id}/{organizationId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof userUpdateDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(userUpdateDto !== undefined ? userUpdateDto : {})
                : (userUpdateDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject15} inlineObject15 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUseUserInvitation: async (inlineObject15: InlineObject15, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject15' is not null or undefined
            if (inlineObject15 === null || inlineObject15 === undefined) {
                throw new RequiredError('inlineObject15','Required parameter inlineObject15 was null or undefined when calling usersControllerUseUserInvitation.');
            }
            const localVarPath = `/api/users/use-user-invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inlineObject15 !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inlineObject15 !== undefined ? inlineObject15 : {})
                : (inlineObject15 || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InlineObject14} inlineObject14 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerCancelUserInvitation(inlineObject14: InlineObject14, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerCancelUserInvitation(inlineObject14, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject20} inlineObject20 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerChangeUserPassword(inlineObject20: InlineObject20, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerChangeUserPassword(inlineObject20, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject16} inlineObject16 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerChangeUserRoles(inlineObject16: InlineObject16, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerChangeUserRoles(inlineObject16, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerCheckPasswordResetToken(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerCheckPasswordResetToken(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject17} inlineObject17 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerDeleteUser(inlineObject17: InlineObject17, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerDeleteUser(inlineObject17, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [limit] Search limit
         * @param {number} [offset] Search offset
         * @param {string} [searchText] Search text
         * @param {string} [sortBy] Sorting field
         * @param {boolean} [sortDesc] Descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetAllUsers(limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UsersItemDto>>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerGetAllUsers(limit, offset, searchText, sortBy, sortDesc, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetCanCreateOrganization(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerGetCanCreateOrganization(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetEditingUser(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditingUserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerGetEditingUser(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerGetUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject12} inlineObject12 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetUserByInvitation(inlineObject12: InlineObject12, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerGetUserByInvitation(inlineObject12, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject19} inlineObject19 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerInvalidateAllUserTokens(inlineObject19: InlineObject19, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerInvalidateAllUserTokens(inlineObject19, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {MemberInviteDto} memberInviteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerInviteMember(memberInviteDto: MemberInviteDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerInviteMember(memberInviteDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UserLoginRequestDto} userLoginRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerLogin(userLoginRequestDto: UserLoginRequestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginResponseDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerLogin(userLoginRequestDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject10} inlineObject10 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerLogout(inlineObject10: InlineObject10, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerLogout(inlineObject10, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject18} inlineObject18 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerRefreshToken(inlineObject18: InlineObject18, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginResponseDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerRefreshToken(inlineObject18, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UserCreateDto} userCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerRegister(userCreateDto: UserCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginResponseDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerRegister(userCreateDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject13} inlineObject13 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerRemoveUserFromOrganization(inlineObject13: InlineObject13, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerRemoveUserFromOrganization(inlineObject13, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {MemberInviteDto} memberInviteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerResendMemberInvitation(memberInviteDto: MemberInviteDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerResendMemberInvitation(memberInviteDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerResetUserPassword(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerResetUserPassword(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerSendResetPasswordEmail(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerSendResetPasswordEmail(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject11} inlineObject11 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerSetNewPassword(inlineObject11: InlineObject11, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginResponseDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerSetNewPassword(inlineObject11, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerTransferOrganization(body: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerTransferOrganization(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} organizationId 
         * @param {UserUpdateDto} userUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdate(id: number, organizationId: number, userUpdateDto: UserUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerUpdate(id, organizationId, userUpdateDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InlineObject15} inlineObject15 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUseUserInvitation(inlineObject15: InlineObject15, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginResponseDto>> {
            const localVarAxiosArgs = await UsersApiAxiosParamCreator(configuration).usersControllerUseUserInvitation(inlineObject15, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {InlineObject14} inlineObject14 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCancelUserInvitation(inlineObject14: InlineObject14, options?: any): AxiosPromise<UserDto> {
            return UsersApiFp(configuration).usersControllerCancelUserInvitation(inlineObject14, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject20} inlineObject20 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerChangeUserPassword(inlineObject20: InlineObject20, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).usersControllerChangeUserPassword(inlineObject20, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject16} inlineObject16 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerChangeUserRoles(inlineObject16: InlineObject16, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).usersControllerChangeUserRoles(inlineObject16, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCheckPasswordResetToken(token: string, options?: any): AxiosPromise<boolean> {
            return UsersApiFp(configuration).usersControllerCheckPasswordResetToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject17} inlineObject17 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeleteUser(inlineObject17: InlineObject17, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).usersControllerDeleteUser(inlineObject17, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Search limit
         * @param {number} [offset] Search offset
         * @param {string} [searchText] Search text
         * @param {string} [sortBy] Sorting field
         * @param {boolean} [sortDesc] Descending sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetAllUsers(limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options?: any): AxiosPromise<Array<UsersItemDto>> {
            return UsersApiFp(configuration).usersControllerGetAllUsers(limit, offset, searchText, sortBy, sortDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetCanCreateOrganization(options?: any): AxiosPromise<boolean> {
            return UsersApiFp(configuration).usersControllerGetCanCreateOrganization(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetEditingUser(userId: string, options?: any): AxiosPromise<EditingUserDto> {
            return UsersApiFp(configuration).usersControllerGetEditingUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUser(options?: any): AxiosPromise<UserDto> {
            return UsersApiFp(configuration).usersControllerGetUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject12} inlineObject12 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUserByInvitation(inlineObject12: InlineObject12, options?: any): AxiosPromise<UserDto> {
            return UsersApiFp(configuration).usersControllerGetUserByInvitation(inlineObject12, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject19} inlineObject19 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerInvalidateAllUserTokens(inlineObject19: InlineObject19, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).usersControllerInvalidateAllUserTokens(inlineObject19, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberInviteDto} memberInviteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerInviteMember(memberInviteDto: MemberInviteDto, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).usersControllerInviteMember(memberInviteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserLoginRequestDto} userLoginRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLogin(userLoginRequestDto: UserLoginRequestDto, options?: any): AxiosPromise<UserLoginResponseDto> {
            return UsersApiFp(configuration).usersControllerLogin(userLoginRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject10} inlineObject10 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerLogout(inlineObject10: InlineObject10, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).usersControllerLogout(inlineObject10, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject18} inlineObject18 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRefreshToken(inlineObject18: InlineObject18, options?: any): AxiosPromise<UserLoginResponseDto> {
            return UsersApiFp(configuration).usersControllerRefreshToken(inlineObject18, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserCreateDto} userCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRegister(userCreateDto: UserCreateDto, options?: any): AxiosPromise<UserLoginResponseDto> {
            return UsersApiFp(configuration).usersControllerRegister(userCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject13} inlineObject13 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRemoveUserFromOrganization(inlineObject13: InlineObject13, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).usersControllerRemoveUserFromOrganization(inlineObject13, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MemberInviteDto} memberInviteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResendMemberInvitation(memberInviteDto: MemberInviteDto, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).usersControllerResendMemberInvitation(memberInviteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResetUserPassword(email: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).usersControllerResetUserPassword(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSendResetPasswordEmail(email: string, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).usersControllerSendResetPasswordEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject11} inlineObject11 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSetNewPassword(inlineObject11: InlineObject11, options?: any): AxiosPromise<UserLoginResponseDto> {
            return UsersApiFp(configuration).usersControllerSetNewPassword(inlineObject11, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerTransferOrganization(body: object, options?: any): AxiosPromise<void> {
            return UsersApiFp(configuration).usersControllerTransferOrganization(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} organizationId 
         * @param {UserUpdateDto} userUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdate(id: number, organizationId: number, userUpdateDto: UserUpdateDto, options?: any): AxiosPromise<UserDto> {
            return UsersApiFp(configuration).usersControllerUpdate(id, organizationId, userUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject15} inlineObject15 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUseUserInvitation(inlineObject15: InlineObject15, options?: any): AxiosPromise<UserLoginResponseDto> {
            return UsersApiFp(configuration).usersControllerUseUserInvitation(inlineObject15, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {InlineObject14} inlineObject14 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerCancelUserInvitation(inlineObject14: InlineObject14, options?: any) {
        return UsersApiFp(this.configuration).usersControllerCancelUserInvitation(inlineObject14, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject20} inlineObject20 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerChangeUserPassword(inlineObject20: InlineObject20, options?: any) {
        return UsersApiFp(this.configuration).usersControllerChangeUserPassword(inlineObject20, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject16} inlineObject16 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerChangeUserRoles(inlineObject16: InlineObject16, options?: any) {
        return UsersApiFp(this.configuration).usersControllerChangeUserRoles(inlineObject16, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerCheckPasswordResetToken(token: string, options?: any) {
        return UsersApiFp(this.configuration).usersControllerCheckPasswordResetToken(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject17} inlineObject17 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerDeleteUser(inlineObject17: InlineObject17, options?: any) {
        return UsersApiFp(this.configuration).usersControllerDeleteUser(inlineObject17, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Search limit
     * @param {number} [offset] Search offset
     * @param {string} [searchText] Search text
     * @param {string} [sortBy] Sorting field
     * @param {boolean} [sortDesc] Descending sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetAllUsers(limit?: number, offset?: number, searchText?: string, sortBy?: string, sortDesc?: boolean, options?: any) {
        return UsersApiFp(this.configuration).usersControllerGetAllUsers(limit, offset, searchText, sortBy, sortDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetCanCreateOrganization(options?: any) {
        return UsersApiFp(this.configuration).usersControllerGetCanCreateOrganization(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetEditingUser(userId: string, options?: any) {
        return UsersApiFp(this.configuration).usersControllerGetEditingUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetUser(options?: any) {
        return UsersApiFp(this.configuration).usersControllerGetUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject12} inlineObject12 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetUserByInvitation(inlineObject12: InlineObject12, options?: any) {
        return UsersApiFp(this.configuration).usersControllerGetUserByInvitation(inlineObject12, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject19} inlineObject19 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerInvalidateAllUserTokens(inlineObject19: InlineObject19, options?: any) {
        return UsersApiFp(this.configuration).usersControllerInvalidateAllUserTokens(inlineObject19, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberInviteDto} memberInviteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerInviteMember(memberInviteDto: MemberInviteDto, options?: any) {
        return UsersApiFp(this.configuration).usersControllerInviteMember(memberInviteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserLoginRequestDto} userLoginRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerLogin(userLoginRequestDto: UserLoginRequestDto, options?: any) {
        return UsersApiFp(this.configuration).usersControllerLogin(userLoginRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject10} inlineObject10 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerLogout(inlineObject10: InlineObject10, options?: any) {
        return UsersApiFp(this.configuration).usersControllerLogout(inlineObject10, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject18} inlineObject18 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerRefreshToken(inlineObject18: InlineObject18, options?: any) {
        return UsersApiFp(this.configuration).usersControllerRefreshToken(inlineObject18, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserCreateDto} userCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerRegister(userCreateDto: UserCreateDto, options?: any) {
        return UsersApiFp(this.configuration).usersControllerRegister(userCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject13} inlineObject13 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerRemoveUserFromOrganization(inlineObject13: InlineObject13, options?: any) {
        return UsersApiFp(this.configuration).usersControllerRemoveUserFromOrganization(inlineObject13, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MemberInviteDto} memberInviteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerResendMemberInvitation(memberInviteDto: MemberInviteDto, options?: any) {
        return UsersApiFp(this.configuration).usersControllerResendMemberInvitation(memberInviteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerResetUserPassword(email: string, options?: any) {
        return UsersApiFp(this.configuration).usersControllerResetUserPassword(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerSendResetPasswordEmail(email: string, options?: any) {
        return UsersApiFp(this.configuration).usersControllerSendResetPasswordEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject11} inlineObject11 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerSetNewPassword(inlineObject11: InlineObject11, options?: any) {
        return UsersApiFp(this.configuration).usersControllerSetNewPassword(inlineObject11, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerTransferOrganization(body: object, options?: any) {
        return UsersApiFp(this.configuration).usersControllerTransferOrganization(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} organizationId 
     * @param {UserUpdateDto} userUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdate(id: number, organizationId: number, userUpdateDto: UserUpdateDto, options?: any) {
        return UsersApiFp(this.configuration).usersControllerUpdate(id, organizationId, userUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InlineObject15} inlineObject15 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUseUserInvitation(inlineObject15: InlineObject15, options?: any) {
        return UsersApiFp(this.configuration).usersControllerUseUserInvitation(inlineObject15, options).then((request) => request(this.axios, this.basePath));
    }
}


