import { MutationTree } from 'vuex';
import { initialState as getInitialState } from '@/store/initialState/initialState';

export const APP_MUTATIONS: MutationTree<Record<string, unknown>> = {
  RESET_STATE(state) {
    const initialState_ = getInitialState();
    Object.keys(initialState_).forEach((key: string) => {
      state[key] = initialState_[key];
    });
  },
};
