
import { Component, Prop, Vue } from 'vue-property-decorator';
import UploadPhotoDialog from '@/components/dialogs/UploadPhotoDialog.vue';
import { OrganizationDto } from '@/castapi';
import MoaAvatar from '@/components/shared/various/MoaAvatar.vue';
import { MenuItem } from '@/shared/types/common';

@Component({
  components: {
    UploadPhotoDialog,
    MoaAvatar,
  },
})
export default class OrganizationCard extends Vue {
  @Prop({ default: () => ({}) }) organization!: OrganizationDto;
  @Prop({ default: false }) editable!: boolean;

  private uploadPhotoDialogVisible = false;
  private avatarMenu: MenuItem[] = [
    {
      title: 'Upload photo',
      action: this.openUploadPhotoDialog,
      dataCy: 'list-item-upload-avatar',
      isDisabled: () => false,
    },
    {
      title: 'Remove photo',
      action: this.handleRemovePhoto,
      dataCy: 'list-item-remove-avatar',
      isDisabled: this.isRemoveAvatarDisable,
    },
  ];

  isRemoveAvatarDisable() {
    return !this.organization?.organizationAvatar;
  }

  openUploadPhotoDialog() {
    this.uploadPhotoDialogVisible = true;
  }

  get name(): string {
    return this.organization?.organizationName;
  }

  async uploadCallback(file: string): Promise<void> {
    await this.$store.dispatch('organizations/updateOrganizationData', {
      organization: this.organization,
      avatarImage: file,
    });
  }

  async handleRemovePhoto() {
    await this.$store.dispatch('organizations/updateOrganizationData', {
      organization: {
        ...this.organization,
        organizationAvatar: null,
      },
    });
  }
}
