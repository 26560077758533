import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        primary: '#1D4A89',
        success: '#3B511E', //white font
        warning: '#FECC7C', //white font
        error: '#940505', //white font
        info: '#584364', //white font
        disabled: '#DBDBDB', //black font
        normal: '#3B511E', //black font
      },
    },
  },
});
