import { Component, Vue } from 'vue-property-decorator';
import { AddressDto, CountryDto, ProductInfoDto } from '@/castapi';
import { addressLine, getAddress, isAddressValid } from '@/castapi/helpers';
import { format, isBefore, parse } from 'date-fns';
import { ISO_DATE_FORMAT, ISO_MS_DATE_FORMAT, MDY_DATE_FORMAT, MDY_FULL_FORMAT } from '@/shared/constants';
import { getExpiryOrSteppingDate } from '@/shared/functions';

@Component
class ProjectMixin extends Vue {
  // NOTE: Date in string in "yyyy-MM-dd" format, without time and timezone!
  public formatDateToMdy(date: string, srcFormat = ISO_DATE_FORMAT): string {
    if (!date) {
      throw Error('Date must not be null');
      // return 'Date unknown';
    }
    if (date.length !== 10) {
      throw Error(`Date must be in "${srcFormat}" format`);
    }
    return format(parse(date, srcFormat, new Date()), MDY_DATE_FORMAT);
  }

  // NOTE: Timestamp in string in "yyyy-MM-ddTHH:MM:ss.xxxZ" format, with time and timezone!
  public formatTimestampToMdy(date: string, full = false): string {
    if (date.length !== 24) {
      throw Error(`Date must be in "${ISO_MS_DATE_FORMAT}Z" format`);
    }
    return format(new Date(date), full ? MDY_DATE_FORMAT : MDY_FULL_FORMAT);
  }

  public isDateValid(date: string): boolean {
    if (!date) {
      return false;
    }
    const exp = new Date(this.formatDateToMdy(date));
    const today = new Date(format(new Date(), MDY_DATE_FORMAT));
    return !isBefore(new Date(exp), new Date(today));
  }

  public isProductObsolete(product: ProductInfoDto): boolean {
    return this.isDateValid(getExpiryOrSteppingDate(product));
  }

  public getProductObsoleteDateAndStatus(product: ProductInfoDto, short = false): string {
    const obsoleteDate = getExpiryOrSteppingDate(product);
    if (!obsoleteDate) {
      return 'Not burned';
    }
    return `${this.isDateValid(obsoleteDate) ? `Valid${short ? '' : ' until'}` : 'Expired on'} ${this.formatDateToMdy(
      obsoleteDate,
    )}`;
  }

  public getRange(start: number, end: number, step?: number): number[] {
    const arr: number[] = [];
    for (let i = start; i <= end; step ? (i = i + step) : i++) {
      arr.push(i);
    }
    return arr;
  }

  public addressLine(address: AddressDto | { countryRef; province }, countries: CountryDto[]) {
    return addressLine(address, countries);
  }

  public isAddressValid(address: AddressDto | null): boolean {
    return isAddressValid(address);
  }

  public getAddress(address: AddressDto | null): Partial<AddressDto> | null {
    return getAddress(address);
  }
}

export default ProjectMixin;
