
import { Component, Mixins } from 'vue-property-decorator';
import EventBus from '@/plugins/EventBus';
import ProjectMixin from '@/mixins/ProjectMixin';
import EmergencyCodeDialog from '@/components/dialogs/EmergencyCodeDialog.vue';
import RenewUpgradeDialog from '@/components/dialogs/RenewUpgradeDialog.vue';
import SubtotalCheckoutDialog from '@/components/dialogs/SubtotalCheckoutDialog.vue';
import { AddressDto, OrderItemDto, ProductDto, UserDto } from '@/castapi';
import EditOrderAddressDialog from '@/components/dialogs/EditOrderAddressDialog.vue';
import { mapGetters } from 'vuex';
import { getUserFullName } from '@/shared/functions';
import MoaAvatar from '@/components/shared/various/MoaAvatar.vue';
import { LEASE_STEPPING_ID } from '@/shared/constants';

@Component({
  components: {
    EmergencyCodeDialog,
    RenewUpgradeDialog,
    SubtotalCheckoutDialog,
    EditOrderAddressDialog,
    MoaAvatar,
  },
  computed: {
    ...mapGetters({
      order: 'shop/order',
      totalPrice: 'shop/orderProductsPrice',
      orderDiscountTotal: 'shop/orderDiscountTotal',
      isLoading: 'shop/isLoading',
      checkoutLoading: 'shop/checkoutLoading',
      error: 'shop/error',
      orderValid: 'shop/orderValid',
      currentDongleRenewOptions: 'shop/currentDongleRenewOptions',
      currentDongleUpgradeOptions: 'shop/currentDongleUpgradeOptions',
      currentDongleRenewPossibility: 'shop/currentDongleRenewPossibility',
      currentDongleUpgradePossibility: 'shop/currentDongleUpgradePossibility',
      currentDongleRenewalsRequired: 'shop/currentDongleRenewalsRequired',
      currentDongleMaxRenewalsAvailable: 'shop/currentDongleMaxRenewalsAvailable',
      currentDongleLicenseTooNew: 'shop/currentDongleLicenseTooNew',
      currentDongleLicenseTooOld: 'shop/currentDongleLicenseTooOld',
      currentDongleLicenseNeedsExpirationBeforeUpgrade: 'shop/currentDongleLicenseNeedsExpirationBeforeUpgrade',
      organizationId: 'organizations/organizationId',
      shippingAddresses: 'organizations/shippingAddresses',
      billingAddresses: 'organizations/billingAddresses',
      dongleId: 'dongles/currentDongleId',
      product: 'dongles/currentDongle',
      dongleEmergencyCodeGenerating: 'dongles/dongleEmergencyCodeGenerating',
      dongleEmergencyCodeError: 'dongles/dongleEmergencyCodeError',
      currentDongleSerialCode: 'dongles/currentDongleSerialCode',
      people: 'organizations/organizationUsers',
      expireDateLoading: 'shop/expireDateLoading',
      expireDateLoadError: 'shop/expireDateLoadError',
      expireDate: 'shop/expireDate',
    }),
  },
})
export default class DongleCard extends Mixins(ProjectMixin) {
  private order;
  private totalPrice;
  private orderDiscountTotal;
  private error;
  private currentDongleRenewOptions;
  private currentDongleUpgradeOptions;
  private currentDongleRenewPossibility;
  private currentDongleUpgradePossibility;
  private currentDongleRenewalsRequired;
  private currentDongleMaxRenewalsAvailable;
  private currentDongleLicenseTooNew;
  private currentDongleLicenseTooOld;
  private currentDongleLicenseNeedsExpirationBeforeUpgrade;
  private currentDongleSerialCode;
  private organizationId;
  private shippingAddresses;
  private billingAddresses;
  private dongleId;
  private isLoading;
  private product;
  private people;
  private orderValid;
  private unsubscribe;
  private dongleEmergencyCodeGenerating;
  private dongleEmergencyCodeError;

  private emergencyCodeDialogVisible = false;
  private renewUpgradeDialogVisible = false;
  private editOrderAddressDialogVisible = false;
  private subtotalCheckoutDialogVisible = false;
  private billingAddress = null;
  private billingAddressValid = false;
  private userData: UserDto | null = null;
  private discountNotFound = false;
  private companyName = this.organization.organizationName;
  private phoneNumber = this.organization.phoneNumber;

  private renewInputIcon = 'mdi-content-copy';
  private upgradeInputIcon = 'mdi-content-copy';
  private showMessage = false;

  private expireDateLoading;
  private expireDateLoadError;
  private expireDate;
  private emergencyCodeLoading = false;
  private shopWasUsed = false;
  private userDataHasBeenChanged = false;

  get user() {
    return this.$store.getters['login/user'];
  }

  get organization() {
    return this.$store.getters['organizations/organization'];
  }

  get activeUserMainRoleWithPermissions() {
    return this.$store.getters['login/activeUserMainRoleWithPermissions'];
  }

  get canManageOrganization() {
    return this.activeUserMainRoleWithPermissions.canManageOrganization;
  }

  get isWysiwygLabel(): string {
    return this.product?.productLevel && this.product?.productLevel !== 'none' ? 'Wysiwyg' : 'Vivien';
  }

  get wysiwygLevel(): string {
    return this.product?.productLevel !== 'none' ? this.product?.productLevel : '---';
  }

  get vivienLevel(): string {
    return this.product?.vivLevel && this.product?.vivLevel !== 'none' && this.product?.productLevel !== 'none'
      ? 'Vivien'
      : '---';
  }

  get licenseType(): string {
    return `${this.isLease ? 'Lease' : 'Perpetual'} License`;
  }

  get isLease(): boolean {
    return this.product.steppingRef === LEASE_STEPPING_ID;
  }

  get productCode() {
    return this.product?.serialCode;
  }

  get message() {
    if (this.error) {
      return {
        isError: true,
        text: this.error,
      };
    } else if (this.discountNotFound) {
      return {
        isError: false,
        text: "Discount code isn't valid",
      };
    }
    return null;
  }

  get assignee() {
    return this.people.find(p => p.userId === this.userRef);
  }

  get assigneeAvatar() {
    return this.assignee?.avatar;
  }

  get assigneeFullName() {
    return this.assignee && getUserFullName(this.assignee);
  }

  get emergencyCode() {
    return this.product?.emergencyCode;
  }

  get cheatCounterExpired() {
    return this.product?.cheatCounter >= 3;
  }

  getUserFullName = getUserFullName;

  async changeAssignee(userId) {
    await this.$store.dispatch('dongles/assignDongle', { dongleId: this.product.dongleId, userId });
  }

  async removeAssignee() {
    await this.$store.dispatch('dongles/unAssignDongle', this.product.dongleId);
  }

  async openEmergencyCodeDialog() {
    if (!this.cheatCounterExpired) {
      this.emergencyCodeLoading = true;
      await this.$store.dispatch('dongles/generateDongleEmergencyCode', this.dongleId);
      this.emergencyCodeLoading = false;
    }
    this.emergencyCodeDialogVisible = true;
  }

  openRenewUpgradeDialog() {
    this.renewUpgradeDialogVisible = true;
  }

  openSubtotalCheckoutDialog() {
    this.subtotalCheckoutDialogVisible = true;
  }

  async openAddressEditorDialog(product) {
    this.shopWasUsed = true;
    await this.$store.dispatch('shop/selectProduct', product);
    this.editOrderAddressDialogVisible = true;
    this.renewUpgradeDialogVisible = false;
  }

  backToRenewDialog() {
    this.editOrderAddressDialogVisible = false;
    this.renewUpgradeDialogVisible = true;
  }

  backToAddressDialog() {
    this.subtotalCheckoutDialogVisible = false;
    this.editOrderAddressDialogVisible = true;
  }

  get userRef() {
    return this.product?.userRef || null;
  }

  get isRetired() {
    return this.product?.isRetired || null;
  }

  get defaultAddress(): AddressDto {
    return this.billingAddresses.at(0);
  }

  get addressToCopy() {
    return this.shippingAddresses.find(address => address.isDefault) || this.shippingAddresses.at(0);
  }

  async createOrder() {
    if (this.dongleId && this.isAddressValid(this.billingAddress)) {
      await this.$store.dispatch('shop/syncOrder', {
        shippingAddress: {
          firstName: this.userData?.firstName,
          lastName: this.userData?.lastName,
          phoneNumber: this.phoneNumber,
          companyName: this.companyName,
          organizationRef: this.organizationId,
        },
        billingAddress: this.getAddress(this.billingAddress),
        existingDongleRef: this.dongleId,
      });
    }
  }

  onUserDataChanged(data) {
    this.userData = { ...data };
    this.userDataHasBeenChanged = true;
  }

  onOrganizationChanged(data) {
    if (data.organizationName !== undefined) {
      this.companyName = data.organizationName;
    }
    if (data.organizationPhone !== undefined) {
      this.phoneNumber = data.organizationPhone;
    }
    this.userDataHasBeenChanged = true;
  }

  async onAddressSelected(address): Promise<void> {
    this.billingAddress = address;
    this.billingAddressValid = this.isAddressValid(this.billingAddress);
    await this.createOrder();
  }

  async checkout() {
    if (this.userDataHasBeenChanged) {
      await this.createOrder();
    }
    this.subtotalCheckoutDialogVisible = false;
    await this.$store.dispatch('shop/redirectToCheckout');
    await this.$store.dispatch('shop/getUserUnfinishedOrder');
  }

  async testCheckout() {
    if (this.userDataHasBeenChanged) {
      await this.createOrder();
    }
    this.subtotalCheckoutDialogVisible = false;
    await this.$store.dispatch('shop/testCheckout');
    await this.$store.dispatch('shop/getUserUnfinishedOrder');
  }

  async saveAddress(address) {
    if (address.addressId) {
      await this.$store.dispatch('organizations/updateAddress', address);
    } else {
      await this.$store.dispatch('organizations/addAddress', {
        ...address,
        organizationRef: this.organization.organizationId,
      });
    }
  }

  created() {
    this.userData = { ...this.user };
    this.unsubscribe = this.$store.subscribe(
      (mutation, state) => mutation.type === 'shop/DISCOUNT_LOADED' && (this.discountNotFound = !state.shop.discount),
    );
  }

  async beforeDestroy() {
    EventBus.$off('productSelected');
    if (this.shopWasUsed) {
      this.$store.commit('shop/CLEAR_ORDER');
      await this.$store.dispatch('shop/getUserUnfinishedOrder');
    }
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  codeChange(e) {
    e.preventDefault();
  }

  async closeEmergencyCodeDialog() {
    this.emergencyCodeDialogVisible = false;
    await this.$store.dispatch('dongles/loadDongles');
    await this.$store.dispatch('dongles/loadDongleActivities', this.product.dongleId);
  }

  async copyCode(codeType = 'upgrade') {
    const content = codeType === 'upgrade' ? this.product.upgradeCode : this.product.renewCode;
    try {
      await this.$copyText(content);
      const icon = codeType === 'upgrade' ? 'upgradeInputIcon' : 'renewInputIcon';
      this[icon] = 'mdi-check';
      setTimeout(() => (this[icon] = 'mdi-content-copy'), 2000);
    } catch (e) {
      this.showMessage = true;
    }
  }

  async getNewExpiryDate(product: Partial<ProductDto & OrderItemDto>) {
    if (this.product && product) {
      await this.$store.dispatch('shop/getNewExpireDate', {
        steppingRef: this.product.steppingRef,
        expiryDate: this.product.expiryDate,
        purchaseOption: product.purchaseOptions,
        duration: product.duration,
        itemCount: product.itemCount,
      });
    }
  }
}
