
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AddressDto, OrganizationDto } from '@/castapi';
import AddressList from '@/components/shared/address/AddressList.vue';
import OrganizationEditor from '@/components/shared/organization/OrganizationEditor.vue';
import snackbarPlugin from '@/plugins/snackbar';

@Component({
  components: {
    OrganizationEditor,
    AddressList,
  },
})
export default class OrganizationInfo extends Vue {
  @Prop({ default: {} }) organizationInfo!: OrganizationDto;
  @Prop({ default: () => [] }) addresses!: AddressDto[];
  @Prop({ default: true }) editable!: boolean;

  private formValid = true;
  private organization: OrganizationDto | null = null;
  private unsubscribe;

  get shippingAddresses() {
    return this.addresses.filter(address => !address.isBilling) || [];
  }

  get defaultAddress() {
    const addresses = this.addresses.filter(address => address.isDefault);
    return addresses.length ? addresses[0] : null;
  }

  get billingAddresses() {
    return this.addresses.filter(address => address.isBilling) || [];
  }

  get valid() {
    return this.formValid;
  }

  async save() {
    await this.$store.dispatch('organizations/updateOrganizationData', { organization: this.organization });
  }

  created() {
    this.organization = { ...this.organizationInfo };
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'organizations/ORGANIZATION_CHANGED') {
        snackbarPlugin.showMessage('Organization successfully updated.');
      } else if (mutation.type === 'organizations/CHANGE_ORGANIZATION_ERROR') {
        snackbarPlugin.showMessage('Organization update failed. Please, try again later.');
      }
    });
  }

  onDataChanged(data) {
    this.organization = { ...this.organization, ...data };
  }

  onValidationChanged(validation) {
    this.formValid = validation;
  }

  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
}
