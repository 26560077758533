import { Configuration, HttpExceptionDto, InvoiceDto, InvoicesApiFactory } from '@/castapi';
import download from 'downloadjs';
import { apiConfig } from '@/shared/constants';
import router from '@/router';
import { AppLogger } from '@/logger';
import { getErrorMessage } from '@/castapi/helpers';

const logger = new AppLogger('invoices state');
const getInvoicesApi = (accessToken?) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return InvoicesApiFactory(config);
};

const initialState = (): {
  invoices: InvoiceDto[];
  invoicesLoadError: null | string;
  invoicesLoading: boolean;
} => ({
  invoices: [],
  invoicesLoadError: null,
  invoicesLoading: false,
});

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
    INVOICES_LOADING(state) {
      state.invoices = [];
      state.invoicesLoading = true;
      state.invoicesLoadError = null;
    },
    INVOICES_LOADED(state, invoices) {
      state.invoicesLoading = false;
      state.invoices = [...invoices];
    },
    INVOICES_LOAD_ERROR(state, invoicesLoadError) {
      state.invoicesLoading = false;
      state.invoicesLoadError = getErrorMessage(invoicesLoadError);
    },
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE');
    },

    async loadInvoices({ commit, rootGetters }) {
      const accessToken = rootGetters['login/accessToken'];
      try {
        commit('INVOICES_LOADING');
        const response = await getInvoicesApi(accessToken).invoicesControllerGetUserInvoices();
        commit('INVOICES_LOADED', response.data);
      } catch (error) {
        commit('INVOICES_LOAD_ERROR', error);
        logger.captureStoreError('loadInvoices', error);
      }
    },

    async downloadInvoice({ rootGetters }, invoiceId) {
      const accessToken = rootGetters['login/accessToken'];
      try {
        const response = await getInvoicesApi(accessToken).invoicesControllerDownloadInvoicePdf(invoiceId);
        download(response.data, 'invoice.pdf', 'application/pdf');
      } catch (error) {
        console.error(error);
        logger.captureStoreError('downloadInvoice', error, { invoiceId });
      }
    },

    async downloadInvoiceWithToken(state, token) {
      try {
        const response = await getInvoicesApi().invoicesControllerDownloadInvoiceWithToken(token);
        download(response.data, 'invoice.pdf', 'application/pdf');
      } catch (error) {
        if ((error as HttpExceptionDto).status === 404) {
          await router.push({ name: '404' });
        }
        console.error(error);
        logger.captureStoreError('downloadInvoiceWithToken', error);
      }
    },
  },
  getters: {
    invoicesList: state => state.invoices,
    invoicesLoading: state => state.invoicesLoading,
    invoicesLoadError: state => state.invoicesLoadError,
  },
};
