
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import ProjectMixin from '@/mixins/ProjectMixin';
import { OrderItemDto } from '@/castapi';
import MoaImg from '../various/MoaImg.vue';

@Component({
  components: {
    MoaImg,
  },
})
export default class CartItem extends Mixins(ProjectMixin) {
  @Prop({ default: () => ({}) }) cartItem!: OrderItemDto & {
    productDescription?: string;
    productCode?: string;
    productImage?: string;
  };
  @Prop({ default: 0 }) index!: number;
  @Prop({ default: false }) checkout!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: true }) allowCountChange!: boolean;
  @Prop({ default: false }) isAdmin!: boolean;
  @Prop({ default: false }) selectable!: boolean;
  @Prop({ default: true }) deletable!: boolean;
  @Prop({ default: 'itemPrice' }) priceFieldName!: string;
  @Prop({ default: 'itemTotalPrice' }) totalPriceFieldName!: string;
  @Prop({ default: 100 }) countAvailable!: number;
  @Prop({ default: true }) countChangeAvailable!: boolean;
  @Prop({ default: false }) isReceipt!: boolean;
  @Prop({ default: false }) showExpireDate!: boolean;
  @Prop({ default: false }) expireDateLoading!: boolean;
  @Prop({ default: null }) expireDateLoadError!: null | Error;
  @Prop({ default: null }) expireDate!: null | {
    newExpireDate: string;
    isObsolete: boolean;
  };

  get selectOptions(): Array<{ count: number }> {
    return this.getRange(1, this.countAvailable).map(i => ({ count: i }));
  }

  get itemTotalPrice() {
    return this.cartItem[this.totalPriceFieldName] * this.cartItem.itemCount;
  }

  get itemSubtotalPrice() {
    return this.cartItem[this.priceFieldName] * this.cartItem.itemCount;
  }

  get itemDiscount() {
    return (this.cartItem.itemDiscount || 0) * this.cartItem.itemCount;
  }

  get discountPromoCode() {
    if (this.isReceipt) {
      return this.isAdmin
        ? this.$store.getters['adminOrders/finishedOrderDiscountPromoCode']
        : this.$store.getters['shop/finishedOrderDiscountPromoCode'];
    }
    return this.isAdmin
      ? this.$store.getters['adminOrders/discountPromoCode']
      : this.$store.getters['shop/discountPromoCode'];
  }

  get productImage() {
    return this.cartItem?.productImage;
  }

  // get descriptionWidth() {
  //   let baseWidth = 7;
  //   if (this.deletable) {
  //     baseWidth -= 1;
  //   }
  //   if (!this.checkout && this.allowCountChange) {
  //     baseWidth -= 2;
  //   }
  //   return baseWidth;
  // }

  get itemProductCode() {
    return this.cartItem?.productCode ? `${this.cartItem?.productCode?.replace('+', '-')}` : null;
  }

  changeProductQuantity(qty) {
    Vue.set(this.cartItem, 'count', qty);
    this.$emit('changeProductCount', this.cartItem);
  }

  removeProduct() {
    this.$emit('removeProduct', this.cartItem);
  }

  itemSelected() {
    this.$emit('itemSelected', this.cartItem);
  }

  get isWyg(): boolean {
    if (!this.productImage) {
      return false;
    }
    return this.productImage.toLowerCase().includes('wyg');
  }

  get isViv(): boolean {
    if (!this.productImage) {
      return false;
    }
    return this.productImage.toLowerCase().includes('vivien');
  }
}
