var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.imgSrc)?_c('div',{staticClass:"d-flex justify-center align-center w-100",class:_vm.marginClass,style:({
    backgroundColor: _vm.background,
    minWidth: _vm.width + 'px',
    height: _vm.height + 'px',
    border: _vm.border ? '1px solid #9a9a9a' : '',
    maxWidth: _vm.width + 'px',
    borderRadius: _vm.borderRadius + '%',
  }),attrs:{"data-unit":"moa-img-component"}},[_c('img',{style:({ maxWidth: _vm.svgWidth + 'px' }),attrs:{"data-unit":"moa-img-tag","alt":_vm.alt,"src":require(`@/assets/images/${_vm.imageSrc}`)}})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }