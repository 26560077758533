export const insertScript = (scriptTagId: string, scriptUrl: string, async = false): void => {
  if (document.getElementById(scriptTagId)) {
    return;
  }
  const scriptTag = document.createElement('script');
  if (async) {
    scriptTag.async = true;
  }
  scriptTag.src = scriptUrl;
  document.head.appendChild(scriptTag);
};

export const removeScript = (scriptTagId: string): void => {
  const scriptTag = document.getElementById(scriptTagId);
  if (scriptTag) {
    scriptTag.remove();
  }
};

export const downloadFileUsingUrl = url => {
  const link = document.createElement('a');
  const linkId = `download_link_${Math.ceil(Math.random() * 100000)}`;
  link.id = linkId;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  if (document?.getElementById(linkId)) {
    //sometimes element is not found, so this code is required
    document.removeChild(link);
  }
};
