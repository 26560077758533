import axios from 'axios';

export default (store, router) => {
  axios.interceptors.request.use(
    config => config,
    error => Promise.reject(error),
  );

  axios.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = { ...error.config };
      if (error.response?.status === 401) {
        if (originalRequest._retry) {
          try {
            await store.dispatch('login/LOGOUT');
            await router.push({ path: '/' });
          } catch (err) {
            console.error(err);
          }
        } else {
          await store.dispatch('login/refreshToken');
          originalRequest.headers.Authorization = `Bearer ${store.getters['login/accessToken']}`;
          originalRequest._retry = true;
          return axios(originalRequest);
        }
      }
      return Promise.reject(error.response);
    },
  );
};
