
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/layout/Header.vue';
import Snackbar from '@/components/snackbar/Snackbar.vue';

@Component({
  components: {
    Header,
    Snackbar,
  },
})
export default class App extends Vue {
  async created() {
    if (this.$store.getters['login/loggedIn']) {
      await this.$store.dispatch('login/RELOAD_CURRENT_USER_DATA');
    }
  }
}
