import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import store from '@/store';
import { ADMIN_PAGE_TITLE, DEFAULT_PAGE_TITLE, STORE_PAGE_TITLE, USER_ROLE_IDS } from '@/shared/constants';
import snackbarPlugin from '@/plugins/snackbar';
import { isTestEnv } from '@/shared/functions';

Vue.use(VueRouter);

export type MoaRouterConfig = RouteConfig & { meta: { roles: string[] } };

export const routes: Array<MoaRouterConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/dongles',
    name: 'Dongles',
    component: () =>
      import(
        /* webpackChunkName: "Dongles" */
        '../components/moa-staff-admin/Dongles/Dongles.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Users
  {
    path: '/users',
    name: 'users',
    component: () =>
      import(
        /* webpackChunkName: "Users" */
        '../components/moa-staff-admin/Users/Users.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/users/:id',
    name: 'edit-user',
    component: () =>
      import(
        /* webpackChunkName: "EditOrganizationAndUser" */
        '../components/moa-staff-admin/Users/EditUser.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Organizations
  {
    path: '/organizations',
    name: 'organizations',
    component: () =>
      import(
        /* webpackChunkName: "Organizations" */
        '../components/moa-staff-admin/Organizations/Organizations.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/organizations/:id',
    name: 'edit-organization',
    component: () =>
      import(
        /* webpackChunkName: "EditOrganizationAndUser" */
        '../components/moa-staff-admin/Organizations/EditOrganization.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/organizations/:id/invoice-history',
    name: 'organization-invoice-history',
    component: () =>
      import(
        /* webpackChunkName: "OrganizationInvoiceHistory" */
        '../components/moa-staff-admin/Organizations/OrganizationInvoiceHistory.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/create-account',
    name: 'create-account',
    component: () =>
      import(
        /* webpackChunkName: "CreateAccount" */
        '../components/moa-staff-admin/Organizations/CreateAccount.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Uploads
  {
    path: '/uploads',
    name: 'uploads',
    component: () =>
      import(
        /* webpackChunkName: "Uploads" */
        '../components/moa-staff-admin/Uploads/Uploads.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/uploads/addUploadLink',
    name: 'add-upload-link',
    component: () =>
      import(
        /* webpackChunkName: "EditUploadLink" */
        '../components/moa-staff-admin/Uploads/EditUpload.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/uploads/editUploadLink/:id',
    name: 'edit-upload-link',
    component: () =>
      import(
        /* webpackChunkName: "EditUploadLink" */
        '../components/moa-staff-admin/Uploads/EditUpload.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Demos
  {
    path: '/admin-demos',
    name: 'admin-demos',
    component: () =>
      import(
        /* webpackChunkName: "AdminDemos" */
        '../components/moa-staff-admin/Demos/DemosList.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/admin-demos/:id',
    name: 'admin-demos-edit',
    component: () =>
      import(
        /* webpackChunkName: "AdminDemosEdit" */
        '../components/moa-staff-admin/Demos/DemosEdit.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Discounts
  {
    path: '/discounts',
    name: 'discounts',
    component: () =>
      import(
        /* webpackChunkName: "Discounts" */
        '../components/moa-staff-admin/Discounts/Discounts.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/discounts/addDiscount',
    name: 'add-discount',
    component: () =>
      import(
        /* webpackChunkName: "EditDiscount" */
        '../components/moa-staff-admin/Discounts/EditDiscount.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/discounts/EditDiscounts/:id',
    name: 'edit-discount',
    component: () =>
      import(
        /* webpackChunkName: "EditDiscount" */
        '../components/moa-staff-admin/Discounts/EditDiscount.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Staff
  {
    path: '/staff',
    name: 'Staff',
    component: () =>
      import(
        /* webpackChunkName: "People" */
        '../components/moa-customer-portal/People/People.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/staff/:id',
    name: 'staff-user-profile',
    component: () =>
      import(
        /* webpackChunkName: "UserProfile" */
        '../components/moa-customer-portal/People/UserProfile.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Orders
  {
    path: '/orders',
    name: 'orders',
    component: () =>
      import(
        /* webpackChunkName: "Orders" */
        '../components/moa-staff-admin/Orders/Orders.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/orders/editOrder/:orderId',
    name: 'edit-order',
    component: () =>
      import(
        /* webpackChunkName: "EditOrder" */
        '../components/moa-staff-admin/Orders/EditOrder.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/orders/editOrder',
    name: 'add-order',
    component: () =>
      import(
        /* webpackChunkName: "EditOrder" */
        '../components/moa-staff-admin/Orders/EditOrder.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: () =>
      import(
        /* webpackChunkName: "Inventory" */
        '../components/moa-staff-admin/Inventory/Inventory.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Shop
  {
    path: '/shop/front',
    name: 'shop-front',
    component: () =>
      import(
        /* webpackChunkName: "Front" */
        '../components/shop/Front/Front.vue'
      ),
    meta: {
      roles: ['owner'],
      title: STORE_PAGE_TITLE,
    },
  },
  {
    path: '/shop/front/cart',
    name: 'shop-cart',
    component: () =>
      import(
        /* webpackChunkName: "Cart" */
        '../components/shop/Cart/Cart.vue'
      ),
    meta: {
      roles: ['owner'],
      title: STORE_PAGE_TITLE,
    },
  },
  {
    path: '/shop/front/checkout',
    name: 'shop-checkout',
    component: () =>
      import(
        /* webpackChunkName: "Checkout" */
        '../components/shop/Checkout/Checkout.vue'
      ),
    meta: {
      roles: ['owner'],
      title: STORE_PAGE_TITLE,
    },
  },
  {
    path: '/shop/front/receipt/:orderId',
    name: 'shop-receipt',
    component: () =>
      import(
        /* webpackChunkName: "Receipt" */
        '../components/shop/Receipt/Receipt.vue'
      ),
    meta: {
      roles: ['owner', 'member'],
      title: STORE_PAGE_TITLE,
    },
  },
  // Login
  {
    path: '/login/forgotpassword',
    name: 'loginforgotpassword',
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */
        '../components/login/ForgotPassword/ForgotPassword.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/login/registration/:token',
    name: 'loginregistrationwithtoken',
    component: () =>
      import(
        /* webpackChunkName: "Registration" */
        '../components/login/Registration/Registration.vue'
      ),
    meta: {
      roles: ['unauthorized'],
    },
  },
  {
    path: '/login/registration',
    name: 'loginregistration',
    component: () =>
      import(
        /* webpackChunkName: "Registration" */
        '../components/login/Registration/Registration.vue'
      ),
    meta: {
      roles: ['unauthorized'],
    },
  },
  {
    path: '/login/resetconfirmation',
    name: 'loginresetconfirmation',
    component: () =>
      import(
        /* webpackChunkName: "ResetConfirmation" */
        '../components/login/ForgotPassword/ResetConfirmation.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/login/resetexpired',
    name: 'loginresetexpired',
    component: () =>
      import(
        /* webpackChunkName: "ResetExpired" */
        '../components/login/ForgotPassword/ResetExpired.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/login/setnewpassword/:token',
    name: 'loginsetnewpassword',
    component: () =>
      import(
        /* webpackChunkName: "SetNewPassword" */
        '../components/login/ForgotPassword/SetNewPassword.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/change-password',
    name: 'changepassword',
    component: () =>
      import(
        /* webpackChunkName: "ChangePassword" */
        '../components/login/ChangePassword/ChangePassword.vue'
      ),
    meta: {
      roles: ['owner', 'member', 'admin'],
    },
  },
  {
    path: '/change-password-confirmation',
    name: 'changepasswordconfirmation',
    component: () =>
      import(
        /* webpackChunkName: "ChangePassword" */
        '../components/login/ChangePassword/ChangePasswordConfirmation.vue'
      ),
    meta: {
      roles: ['owner', 'member', 'admin'],
    },
  },
  {
    path: '/login/signin',
    name: 'loginsignin',
    component: () =>
      import(
        /* webpackChunkName: "Signin" */
        '../components/login/Signin/Signin.vue'
      ),
    meta: {
      roles: ['unauthorized'],
    },
  },
  {
    path: '/login/signout',
    name: 'loginsignout',
    component: () =>
      import(
        /* webpackChunkName: "Signout" */
        '../components/login/Signout/Signout.vue'
      ),
    meta: {
      roles: ['owner', 'member', 'admin', 'nobody'],
    },
  },
  {
    path: '/reauth',
    name: 'reauth',
    component: () =>
      import(
        /* webpackChunkName: "Signout" */
        '../components/login/Reauthorisation/Reauthorisation.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  // Downloads
  {
    path: '/downloads',
    name: 'downloads',
    component: () =>
      import(
        /* webpackChunkName: "Downloads" */
        '../components/moa-customer-portal/Downloads/Downloads.vue'
      ),
    meta: {
      roles: ['owner', 'member'],
    },
  },
  {
    path: '/downloads/:id',
    name: 'product-downloads',
    component: () =>
      import(
        /* webpackChunkName: "ProductDownloads" */
        '../components/moa-customer-portal/Downloads/ProductDownloads.vue'
      ),
    meta: {
      roles: ['owner', 'member'],
    },
  },
  {
    path: '/downloads/:id/info',
    name: 'product-info',
    component: () =>
      import(
        /* webpackChunkName: "DownloadInfo" */
        '../components/moa-customer-portal/Downloads/DownloadInfo.vue'
      ),
    meta: {
      roles: ['owner', 'member'],
    },
  },
  // People
  {
    path: '/people',
    name: 'people',
    component: () =>
      import(
        /* webpackChunkName: "People" */
        '../components/moa-customer-portal/People/People.vue'
      ),
    meta: {
      roles: ['owner'],
    },
  },
  {
    path: '/people/:id',
    name: 'user-profile',
    component: () =>
      import(
        /* webpackChunkName: "UserProfile" */
        '../components/moa-customer-portal/People/UserProfile.vue'
      ),
    meta: {
      roles: ['owner', 'member', 'admin'],
    },
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () =>
      import(
        /* webpackChunkName: "Invoices" */
        '../components/moa-customer-portal/Invoices/Invoices.vue'
      ),
    meta: {
      roles: ['owner'],
    },
  },
  // Products
  {
    path: '/products',
    name: 'products',
    component: () =>
      import(
        /* webpackChunkName: "Products" */
        '../components/moa-customer-portal/Products/Products.vue'
      ),
    meta: {
      roles: ['owner', 'member'],
    },
  },
  // Demo
  {
    path: '/demo/:demoId',
    name: 'download-demo',
    component: () =>
      import(
        /* webpackChunkName: "DemoDownload" */
        '../components/moa-customer-portal/Demos/DemoDownload.vue'
      ),
    meta: {
      roles: ['owner', 'member'],
    },
  },
  {
    path: '/demo/:demoId/result',
    name: 'download-demo-result',
    component: () =>
      import(
        /* webpackChunkName: "DemoDownloadResult" */
        '../components/moa-customer-portal/Demos/DemoDownloadResult.vue'
      ),
    meta: {
      roles: ['owner', 'member'],
    },
  },
  {
    path: '/organization',
    name: 'organization',
    component: () =>
      import(
        /* webpackChunkName: "Organization" */
        '../components/moa-customer-portal/Organization/Organization.vue'
      ),
    meta: {
      roles: ['owner', 'nobody'],
    },
  },
  {
    path: '/myprofile',
    name: 'myprofile',
    component: () =>
      import(
        /* webpackChunkName: "MyProfile" */
        '../components/moa-customer-portal/MyProfile/MyProfile.vue'
      ),
    meta: {
      roles: ['owner', 'member', 'admin'],
    },
  },
  {
    path: '/download-invoice/:token',
    name: 'invoicedownload',
    component: () =>
      import(
        /* webpackChunkName: "InvoiceDownload" */
        '../components/shop/Invoice/InvoiceDownload.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/access-denied',
    name: '403',
    component: () =>
      import(
        /* webpackChunkName: "403" */
        '../components/errors/403.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/page-not-found',
    name: '404',
    component: () =>
      import(
        /* webpackChunkName: "404" */
        '../components/errors/404.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/dongle-web-simulator',
    name: 'dongle-web-simulator',
    component: () =>
      import(
        /* webpackChunkName: "DongleWebSimulator" */
        '../components/debug/DongleWebSimulator.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () =>
      import(
        /* webpackChunkName: "Signout" */
        '../components/shared/various/FeedbackForm.vue'
      ),
    meta: {
      roles: ['owner', 'member', 'admin'],
    },
  },
  {
    path: '/all-feedback',
    name: 'all-feedback',
    component: () =>
      import(
        /* webpackChunkName: "Signout" */
        '../components/shared/various/AllFeedback.vue'
      ),
    meta: {
      roles: ['admin'],
    },
  },
  {
    path: '/view-feedback/:feedbackId',
    name: 'view-feedback',
    component: () =>
      import(
        /* webpackChunkName: "Signout" */
        '../components/shared/various/ViewFeedback.vue'
      ),
    meta: {
      roles: ['admin'],
    },
  },
  {
    path: '*',
    redirect: { name: '404' },
    meta: {
      roles: ['everyone'],
    },
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});
router.beforeEach((to, from, next) => {
  // EVERYONE
  if (to.name === 'dongle-web-simulator') {
    if (isTestEnv()) {
      next();
    } else {
      next({ path: '/page-not-found' });
    }
  }
  if (JSON.stringify(to.meta?.roles) === JSON.stringify(['everyone'])) {
    next();
    // UNAUTHORIZED
  } else if (to.meta?.roles.includes('unauthorized')) {
    if (store.getters['login/loggedIn']) {
      if (to.name === 'loginregistrationwithtoken') {
        snackbarPlugin.showMessageExt({
          message: 'You have followed an invite link intended for unauthorized users. Log out first to use the link.',
          topAndCentered: true,
          timeout: 60000,
        });
      }
      next({ path: '/' });
    } else {
      if (!to.query.plan) {
        to.query.plan = `${from.fullPath}`;
      }
      next();
    }
    // AUTHORIZED: OWNER, MEMBER, ADMIN
  } else if (['owner', 'member', 'admin', 'nobody'].find(role => to.meta?.roles.includes(role))) {
    if (store.getters['login/loggedIn']) {
      const userIsAdmin = store.getters['login/isAdmin'];
      const userIsOwner = store.getters['login/isOwner'];
      const userIsMember = store.getters['login/isMember'];
      const userIsNobody = store.getters['login/isEmptyRole'];
      if (
        (userIsAdmin && to.meta?.roles.includes('admin')) ||
        (userIsOwner && to.meta?.roles.includes('owner')) ||
        (userIsMember && to.meta?.roles.includes('member')) ||
        (userIsNobody && to.meta?.roles.includes('nobody'))
      ) {
        next();
      } else {
        if (to.name === 'shop-cart' && userIsMember) {
          snackbarPlugin.showMessageExt({
            message:
              'You are logged in as a company member. You can only buy the product if you are the owner of the ' +
              'company. If you want to purchase a product, please log out and create an account as the owner of the ' +
              'company.',
            topAndCentered: true,
            timeout: 3600000,
          });
        }
        next({ path: '/' });
      }
    } else {
      next({
        path: '/login/signin',
        query: {
          plan: to.fullPath,
        },
      });
    }
    // UNKNOWN ROLE
  } else {
    next({ path: '/access-denied' }); // make sure to always call next()!
    console.error('Error in role based access control');
  }
});

router.afterEach(to => {
  Vue.nextTick(() => {
    if (to.fullPath === '/') {
      const userIsAdmin = store.getters['login/isAdmin'];
      const userIsOwner = store.getters['login/roleId'] === USER_ROLE_IDS.OWNER_USER_ROLE_ID;
      if (userIsAdmin) {
        document.title = ADMIN_PAGE_TITLE;
      } else if (userIsOwner) {
        document.title = STORE_PAGE_TITLE;
      } else {
        document.title = DEFAULT_PAGE_TITLE;
      }
    } else {
      document.title = to.meta?.title || DEFAULT_PAGE_TITLE;
    }
  });
});

export default router;
