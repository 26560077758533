import OrderState from '@/store/modules/common/OrderState';

const order = new OrderState();

const initialState = () => ({
  ...order.state(),
});

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    ...order.mutations,

    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
  },
  actions: {
    ...order.actions,

    resetState({ commit }) {
      commit('RESET_STATE');
    },
  },
  getters: {
    ...order.getters,
  },
};
