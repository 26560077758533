
import { Component, Prop, Vue } from 'vue-property-decorator';
import DongleCard from '@/components/shared/dongle/DongleCard.vue';
import DongleActivityCard from '@/components/shared/dongle/DongleActivityCard.vue';
import { DongleActivityDto } from '@/castapi';

@Component({
  components: {
    DongleCard,
    DongleActivityCard,
  },
})
export default class ProductInfo extends Vue {
  @Prop({ default: () => [] }) dongleActivities!: DongleActivityDto[];
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: null }) error!: string | null;
}
