
import { Component, Vue } from 'vue-property-decorator';
import MoaAvatar from '@/components/shared/various/MoaAvatar.vue';
import { DongleUsersInfoDto } from '@/castapi';
import { DataTableHeader } from 'vuetify';

@Component({ components: { MoaAvatar } })
// eslint-disable-next-line vue/multi-word-component-names
export default class Dongles extends Vue {
  public search = '';
  private headers: DataTableHeader[] = [
    { text: 'SERIAL CODE', align: 'start', value: 'serialCode' },
    { text: 'SERIAL NUMBER', value: 'serialNumber' },
    { text: 'USER ASSIGNED', value: 'assignedFullName' },
    { text: 'VENDOR', value: 'vendorFullName' },
    { text: 'ORGANIZATION', value: 'ownerFullName' },
    { text: 'WYSIWYG Level', value: 'wysiwygProduct' },
    { text: 'Vivien Level', value: 'vivienProduct' },
    { text: 'License type', value: 'licenseType' },
  ];

  get dongles(): Array<DongleUsersInfoDto> {
    return this.$store.getters['adminDongles/dongles'];
  }

  get isLoading() {
    return this.$store.getters['adminDongles/allDonglesLoading'];
  }

  get loadError() {
    return this.$store.getters['adminDongles/allDonglesLoadError'];
  }

  async openUser(dongle: DongleUsersInfoDto): Promise<void> {
    if (dongle.userId) {
      await this.$router.push({ name: 'edit-user', params: { id: dongle.userId.toString() } });
    }
  }

  async openOrganization(organizationId: null | number): Promise<void> {
    if (organizationId) {
      await this.$router.push({ name: 'edit-organization', params: { id: organizationId.toString() } });
    }
  }

  async created() {
    await this.$store.dispatch('adminDongles/resetState');
    await this.$store.dispatch('adminDongles/loadDongles');
  }

  itemClass(item) {
    return item.isRetired ? 'is-deleted' : null;
  }
}
