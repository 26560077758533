import { emailPattern } from '@/shared/constants';
import { Vue } from 'vue-property-decorator';

export type VForm = Vue & { validate: () => boolean; reset: () => void; resetValidation: () => void };
export type VInput = Vue & { value: null | string };

export const rules = {
  required: value => Boolean(value) || 'This field is required',
  min: (v, minLength = 4) => !v || v.length >= minLength || 'Min 4 characters',
  email: value => emailPattern.test(value) || 'Invalid e-mail',
  confirmPassword: (value, password) => value === password || 'Password does not match',
};
