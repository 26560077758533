
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AddressDto } from '@/castapi';
import AddressEditor from '@/components/shared/address/AddressEditor.vue';

@Component({ components: { AddressEditor } })
export default class AddressEditorDialog extends Vue {
  @Prop({ default: () => ({}) }) value!: AddressDto;
  @Prop({ default: null }) addressForCopy!: AddressDto;
  @Prop({ default: false }) isBilling!: boolean;
  @Prop({ default: false }) deletable!: boolean;
  @Prop({ default: false }) showDialog!: boolean;

  get displayDialog() {
    return this.showDialog;
  }

  // noinspection JSUnusedGlobalSymbols
  set displayDialog(value: boolean) {
    if (this.showDialog !== value) {
      this.$emit('visibilityChanged', value);
    }
  }

  saveClick(address) {
    this.$emit('saveClick', address);
  }

  cancelClick() {
    this.$emit('cancelClick');
  }

  deleteAddress() {
    this.$emit('deleteAddress');
  }
}
