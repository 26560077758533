
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AddressDto } from '@/castapi';
import ProjectMixin from '@/mixins/ProjectMixin';
import AddressEditorDialog from '@/components/shared/address/AddressEditorDialog.vue';

@Component({ components: { AddressEditorDialog } })
export default class AddressDisplay extends Mixins(ProjectMixin) {
  @Prop({ default: null }) value!: null | AddressDto;
  @Prop({ default: null }) addressForCopy!: AddressDto;
  @Prop({ default: false }) isBilling!: boolean;
  @Prop({ default: false }) deletable!: boolean;
  @Prop({ default: true }) editable!: boolean;
  @Prop({ default: false }) showDefaultOnly!: boolean;
  @Prop({ default: false }) showEditDialogAddressId!: null | number;
  @Prop({ default: false }) disabled!: boolean;

  get showDialog() {
    return this.showEditDialogAddressId === this.value?.addressId;
  }

  dialogVisibilityChanged(value) {
    this.$emit('dialogVisibilityChanged', value);
  }

  get countries() {
    return this.$store.getters['dictionary/countries'];
  }

  async created() {
    await this.$store.dispatch('dictionary/getCountries');
  }

  deleteAddress() {
    this.$emit('deleteAddress');
  }

  editClick() {
    this.$emit('dialogVisibilityChanged', this.value?.addressId);
  }

  saveClick($event) {
    this.$emit('saveClick', $event);
  }

  cancelEdit() {
    this.$emit('dialogVisibilityChanged', null);
  }
}
