import { Configuration, DemoApiFactory, DemoDto, DemoLeadDto } from '@/castapi';
import { apiConfig } from '@/shared/constants';
import { AppLogger } from '@/logger';
import { getErrorMessage } from '@/castapi/helpers';
import { downloadFileUsingUrl } from '@/shared/functions';

const parseError = payload => getErrorMessage(payload);

const logger = new AppLogger('demo state');

const getDemosApi = (accessToken?) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return DemoApiFactory(config);
};

const initialState = (): {
  availableDemos: DemoDto[];
  availableDemosLoading: boolean;
  availableDemosLoadError: null | Error;
  demoLinkLoading: boolean;
  demoLinkLoadError: null | Error;
  demoLeads: null | DemoLeadDto;
  demoLeadsLoading: boolean;
  demoLeadsLoadError: null | Error;
  professions: [] | Error;
} => ({
  availableDemos: [],
  availableDemosLoading: false,
  availableDemosLoadError: null,
  demoLinkLoading: false,
  demoLinkLoadError: null,
  demoLeads: null,
  demoLeadsLoading: false,
  demoLeadsLoadError: null,
  professions: [],
});

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
    DEMOS_LOADING(state) {
      state.availableDemos = [];
      state.availableDemosLoading = true;
      state.availableDemosLoadError = null;
    },
    DEMOS_LOADED(state, demos) {
      state.availableDemos = demos;
      state.availableDemosLoading = false;
    },
    DEMOS_LOAD_ERROR(state, payload) {
      state.availableDemosLoading = false;
      state.availableDemosLoadError = getErrorMessage(payload);
    },
    DEMO_LOADING(state) {
      state.demoLinkLoading = true;
      state.demoLinkLoadError = null;
    },
    DEMO_LOADED(state) {
      state.demoLinkLoading = false;
    },
    DEMO_LOAD_ERROR(state, payload) {
      state.demoLinkLoading = false;
      state.demoLinkLoadError = getErrorMessage(payload);
    },
    DEMO_LEADS_LOADING(state) {
      state.demoLeads = null;
      state.demoLeadsLoading = true;
      state.demoLeadsLoadError = null;
    },
    DEMO_LEADS_LOADED(state, demoLeads) {
      state.demoLeads = demoLeads;
      state.demoLeadsLoading = false;
    },
    DEMO_LEADS_LOAD_ERROR(state, payload) {
      state.demoLeadsLoading = false;
      state.demoLeadsLoadError = parseError(payload);
    },
    PROFESSIONS_LOADED(state, payload) {
      state.professions = payload;
    },
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE');
    },
    async loadDemos({ commit, rootGetters }, token) {
      try {
        commit('DEMOS_LOADING');
        const response = await getDemosApi(token || rootGetters['login/accessToken']).demoControllerFindAll();
        commit('DEMOS_LOADED', response.data);
      } catch (error) {
        commit('DEMOS_LOAD_ERROR', error);
        logger.captureStoreError('loadDemos', error);
      }
    },
    async downloadDemo({ commit, rootGetters }, demoLead) {
      const organizationRef = rootGetters['organizations/organization'].organizationId;
      try {
        commit('DEMO_LOADING');
        const response = await getDemosApi(rootGetters['login/accessToken']).demoControllerGetDemoDownloadLink({
          ...demoLead,
          organizationRef,
        });
        const url = response.data;
        downloadFileUsingUrl(url);
        commit('DEMO_LOADED');
      } catch (error) {
        commit('DEMO_LOAD_ERROR', error);
        logger.captureStoreError('downloadDemo', error, { demoLead });
      }
    },
    async loadDemoLeads({ commit, rootGetters }) {
      try {
        commit('DEMO_LEADS_LOADING');
        const response = await getDemosApi(rootGetters['login/accessToken']).demoControllerGetDemoLeadsForCurrentUser();
        commit('DEMO_LEADS_LOADED', response.data);
      } catch (error) {
        commit('DEMO_LEADS_LOAD_ERROR', error);
        logger.captureStoreError('loadDemoLeads', error);
      }
    },
    async loadProfessions({ commit, rootGetters }, productLine) {
      try {
        //commit('DEMOS_LOADING');
        const response = await getDemosApi(rootGetters['login/accessToken']).demoControllerGetProfessions(productLine);
        commit('PROFESSIONS_LOADED', response.data);
      } catch (error) {
        commit('DEMOS_LOAD_ERROR', error);
        logger.captureStoreError('loadProfessions', error, { productLine });
      }
    },
  },
  getters: {
    availableDemos: state => state.availableDemos,
    demoLeads: state => state.demoLeads,
    professions: state => state.professions,
  },
};
