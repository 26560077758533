
import { Component, Mixins, Prop } from 'vue-property-decorator';
import ProjectMixin from '@/mixins/ProjectMixin';
import { DongleActivityDto } from '@/castapi';

@Component
export default class DongleActivityCard extends Mixins(ProjectMixin) {
  @Prop({ default: () => [] }) activities!: Array<DongleActivityDto>;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: null }) error!: null | string;
  @Prop({ default: false }) hideTitle!: boolean;

  async openOrder(orderId: number) {
    if (orderId !== 0) {
      await this.$router.push({
        name: 'edit-order',
        params: { orderId: orderId.toString() },
      });
    }
  }
}
