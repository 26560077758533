import { Configuration, CreateDongleDto, DongleDto, DonglesApiFactory } from '@/castapi';
import { apiConfig } from '@/shared/constants';
import { AppLogger } from '@/logger';
import { getErrorMessage } from '@/castapi/helpers';

const logger = new AppLogger('adminInventory state');

const initialState = (): {
  inventory: DongleDto[];
  inventoryCount: number;
  inventoryLoading: boolean;
  inventoryLoadError: null | Error;
  addInventoryLoading: boolean;
  addInventoryLoadError: null | string;
  duplicatedDongleCodes: string[];
  createdDongleCodes: string[];
  deletedDongleCodes: string[];
  donglesCanNotBeDeletedCodes: string[];
  deleteInventoryError: null | string;
} => ({
  inventory: [],
  inventoryCount: 0,
  inventoryLoading: false,
  inventoryLoadError: null,
  addInventoryLoading: false,
  addInventoryLoadError: null,
  duplicatedDongleCodes: [],
  createdDongleCodes: [],
  deletedDongleCodes: [],
  donglesCanNotBeDeletedCodes: [],
  deleteInventoryError: null,
});

const getDonglesApi = (accessToken?) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return DonglesApiFactory(config);
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    INVENTORY_LOADING(state) {
      state.inventoryLoadError = null;
      state.inventoryLoading = true;
      state.deleteInventoryError = null;
      state.deletedDongleCodes = [];
      state.donglesCanNotBeDeletedCodes = [];
    },

    INVENTORY_LOADED(state, payload) {
      state.inventory = payload.items;
      state.inventoryCount = payload.count;
      state.inventoryLoading = false;
    },

    INVENTORY_LOAD_ERROR(state, error) {
      state.inventoryLoadError = getErrorMessage(error);
      state.inventoryLoading = false;
    },

    ADD_INVENTORY_LOADING(state) {
      state.addInventoryLoadError = null;
      state.addInventoryLoading = true;
      state.duplicatedDongleCodes = [];
      state.createdDongleCodes = [];
    },

    ADD_INVENTORY(state, payload) {
      state.duplicatedDongleCodes = payload.dongleNotChangedCodes;
      state.createdDongleCodes = payload.dongleChangedCodes;
      state.addInventoryLoading = false;
    },
    ADD_INVENTORY_LOAD_ERROR(state, error) {
      state.addInventoryLoadError = getErrorMessage(error);
      state.addInventoryLoading = false;
    },

    REMOVE_INVENTORY(state, payload) {
      state.inventoryLoading = false;
      state.donglesCanNotBeDeletedCodes = payload.dongleNotChangedCodes;
      state.deletedDongleCodes = payload.dongleChangedCodes;
    },

    REMOVE_INVENTORY_ERROR(state, error) {
      state.inventoryLoading = false;
      state.deleteInventoryError = getErrorMessage(error);
    },

    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
  },
  actions: {
    async loadInventory({ commit, rootGetters }, payload) {
      const { limit, offset, searchText, sortBy, sortDesc } = payload || {};
      try {
        // commit('RESET_STATE');
        commit('INVENTORY_LOADING');
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetAllInventory(
          limit,
          offset,
          searchText,
          sortBy,
          sortDesc,
        );
        commit('INVENTORY_LOADED', response.data);
      } catch (error) {
        commit('INVENTORY_LOAD_ERROR', error);
        logger.captureStoreError('loadInventory', error, { limit, offset, searchText, sortBy, sortDesc });
      }
    },

    async addInventory({ commit, dispatch, rootGetters }, data: CreateDongleDto[]) {
      try {
        commit('ADD_INVENTORY_LOADING');
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerLoadInventory(data);
        commit('ADD_INVENTORY', response.data);
        await dispatch('loadInventory');
      } catch (error) {
        commit('ADD_INVENTORY_LOAD_ERROR', error);
        logger.captureStoreError('addInventory', error, data);
      }
    },

    async removeInventory({ commit, rootGetters }, data) {
      try {
        commit('INVENTORY_LOADING');
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerRemoveInventoryItem(
          data,
        );
        commit('REMOVE_INVENTORY', response.data);
      } catch (error) {
        commit('REMOVE_INVENTORY_ERROR', error);
        logger.captureStoreError('removeInventory', error, data);
      }
    },
    resetState({ commit }) {
      commit('RESET_STATE');
    },
  },
  getters: {
    inventory: state => state.inventory,
    inventoryCount: state => state.inventoryCount,
    inventoryLoading: state => state.inventoryLoading,
    inventoryLoadError: state => state.inventoryLoadError,
    addInventoryLoading: state => state.addInventoryLoading,
    addInventoryLoadError: state => state.addInventoryLoadError,
    duplicatedDongleCodes: state => state.duplicatedDongleCodes,
    createdDongleCodes: state => state.createdDongleCodes,
    donglesCanNotBeDeletedCodes: state => state.donglesCanNotBeDeletedCodes,
    deletedDongleCodes: state => state.deletedDongleCodes,
  },
};
