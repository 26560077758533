import {
  AddressApiFactory,
  AddressDto,
  BucketApiFactory,
  Configuration,
  CreateAddressDto,
  OrganizationDto,
  OrganizationExtendedDto,
  OrganizationsApiFactory,
  UpdateAddressDto,
  UserDto,
  UserRoleBindingDto,
  UserRoleDto,
  UsersApiFactory,
  UserUpdateDto,
} from '@/castapi';

import { apiConfig, PERMISSIONS, USER_GROUP_IDS, USER_ROLE_IDS } from '@/shared/constants';
import { uploadImage } from '@/store/modules/common/Files';
import { omit } from '@/shared/functions';
import { AppLogger } from '@/logger';
import { getErrorMessage, omitUserSecretFields } from '@/castapi/helpers';

export interface RoleWithBindingDto extends UserRoleDto, UserRoleBindingDto {}

const logger = new AppLogger('organizations state');

const getOrganizationsApi = (accessToken?) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return OrganizationsApiFactory(config);
};

const getUsersApi = (accessToken?) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return UsersApiFactory(config);
};

const getAddressApi = (accessToken?) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return AddressApiFactory(config);
};

const getBucketApi = (accessToken?) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return BucketApiFactory(config);
};

const filterUniqueUsers = (user, index, self) => self.findIndex(value => value.userId === user.userId) >= index;

const initialState = (): {
  organizations: OrganizationDto[];
  canCreateOrganization: boolean | null;
  addresses: AddressDto[];
  userRolesBinding: UserRoleBindingDto[];
  users: UserDto[];
  addressChanging: boolean;
  changeAddressError: string | null;
  addressOperation: string | null;
  organizationChanging: boolean;
  changeOrganizationError: string | null;
  invitationCreating: boolean;
  invitationCreateError: string | null;
  organizationTransferring: boolean;
  organizationTransferError: string | null;
  userDeleting: UserDto | null;
  userDeleteError: string | null;
  userUpdating: UserDto | null;
  userUpdateError: string | null;
  userRolesChanging: UserDto | null;
  userRolesChangeError: string | null;
} => ({
  organizations: [],
  addresses: [],
  userRolesBinding: [],
  users: [],
  addressChanging: false,
  changeAddressError: null,
  organizationChanging: false,
  changeOrganizationError: null,
  invitationCreating: false,
  invitationCreateError: null,
  addressOperation: null,
  organizationTransferring: false,
  organizationTransferError: null,
  userDeleting: null,
  userDeleteError: null,
  userUpdating: null,
  userUpdateError: null,
  userRolesChanging: null,
  userRolesChangeError: null,
  canCreateOrganization: null,
});

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
    ORGANIZATIONS_LOADED(state, organizationsExtended: OrganizationExtendedDto[]) {
      state.organizations = [];
      state.addresses = [];
      state.userRolesBinding = [];
      state.users = [];
      organizationsExtended.forEach(org => {
        state.organizations = [...state.organizations, org.organization];
        state.addresses = [...state.addresses, ...org.addresses];
        state.userRolesBinding = [...state.userRolesBinding, ...org.rolesBinding];
        state.users = [...state.users, ...org.users];
      });
    },
    CAN_CREATE_ORGANIZATION_LOADED(state, canCreateOrganization: boolean) {
      state.canCreateOrganization = canCreateOrganization;
    },
    ORGANIZATION_ADDRESSES_LOADED(state, { organizationId, addresses }) {
      state.addresses = [
        ...state.addresses.filter(address => address.organizationRef !== organizationId),
        ...addresses,
      ];
    },
    ADDRESS_CHANGING(state) {
      state.changeAddressError = null;
      state.addressChanging = true;
    },
    CHANGE_ADDRESS_ERROR(state, payload) {
      state.changeAddressError = getErrorMessage(payload);
      state.addressChanging = false;
      state.addressOperation = payload;
    },
    ADDRESS_CHANGED(state, payload) {
      state.addressChanging = false;
      state.addressOperation = payload;
    },
    ORGANIZATION_CHANGING(state) {
      state.changeOrganizationError = null;
      state.organizationChanging = true;
    },
    CHANGE_ORGANIZATION_ERROR(state, payload) {
      state.changeOrganizationError = getErrorMessage(payload);
      state.organizationChanging = false;
    },
    ORGANIZATION_CHANGED(state, newOrganization) {
      state.organizationChanging = false;
      const index = state.organizations.findIndex(org => org.organizationId === newOrganization.organizationId);
      if (index > -1) {
        state.organizations.splice(index, 1, newOrganization);
      }
    },
    INVITATION_CREATING(state) {
      state.invitationCreating = true;
      state.invitationCreateError = null;
    },
    INVITATION_CREATED(state) {
      state.invitationCreating = false;
      state.invitationCreateError = null;
    },
    INVITATION_CANCELED(state) {
      state.invitationCreating = false;
      state.invitationCreateError = null;
    },
    INVITATION_ERROR(state, payload) {
      state.invitationCreating = false;
      state.invitationCreateError = getErrorMessage(payload);
    },
    ORGANIZATION_TRANSFERRING(state) {
      state.organizationTransferring = true;
      state.organizationTransferError = null;
    },
    ORGANIZATION_TRANSFERRED(state) {
      state.organizationTransferring = false;
      state.organizationTransferError = null;
    },
    ORGANIZATION_TRANSFER_ERROR(state, payload) {
      state.organizationTransferring = false;
      state.organizationTransferError = getErrorMessage(payload);
    },
    USER_DELETING(state, user) {
      state.userDeleteError = null;
      state.userDeleting = user;
    },
    USER_DELETED(state) {
      state.userDeleting = null;
      state.userDeleteError = null;
    },
    USER_DELETE_ERROR(state, payload) {
      state.userDeleting = null;
      state.userDeleteError = getErrorMessage(payload);
    },
    USER_ROLES_CHANGING(state, user) {
      state.userRolesChanging = user;
      state.userRolesChangeError = null;
    },
    USER_ROLES_CHANGED(state) {
      state.userRolesChanging = null;
      state.userRolesChangeError = null;
    },
    USER_ROLES_CHANGE_ERROR(state, payload) {
      state.userRolesChanging = null;
      state.userRolesChangeError = getErrorMessage(payload);
    },
    USER_UPDATING(state, user) {
      state.userUpdating = user;
      state.userUpdateError = null;
    },
    USER_UPDATED(state) {
      state.userUpdating = null;
    },
    USER_UPDATE_ERROR(state, payload) {
      state.userUpdateError = getErrorMessage(payload);
      state.userUpdating = null;
    },
  },
  actions: {
    async getUserOrganizations({ dispatch, commit, rootGetters }, token) {
      try {
        const response = await getOrganizationsApi(
          token || rootGetters['login/accessToken'],
        ).organizationsControllerGetUserOrganizations();
        commit('ORGANIZATIONS_LOADED', response.data);
        if (!response.data.length) {
          await dispatch('getCanCreateOrganization', token);
        }
      } catch (error) {
        logger.captureStoreError('getUserOrganizations', error);
        // Plan: handle error
      }
    },
    async getCanCreateOrganization({ commit, rootGetters }, token: string) {
      try {
        const response = await getUsersApi(
          token || rootGetters['login/accessToken'],
        ).usersControllerGetCanCreateOrganization();
        commit('CAN_CREATE_ORGANIZATION_LOADED', response.data);
      } catch (error) {
        logger.captureStoreError('getCanCreateOrganization', error);
      }
    },
    async getOrganizationAddresses({ commit, rootGetters }, organizationId) {
      try {
        const response = await getAddressApi(
          rootGetters['login/accessToken'],
        ).addressControllerGetOrganizationAddresses(organizationId);
        commit('ORGANIZATION_ADDRESSES_LOADED', { organizationId, addresses: response.data });
      } catch (error) {
        logger.captureStoreError('getOrganizationAddresses', error, { organizationId });
        // Plan: handle error
      }
    },
    async addAddress({ commit, dispatch, rootGetters }, address: CreateAddressDto) {
      commit('ADDRESS_CHANGING');
      try {
        await getAddressApi(rootGetters['login/accessToken']).addressControllerCreateAddress(address);
        if (rootGetters['login/isAdmin']) {
          await dispatch('adminOrganizations/getOrganizationData', address.organizationRef, { root: true });
        } else {
          await dispatch('getOrganizationAddresses', address.organizationRef);
        }
        commit('ADDRESS_CHANGED', 'added');
      } catch (error) {
        commit('CHANGE_ADDRESS_ERROR', error);
        logger.captureStoreError('addAddress', error, { address });
      }
    },
    async updateAddress({ commit, dispatch, rootGetters }, address: UpdateAddressDto) {
      commit('ADDRESS_CHANGING', address);
      try {
        await getAddressApi(rootGetters['login/accessToken']).addressControllerUpdateAddress(address);
        if (rootGetters['login/isAdmin']) {
          await dispatch('adminOrganizations/getOrganizationData', address.organizationRef, { root: true });
        } else {
          await dispatch('getOrganizationAddresses', address.organizationRef);
        }
        commit('ADDRESS_CHANGED', 'updated');
      } catch (error) {
        commit('CHANGE_ADDRESS_ERROR', error);
        logger.captureStoreError('updateAddress', error, { address });
      }
    },
    async deleteAddress({ commit, dispatch, rootGetters }, address: AddressDto) {
      commit('ADDRESS_CHANGING');
      try {
        await getAddressApi(rootGetters['login/accessToken']).addressControllerDeleteAddress({
          addressId: address.addressId,
        });
        if (rootGetters['login/isAdmin']) {
          await dispatch('adminOrganizations/getOrganizationData', address.organizationRef, { root: true });
        } else {
          await dispatch('getOrganizationAddresses', address.organizationRef);
        }
        commit('ADDRESS_CHANGED', 'deleted');
      } catch (error) {
        commit('CHANGE_ADDRESS_ERROR', error);
        logger.captureStoreError('deleteAddress', error, { address });
      }
    },
    async updateOrganizationData(
      { commit, rootState },
      { organization, avatarImage }: { organization: OrganizationDto; avatarImage?: object | undefined },
    ) {
      commit('ORGANIZATION_CHANGING');
      try {
        let avatarUrl: null | string = null;
        const accessToken = rootState.login.accessToken;
        if (avatarImage) {
          avatarUrl = await uploadImage(
            avatarImage,
            `organization-avatars/${organization.organizationId}`,
            getBucketApi(accessToken),
          );
        }

        let updateBody = { ...organization };
        if (avatarImage && avatarUrl) {
          updateBody = { ...updateBody, organizationAvatar: avatarUrl };
        }

        const response = await getOrganizationsApi(accessToken).organizationsControllerUpdateOrganization(updateBody);
        commit('ORGANIZATION_CHANGED', response.data);
      } catch (error) {
        commit('CHANGE_ORGANIZATION_ERROR', error);
        logger.captureStoreError('updateOrganizationData', error, { organization, avatarImage });
      }
    },
    async inviteMember({ commit, dispatch, rootState }, { user, organization, roles }) {
      commit('INVITATION_CREATING');
      try {
        await getUsersApi(rootState.login.accessToken).usersControllerInviteMember({
          user,
          organization,
          roles,
        });
        commit('INVITATION_CREATED');
        await dispatch('getUserOrganizations', rootState.login.accessToken);
      } catch (error) {
        commit('INVITATION_ERROR', error);
        logger.captureStoreError('inviteMember', error, {
          invitedUser: omitUserSecretFields(user),
          organization,
          roles,
        });
      }
    },
    async transferOrganization({ commit, dispatch, rootState }, { userId, organizationId, notes }) {
      commit('ORGANIZATION_TRANSFERRING');
      try {
        await getUsersApi(rootState.login.accessToken).usersControllerTransferOrganization({
          userId,
          organizationId,
          notes,
        });
        commit('ORGANIZATION_TRANSFERRED');
        await dispatch('getUserOrganizations', rootState.login.accessToken);
        await dispatch('adminOrganizations/getOrganizationData', organizationId, { root: true });
      } catch (error) {
        commit('ORGANIZATION_TRANSFER_ERROR', error);
        logger.captureStoreError('transferOrganization', error, { userId, organizationId, notes });
      }
    },
    async resendInvitationToMember({ commit, rootState }, { user, organization }) {
      commit('INVITATION_CREATING');
      try {
        await getUsersApi(rootState.login.accessToken).usersControllerResendMemberInvitation({ user, organization });
        commit('INVITATION_CREATED');
      } catch (error) {
        commit('INVITATION_ERROR', error);
        logger.captureStoreError('resendInvitationToMember', error, {
          invitedUser: omitUserSecretFields(user),
          organization,
        });
      }
    },
    async cancelMemberInvitation({ commit, rootState }, { user, organization }) {
      commit('INVITATION_CREATING');
      try {
        await getUsersApi(rootState.login.accessToken).usersControllerCancelUserInvitation({
          userId: user.userId,
          organizationId: organization.organizationId,
        });
        commit('INVITATION_CANCELED');
      } catch (error) {
        commit('INVITATION_ERROR', error);
        logger.captureStoreError('cancelMemberInvitation', error, {
          invitedUser: omitUserSecretFields(user),
          organization,
        });
      }
    },
    async removeUserFromOrganization({ commit, rootState }, { user, organization }) {
      commit('USER_DELETING', user);
      try {
        await getUsersApi(rootState.login.accessToken).usersControllerRemoveUserFromOrganization({
          userId: user.userId,
          organizationId: organization.organizationId,
        });
        commit('USER_DELETED');
      } catch (error) {
        commit('USER_DELETE_ERROR', error);
        logger.captureStoreError('removeUserFromOrganization', error, {
          removingUser: omitUserSecretFields(user),
          organization,
        });
      }
    },
    async changeUserRoles({ commit, dispatch, rootState, getters }, { user, roles }) {
      commit('USER_ROLES_CHANGING', user);
      const organizationId = getters.organization.organizationId;
      try {
        await getUsersApi(rootState.login.accessToken).usersControllerChangeUserRoles({
          organizationId,
          roles,
          userId: user.userId,
        });
        commit('USER_ROLES_CHANGED');
        await dispatch('getUserOrganizations', rootState.login.accessToken);
      } catch (error) {
        commit('USER_ROLES_CHANGE_ERROR', error);
        logger.captureStoreError('changeUserRoles', error, {
          changingUser: omitUserSecretFields(user),
          roles,
        });
      }
    },
    async updateUser({ commit, dispatch, getters, rootGetters }, { user, avatarImage }) {
      commit('USER_UPDATING', user);
      try {
        let avatar: null | string = null;
        const accessToken = rootGetters['login/accessToken'];
        if (avatarImage) {
          avatar = await uploadImage(avatarImage, `user-avatars/${user.userId}`, getBucketApi(accessToken));
        }

        let updateBody = omit(
          user,
          Object.keys(user).filter(
            f => !['firstName', 'lastName', 'phoneNumber', 'email', 'avatar', 'viewedTours'].includes(f),
          ),
        ) as UserUpdateDto;
        if (avatarImage && avatar) {
          updateBody = { ...updateBody, avatar };
        }

        const response = await getUsersApi(accessToken).usersControllerUpdate(
          user.userId,
          getters.organizationId,
          updateBody,
        );
        if (rootGetters['login/user'].userId === user.userId) {
          await dispatch('login/updateUser', response.data, { root: true });
        } else {
          await dispatch('getUserOrganizations', accessToken);
        }
        commit('USER_UPDATED');
      } catch (error) {
        commit('USER_UPDATE_ERROR', error);
        logger.captureStoreError('updateUser', error, { changingUser: omitUserSecretFields(user), avatarImage });
      }
    },
    async createOrganization({ commit, dispatch, rootGetters }, { organizationName, countryRef }) {
      commit('ORGANIZATION_CHANGING');
      try {
        const accessToken = rootGetters['login/accessToken'];
        const userId = rootGetters['login/userId'];
        await getOrganizationsApi(accessToken).organizationsControllerCreateOrganization({
          userId,
          organizationName,
          countryRef,
        });
        commit('ORGANIZATION_CHANGED');
        await dispatch('login/GET_CURRENT_USER_DATA', accessToken, { root: true });
      } catch (error) {
        commit('CHANGE_ORGANIZATION_ERROR', error);
        logger.captureStoreError('createOrganization', error, { organizationName, countryRef });
      }
    },
    resetState({ commit }) {
      commit('RESET_STATE');
    },
  },
  getters: {
    addresses: state => state.addresses,
    addressOperation: state => state.addressOperation,
    shippingAddresses: state => state.addresses.filter(address => !address.isBilling),
    billingAddresses: state => state.addresses.filter(address => address.isBilling),
    addressChanging: state => state.addressChanging,
    changeAddressError: state => state.changeAddressError,
    organization: state => (state.organizations.length ? state.organizations[0] : null),
    organizationId: (state, getters) => getters.organization?.organizationId,
    organizationName: (state, getters) => getters.organization?.organizationName,
    organizationAddresses: (state, getters) =>
      getters.organization
        ? getters.addresses.filter(address => address.organizationRef === getters.organizationId)
        : [],
    organizationChanging: state => state.organizationChanging,
    changeOrganizationError: state => state.changeOrganizationError,
    organizationUserRoles: (state, getters) =>
      state.userRolesBinding.filter(roleBinding => roleBinding.organizationRef === getters.organizationId),
    organizationUsers: (state, getters) => {
      return state.users
        .filter(user => getters.organizationUserRoles.find(role => role.userRef === user.userId))
        .filter(filterUniqueUsers);
    },
    userRolesBindingGetter: (state, getters) => userId =>
      getters.organizationUserRoles.filter(roleBinding => roleBinding.userRef === userId),
    userRolesBinding: state => state.userRolesBinding,
    invitationCreating: state => state.invitationCreating,
    invitationCreateError: state => state.invitationCreateError,
    organizationTransferring: state => state.organizationTransferring,
    organizationTransferError: state => state.organizationTransferError,
    userDeleteError: state => state.userDeleteError,
    userRolesGetter: (state, getters, rootState, rootGetters) => userId =>
      getters.userRolesBindingGetter(userId).map(binding => ({
        ...rootGetters['dictionary/roleTypeGetter'](binding.userRoleRef),
        ...binding,
      })),
    userMainRole: (state, getters) => userId =>
      getters.userRolesGetter(userId).reduce((a, c) => (a?.priority > c.priority ? a : c), null),
    activeUserRoles: (state, getters) => userId => getters.userRolesGetter(userId).filter(r => r.isActive),
    activeUserMainRole: (state, getters) => userId =>
      getters
        .userRolesGetter(userId)
        .filter(r => r.isActive)
        .reduce((a, c) => (a?.priority > c.priority ? a : c), null),
    activeUserMainRoleWithPermissions: (state, getters) => userId => {
      return {
        ...getters.activeUserMainRole(userId),
        ...getters.userRolesGetter(userId).reduce((result, role) => {
          return Object.values(PERMISSIONS).reduce((obj, key) => ({ ...obj, [key]: result[key] || role[key] }), {});
        }, {}),
      };
    },
    isAdmin: (state, getters) => userId => getters.activeUserMainRole(userId)?.userGroupRef === USER_GROUP_IDS.ADMIN,
    isOwner: (state, getters) => userId =>
      getters.activeUserMainRole(userId)?.userRoleId === USER_ROLE_IDS.OWNER_USER_ROLE_ID,
    isMember: (state, getters) => userId =>
      getters.activeUserMainRole(userId)?.userRoleId === USER_ROLE_IDS.MEMBER_USER_ROLE_ID,
    isEmptyRole: (state, getters) => userId => !getters.activeUserRoles(userId).length,
    roleId: (state, getters) => userId => getters.activeUserMainRole(userId)?.userRoleId,
    roleName: (state, getters) => userId => getters.activeUserMainRole(userId)?.userRoleName?.toLowerCase(),
    userRolesChanging: state => state.userRolesChanging,
    userRolesChangeError: state => state.userRolesChangeError,
    userUpdating: state => state.userUpdating,
    userUpdateError: state => state.userUpdateError,
    canCreateOrganization: state => state.canCreateOrganization,
  },
};
