
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProductWideDto } from '@/castapi';
import MoaImg from '@/components/shared/various/MoaImg.vue';
import { PURCHASE_OPTIONS_IDS } from '@/shared/constants/purchaseOptionsIds';

@Component({
  components: {
    MoaImg,
  },
})
export default class ProductItem extends Vue {
  @Prop({ default: () => ({}) }) item!: ProductWideDto;

  private selectedProductCode;

  data() {
    return {
      selectedProductCode:
        this.item.purchaseType === 'Lease License' ? this.item.productOptions[0].productCode : this.item.productCode,
    };
  }

  async selectProduct() {
    const selectedPrice = this.item.productOptions.find(price => price.productCode === this.selectedProductCode);
    await this.$store.dispatch('shop/addProductToCart', { ...this.item, ...selectedPrice, count: 1 });
    await this.$router.push({ name: 'shop-cart' });
  }

  get perpetualPurchaseOptionId() {
    return PURCHASE_OPTIONS_IDS.PERPETUAL_ID;
  }

  get isLoading() {
    return this.$store.getters['shop/isLoading'];
  }

  getItemProductCode(item) {
    return item?.productCode?.replace('+', '-');
  }

  get isWyg(): boolean {
    return this.item.productName.toLowerCase().includes('wyg');
  }

  get isViv(): boolean {
    return this.item.productName.toLowerCase().includes('vivien');
  }
}
