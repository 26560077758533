
import { Component, Prop, Vue } from 'vue-property-decorator';
import snackbarPlugin from '@/plugins/snackbar';

@Component
export default class EmergencyCodeDialog extends Vue {
  @Prop(Boolean) dialogVisible!: false;
  @Prop(Boolean) cheatCounterExpired!: false;
  @Prop(Boolean) emergencyCodeGenerating!: false;
  @Prop(String) emergencyCodeError!: null | string;
  @Prop(String) emergencyCode!: null | string;

  get showDialog(): boolean {
    return this.dialogVisible;
  }

  set showDialog(value: boolean) {
    if (!value) {
      this.$emit('close');
    }
  }

  closeDialog(): void {
    this.showDialog = false;
  }

  copyToClipboard() {
    const codeToCopyElement = this.$refs.codeToCopy as Vue & { value: string };
    const codeInput = codeToCopyElement.$el.querySelector('input');
    if (!codeInput) {
      throw new Error('Can not find `codeInput`');
    }
    const codeToCopy = codeToCopyElement.value;
    if (codeToCopy !== '') {
      codeInput.select();
      document.execCommand('copy');
      snackbarPlugin.showMessageExt({
        message: 'The code is successfully copied!',
        topAndCentered: false,
        color: 'primary',
      });
    } else {
      snackbarPlugin.showMessageExt({
        message: 'Failed to copy code!',
        topAndCentered: false,
        color: 'primary',
      });
    }
    this.closeDialog();
  }
}
