
import { Component, Vue } from 'vue-property-decorator';
import ShopFront from '@/components/shop/Front/Front.vue';
import Dongles from '@/components/moa-staff-admin/Dongles/Dongles.vue';
import Products from '@/components/moa-customer-portal/Products/Products.vue';
import EmptyRole from '@/components/errors/EmptyRole.vue';
import Organization from '@/components/moa-customer-portal/Organization/Organization.vue';

@Component({
  components: {
    Organization,
    Products,
    ShopFront,
    Dongles,
    EmptyRole,
  },
})
// eslint-disable-next-line vue/multi-word-component-names
export default class Home extends Vue {
  get loggedIn(): string {
    return this.$store.getters['login/loggedIn'];
  }

  get isAdmin(): boolean {
    return this.$store.getters['login/isAdmin'];
  }

  get isMember(): string {
    return this.$store.getters['login/isMember'];
  }

  get isEmptyRole(): string {
    return this.$store.getters['login/isEmptyRole'];
  }
}
