
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { UserDto } from '@/castapi';
import { required } from 'vuelidate/lib/validators';
import { Validation } from 'vuelidate';

// Plan: why we need it?
Component.registerHooks(['validations']);

@Component({})
export default class UserEditor extends Vue {
  @Prop({ default: {} }) value!: UserDto;
  @Prop({ default: false }) editOrganization!: boolean;
  @Prop({ default: true }) editable!: boolean;
  @Prop({ default: false }) dialog!: boolean;

  // noinspection JSUnusedGlobalSymbols
  private $v!: Validation & {
    user: {
      firstName: Validation & { required };
      lastName: Validation & { required };
      phoneNumber: Validation & { required };
    };
    organizationPhone: Validation & { required };
  };
  private touchMap = new WeakMap();
  private user;
  private organizationPhone: string | null = this.organization.organizationPhoneNumber;
  private organizationName: string | null = this.organization.organizationName;

  validations() {
    return {
      user: {
        firstName: { required },
        lastName: { required },
        phoneNumber: this.editOrganization ? {} : { required },
      },
      organizationPhone: this.editOrganization ? { required } : {},
    };
  }

  get firstNameValidationErrors() {
    if (!this.$v.user.firstName.$dirty) {
      return [];
    }
    const errors: string[] = [];
    if (!this.$v.user.firstName.required) {
      errors.push('This field is required');
    }
    return errors;
  }

  get lastNameValidationErrors() {
    if (!this.$v.user.lastName.$dirty) {
      return [];
    }
    const errors: string[] = [];
    if (!this.$v.user.lastName.required) {
      errors.push('This field is required');
    }
    return errors;
  }

  get userPhoneNumberValidationErrors() {
    if (!this.$v.user.phoneNumber.$dirty) {
      return [];
    }
    const errors: string[] = [];
    if (!this.$v.user.phoneNumber.required) {
      errors.push('This field is required');
    }
    return errors;
  }

  get orgPhoneNumberValidationErrors() {
    if (!this.$v.organizationPhone.$dirty) {
      return [];
    }
    const errors: string[] = [];
    if (!this.$v.organizationPhone.required) {
      errors.push('This field is required');
    }
    return errors;
  }

  delayValidation($v) {
    $v.$reset();
    if (this.touchMap.has($v)) {
      clearTimeout(this.touchMap.get($v));
    }
    this.touchMap.set($v, setTimeout($v.$touch, 800));
  }

  get organization() {
    return this.$store.getters['organizations/organization'];
  }

  data() {
    return {
      user: { ...this.value },
    };
  }

  get currentData() {
    return { ...this.user };
  }

  mounted() {
    if (this.$route.name === 'shop-checkout' || this.$route.name === 'products') {
      this.$emit('userValidationChanged', this.$v.$invalid);
    }
  }

  @Watch('currentData')
  userDataChange() {
    this.$emit('userDataChanged', this.currentData);
  }

  @Watch('$v', { deep: true })
  userChange() {
    this.$emit('userValidationChanged', this.$v.$invalid);
  }

  @Watch('organizationPhone')
  organizationPhoneChange() {
    this.$emit('organizationChanged', { organizationPhone: this.organizationPhone });
  }

  @Watch('organizationName')
  organizationNameChange() {
    this.$emit('organizationChanged', { organizationName: this.organizationName });
  }
}
