
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ConfirmDialog extends Vue {
  @Prop({ default: false }) dialogVisible!: boolean;
  @Prop({ default: 'Are you sure?' }) title!: string;
  @Prop({ default: 'Are you sure that you want to delete this item?' }) message!: string;
  @Prop({ default: () => ({ text: 'Yes', color: 'error' }) }) confirmButton!: { text: string; color: string };
  @Prop({ default: () => ({ text: 'No', color: 'gray' }) }) cancelButton!: { text: string; color: string };

  get showDialog(): boolean {
    return this.dialogVisible;
  }

  set showDialog(value: boolean) {
    if (!value) {
      this.$emit('close');
    }
  }

  closeDialog(): void {
    this.showDialog = false;
  }

  cancelClick() {
    this.closeDialog();
  }

  confirmClick() {
    this.$emit('confirmClick');
    this.closeDialog();
  }
}
