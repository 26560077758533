
import { Component, Prop, Vue } from 'vue-property-decorator';
import EventBus from '@/plugins/EventBus';
import Subtotal from '@/components/shared/cart/Subtotal.vue';
import { isTestEnv } from '@/shared/functions';

@Component({
  components: {
    Subtotal,
  },
})
export default class SubtotalCheckoutDialog extends Vue {
  @Prop(Boolean) dialogVisible!: false;
  @Prop({ default: 0 }) totalPrice!: number;
  @Prop({ default: 0 }) tax!: number;
  @Prop({ default: 0 }) discount!: number;
  @Prop({ default: false }) disabled!: boolean;
  @Prop() actionOnTotalChange!: string;
  @Prop({ default: false }) hideDiscountInput!: boolean;
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: null }) message!: null | { isError: boolean; text: string };

  get showDialog(): boolean {
    return this.dialogVisible;
  }

  set showDialog(value: boolean) {
    if (!value) {
      this.$emit('close');
    }
  }

  get isError() {
    return this.message?.isError;
  }

  isTestEnv = isTestEnv;

  closeDialog(): void {
    EventBus.$emit('clearDiscount');
    this.showDialog = false;
  }

  backToRenewDialog() {
    this.closeDialog();
    this.$emit('backToRenew');
  }

  confirm() {
    this.$emit('checkout');
  }

  testConfirm() {
    this.$emit('testCheckout');
  }
}
