import { AppLogger } from '@/logger';
import { Configuration, FeedbackApiFactory, FeedbackResponseDto, FeedbackSearchResponseDto } from '@/castapi';
import { apiConfig } from '@/shared/constants';
import { getErrorMessage } from '@/castapi/helpers';

const logger = new AppLogger('feedback state');
const getFeedbackApi = (accessToken?) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return FeedbackApiFactory(config);
};

interface FeedbackState {
  feedbacks: FeedbackSearchResponseDto[];
  feedbacksLoading: boolean;
  feedbacksLoadError: null | string;
  currentFeedback: FeedbackResponseDto | null;
  currentFeedbackLoading: boolean;
  currentFeedbackLoadError: null | string;
  feedbackAdding: boolean;
  feedbackAddError: null | string;
}

const initialState: () => FeedbackState = () => ({
  feedbacks: [],
  feedbacksLoading: false,
  feedbacksLoadError: null,
  currentFeedback: null,
  currentFeedbackLoading: false,
  currentFeedbackLoadError: null,
  feedbackAdding: false,
  feedbackAddError: null,
});

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    FEEDBACKS_LOADING(state) {
      state.feedbacks = [];
      state.feedbacksLoading = true;
      state.feedbacksLoadError = null;
    },
    FEEDBACKS_LOADED(state, feedbacks) {
      state.feedbacks = feedbacks;
      state.feedbacksLoading = false;
    },
    FEEDBACKS_LOAD_ERROR(state, payload) {
      state.feedbacksLoading = false;
      state.feedbacksLoadError = getErrorMessage(payload);
    },
    CURRENT_FEEDBACK_LOADING(state) {
      state.currentFeedback = null;
      state.currentFeedbackLoading = true;
      state.currentFeedbackLoadError = null;
    },
    CURRENT_FEEDBACK_LOADED(state, feedback) {
      state.currentFeedback = feedback;
      state.currentFeedbackLoading = false;
    },
    CURRENT_FEEDBACK_LOAD_ERROR(state, payload) {
      state.currentFeedbackLoading = false;
      state.currentFeedbackLoadError = getErrorMessage(payload);
    },
    FEEDBACK_ADDING(state) {
      state.feedbackAdding = true;
      state.feedbackAddError = null;
    },
    FEEDBACK_ADDED(state) {
      state.feedbackAdding = false;
    },
    ADD_FEEDBACK_ERROR(state, payload) {
      state.feedbackAdding = false;
      state.feedbackAddError = getErrorMessage(payload);
    },
    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE');
    },
    async loadFeedbacks({ commit, rootGetters }, payload) {
      const { limit, offset, searchText, sortBy, sortDesc } = payload || {};
      commit('FEEDBACKS_LOADING', { limit, offset, searchText });
      try {
        const response = await getFeedbackApi(rootGetters['login/accessToken']).feedbackControllerFindAll(
          limit,
          offset,
          searchText,
          sortBy,
          sortDesc,
        );
        commit('FEEDBACKS_LOADED', response.data);
      } catch (error) {
        commit('FEEDBACKS_LOAD_ERROR', error);
        logger.captureStoreError('loadFeedbacks', error, { limit, offset, searchText, sortBy, sortDesc });
      }
    },
    async getFeedback({ commit, rootGetters }, feedbackId) {
      commit('CURRENT_FEEDBACK_LOADING');
      try {
        const response = await getFeedbackApi(rootGetters['login/accessToken']).feedbackControllerGetFeedbackById(
          feedbackId,
        );
        commit('CURRENT_FEEDBACK_LOADED', response.data);
      } catch (error) {
        commit('CURRENT_FEEDBACK_LOAD_ERROR', error);
        logger.captureStoreError('getFeedback', error, { feedbackId });
      }
    },
    async addFeedback({ commit, rootGetters }, payload) {
      commit('FEEDBACK_ADDING');
      try {
        const response = await getFeedbackApi(rootGetters['login/accessToken']).feedbackControllerAddFeedback(payload);
        commit('FEEDBACK_ADDED', response.data);
      } catch (error) {
        commit('ADD_FEEDBACK_ERROR', error);
        logger.captureStoreError('addFeedback', error, { payload });
      }
    },
  },
  getters: {
    feedbacks: state => state.feedbacks,
    feedbacksLoading: state => state.feedbacksLoading,
    feedbacksLoadError: state => state.feedbacksLoadError,
    currentFeedback: state => state.currentFeedback,
    currentFeedbackLoading: state => state.currentFeedbackLoading,
    currentFeedbackLoadError: state => state.currentFeedbackLoadError,
    feedbackAdding: state => state.feedbackAdding,
    feedbackAddError: state => state.feedbackAddError,
  },
};
