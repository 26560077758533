
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MoaImg extends Vue {
  @Prop({ default: 112 }) width: undefined | number;
  @Prop({ default: 112 }) height: undefined | number;
  @Prop({ default: false }) border: undefined | boolean;
  @Prop({ default: 'transparent' }) background: undefined | string;
  @Prop({ required: true }) imgSrc: undefined | string;
  @Prop({ default: 37 }) svgWidth: undefined | number;
  @Prop({ default: 'Product image' }) alt: undefined | string;
  @Prop({ default: '' }) marginClass: undefined | string;
  @Prop({ default: 12 }) borderRadius: undefined | number;

  get imageSrc() {
    if (this.imgSrc?.toLowerCase().includes('vivien')) {
      return 'vivien-base.svg';
    } else if (this.imgSrc?.includes('wyg')) {
      return 'wyg-base.svg';
    }
    return false;
  }
}
