
import { Component, Vue } from 'vue-property-decorator';
import OrganizationInfo from '@/components/shared/organization/OrganizationInfo.vue';
import OrganizationCard from '@/components/shared/organization/OrganizationCard.vue';
import { UserDto } from '@/castapi';
import CreateOrganizationDialog from '@/components/dialogs/CreateOrganizationDialog.vue';

@Component({
  components: {
    OrganizationCard,
    OrganizationInfo,
    CreateOrganizationDialog,
  },
})
export default class Organization extends Vue {
  private createOrganizationDialogVisible = false;

  private newOrganizationName = `${this.user?.firstName} ${this.user?.lastName}`;

  get organization() {
    return this.$store.getters['organizations/organization'];
  }

  get organizationAddresses() {
    return this.$store.getters['organizations/organizationAddresses'];
  }

  get organizationChanging() {
    return this.$store.getters['organizations/organizationChanging'];
  }

  get changeOrganizationError() {
    return this.$store.getters['organizations/changeOrganizationError'];
  }

  get user(): null | UserDto {
    return this.$store.getters['login/user'];
  }

  get canCreateOrganization(): null | boolean {
    return this.$store.getters['organizations/canCreateOrganization'];
  }

  openCreateOrganizationDialog() {
    this.createOrganizationDialogVisible = true;
  }

  async createOrganization(data) {
    await this.$store.dispatch('organizations/createOrganization', data);
  }
}
