
import { Component, Vue } from 'vue-property-decorator';
import MoaAvatar from '@/components/shared/various/MoaAvatar.vue';
import { getUserFullName } from '@/shared/functions';
import { routes } from '@/router';

type NavigationLink = {
  tab: string;
  route?: string;
  dataCy: string;
  roles?: string[];
  subMenus?: NavigationLink[];
};
type ProfileLink = {
  tab: string;
  route?: string;
  dataCy: string;
};

@Component({ components: { MoaAvatar } })
export default class Header extends Vue {
  private sidebar = false;
  private dialogLegal = false;

  getUserFullName = getUserFullName;

  mounted() {
    document.body.classList.remove('disable-scrolling');
  }

  handleProfileLink(tab) {
    if (tab === 'Legal') {
      this.dialogLegal = true;
    }
  }

  navStateChanged($event) {
    return $event === true
      ? document.body.classList.add('disable-scrolling')
      : document.body.classList.remove('disable-scrolling');
  }

  get availableDemos() {
    return this.$store.getters['demo/availableDemos'];
  }

  get demoSubmenus(): NavigationLink[] {
    return this.availableDemos.map(demo => ({
      tab: demo.name?.toUpperCase(),
      route: `/demo/${demo.demoId}`,
      dataCy: `link-demo-${demo.name?.toLowerCase()}`,
    }));
  }

  get navigationLinks(): NavigationLink[] {
    return [
      {
        tab: 'Products',
        route: '/products',
        dataCy: 'link-products-top',
      },
      {
        tab: 'Downloads',
        route: '/downloads',
        dataCy: 'link-downloads-top',
      },
      {
        tab: 'People',
        route: '/people',
        dataCy: 'link-people-top',
      },
      {
        tab: 'Invoices',
        route: '/invoices',
        dataCy: 'link-invoices-top',
      },
      {
        tab: 'Store',
        route: '/shop/front',
        dataCy: 'link-store',
      },
      {
        tab: 'Demos',
        dataCy: 'link-demos',
        subMenus: this.demoSubmenus,
        roles: ['owner', 'member'],
      },
      {
        tab: 'Organization',
        route: '/organization',
        dataCy: 'link-organization',
      },
      {
        tab: 'Dongles',
        route: '/dongles',
        dataCy: 'nav-link-dongles',
      },
      {
        tab: 'Users',
        route: '/users',
        dataCy: 'nav-link-users',
      },
      {
        tab: 'Organizations',
        route: '/organizations',
        dataCy: 'nav-link-organizations',
      },
      {
        tab: 'Uploads',
        dataCy: 'nav-link-uploads',
        roles: ['admin'],
        subMenus: [
          {
            tab: 'Releases',
            route: '/uploads',
            dataCy: 'nav-link-releases',
          },
          {
            tab: 'Demos',
            route: '/admin-demos',
            dataCy: 'nav-link-admin-demos',
          },
        ],
      },
      {
        tab: 'Discounts',
        route: '/discounts',
        dataCy: 'nav-link-discounts',
      },
      {
        tab: 'Staff',
        route: '/staff',
        dataCy: 'nav-link-staff',
      },
      {
        tab: 'Orders',
        route: '/orders',
        dataCy: 'nav-link-orders',
      },
      {
        tab: 'Inventory',
        route: '/inventory',
        dataCy: 'nav-link-inventory',
      },
    ];
  }

  get user() {
    return this.$store.getters['login/user'];
  }

  get isAdmin(): boolean {
    return this.$store.getters['login/isAdmin'];
  }

  get userRoleName(): string {
    return this.$store.getters['login/roleName'];
  }

  get isNotXs(): boolean {
    return this.$vuetify.breakpoint.width > 480;
  }

  get userNavigationLinks(): NavigationLink[] {
    return this.navigationLinks.filter(link => {
      const role = this.isAdmin ? 'admin' : this.userRoleName;
      if (!link.route && link.subMenus && link.roles) {
        return link.roles.includes(role);
      }
      if (link.route) {
        const route = routes.find(r => r.path === link.route);
        if (!route) {
          throw new Error('Can not find route for navigation link');
        }
        return route.meta.roles.includes(role);
      }
      throw new Error('Can not find route for navigation link');
    });
  }

  get profileLinks(): ProfileLink[] {
    return this.$store.getters['login/loggedIn']
      ? [
          { tab: 'Profile', route: '/myprofile', dataCy: 'profile-link-top' },
          { tab: 'Legal', dataCy: 'legal-link-top' },
          { tab: 'Feedback', route: '/feedback', dataCy: 'feedback' },
          { tab: 'Sign Out', route: '/login/signout', dataCy: 'logout-link-top' },
        ]
      : [
          { tab: 'Legal', dataCy: 'legal-link-top' },
          { tab: 'Sign In', route: '/login/signin', dataCy: 'login-link-top' },
        ];
  }

  get cartProductsCount(): number {
    return this.$store.getters['shop/cartProductsCount'];
  }

  async goToCart() {
    await this.$router.push({ name: 'shop-cart' });
  }
}
