
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Snackbar extends Vue {
  get show(): boolean {
    return this.$store.getters['snackbar/show'];
  }

  set show(value: boolean) {
    if (!value) {
      this.$store.commit('snackbar/CLOSE');
    }
  }

  get message(): string {
    return this.$store.getters['snackbar/message'];
  }

  get color(): string {
    return this.$store.getters['snackbar/color'];
  }

  get topAndCentered(): string {
    return this.$store.getters['snackbar/topAndCentered'];
  }

  get timeout(): string {
    return this.$store.getters['snackbar/timeout'];
  }
}
