
import { Component, Prop, Watch } from 'vue-property-decorator';
import { AddressDto } from '@/castapi';
import AddressDisplay from '@/components/shared/address/AddressDisplay.vue';
import AddressEditorDialog from '@/components/shared/address/AddressEditorDialog.vue';
import ProjectMixin from '@/mixins/ProjectMixin';
import snackbarPlugin from '@/plugins/snackbar';

@Component({
  components: {
    AddressDisplay,
    AddressEditorDialog,
  },
})
export default class AddressList extends ProjectMixin {
  @Prop({ default: () => [] }) addresses!: AddressDto[];
  @Prop({ default: null }) initialSelectedAddress!: AddressDto | null;
  @Prop({ default: null }) addressForCopy!: AddressDto | null;
  @Prop({ default: false }) isBilling!: boolean;
  @Prop({ default: true }) editable!: boolean;
  @Prop({ default: false }) selectable!: boolean;
  @Prop({ default: false }) isAdmin!: boolean;
  @Prop({ default: false }) hideList!: boolean;
  @Prop({ default: false }) disabled!: boolean;

  private showAddDialog = false;
  private showEditDialogAddressId: null | number = null;
  private selectedAddressId: number | null | undefined = null;
  private unsubscribe;

  addDialogVisibilityChanged(value) {
    if (this.showAddDialog !== value) {
      this.showAddDialog = value;
    }
  }

  editDialogVisibilityChanged(value) {
    if (this.showEditDialogAddressId !== value) {
      this.showEditDialogAddressId = value;
    }
  }

  get organization() {
    return this.isAdmin
      ? this.$store.getters['adminOrganizations/currentOrganization']
      : this.$store.getters['organizations/organization'];
  }

  get selectedAddress() {
    return this.selectedAddressId ? this.addresses.find(address => address.addressId === this.selectedAddressId) : null;
  }

  set selectedAddress(address) {
    this.selectedAddressId = address?.addressId;
  }

  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'organizations/ADDRESS_CHANGED') {
        const message = `Address successfully ${mutation.payload || 'updated'}`;
        snackbarPlugin.showMessage(message);
        const previousSelectedAddressId = this.selectedAddress?.addressId;
        this.selectedAddress =
          this.addresses.length === 1
            ? this.addresses[0]
            : this.addresses.find(address => address.addressId === this.selectedAddress?.addressId);
        const selectedAddressId = this.selectedAddress?.addressId;
        if (selectedAddressId !== previousSelectedAddressId) {
          this.$emit('addressSelected', this.selectedAddress);
        }
      } else if (mutation.type === 'organizations/CHANGE_ADDRESS_ERROR') {
        snackbarPlugin.showMessage(
          this.$store.getters['organizations/changeAddressError'] || 'Address update failed. Please, try again later.',
        );
      }
    });
    this.initialSelectedAddressChanged();
  }

  @Watch('initialSelectedAddress')
  initialSelectedAddressChanged() {
    if (this.selectable && this.initialSelectedAddress) {
      this.selectedAddress = this.initialSelectedAddress;
    }
  }

  cancelAdd() {
    this.showAddDialog = false;
  }

  cancelEdit() {
    this.showEditDialogAddressId = null;
  }

  async saveAddress(address) {
    if (address.addressId) {
      await this.$store.dispatch('organizations/updateAddress', address);
      this.showEditDialogAddressId = null;
    } else {
      await this.$store.dispatch('organizations/addAddress', {
        ...address,
        organizationRef: this.organization.organizationId,
      });
      this.showAddDialog = false;
    }
    if (this.selectedAddressId === address.addressId) {
      this.$emit('addressSelected', address); // Make signal to parent component to update selected address
    }
  }

  addAddressClick() {
    this.showAddDialog = true;
  }

  addressSelected(address) {
    this.selectedAddressId = address.addressId;
    this.$emit('addressSelected', address);
  }

  deletable(address: AddressDto) {
    return (
      this.editable && !address.isBilling && !address.isDefault && this.addresses.filter(a => !a.isBilling).length > 1
    );
  }

  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
}
