
import { Component, Mixins } from 'vue-property-decorator';
import ProjectMixin from '@/mixins/ProjectMixin';
import InsertDongleDialog from '@/components/dialogs/InsertDongleDialog.vue';
import ProductInfo from '@/components/moa-customer-portal/Products/ProductInfo.vue';
import { DongleDto, UserDto } from '@/castapi';
import { DataTableHeader } from 'vuetify';
import { getExpiryOrSteppingDate, getUserFullName } from '@/shared/functions';
import MoaAvatar from '@/components/shared/various/MoaAvatar.vue';
import snackbarPlugin from '@/plugins/snackbar';

@Component({
  components: {
    InsertDongleDialog,
    ProductInfo,
    MoaAvatar,
  },
})
export default class Products extends Mixins(ProjectMixin) {
  private insertDongleDialogVisible = false;
  private search = '';
  private drawer = false;
  private unsubscribe;
  private headers: DataTableHeader[] = [
    { text: 'Dongle code', align: 'start', value: 'serialCode' },
    { text: 'Subscription', value: 'expiryDate' },
    { text: 'Assigned to', value: 'userRef' },
  ];

  get dongleActivities() {
    return this.$store.getters['dongles/dongleActivities'];
  }

  get dongleActivitiesLoading() {
    return this.$store.getters['dongles/dongleActivitiesLoading'];
  }

  get dongleActivitiesLoadError() {
    return this.$store.getters['dongles/dongleActivitiesLoadError'];
  }

  get activeUserMainRoleWithPermissions() {
    return this.$store.getters['login/activeUserMainRoleWithPermissions'];
  }

  get canManageOrganization() {
    return this.activeUserMainRoleWithPermissions.canManageOrganization;
  }

  get products(): Array<DongleDto> {
    return this.$store.getters['dongles/dongles'];
  }

  get productsLoading(): boolean {
    return this.$store.getters['dongles/donglesLoading'];
  }

  get productsLoadError(): string | null {
    return this.$store.getters['dongles/donglesLoadError'];
  }

  get people(): UserDto[] {
    return this.$store.getters['organizations/organizationUsers'];
  }

  assigneeAvatar(dongle) {
    const user = this.people.find(p => p.userId === dongle.userRef);
    return user ? user.avatar : undefined;
  }

  assigneeFullName(dongle) {
    const user = this.people.find(p => p.userId === dongle.userRef);
    return user ? getUserFullName(user) : 'Unassigned';
  }

  get organization() {
    return this.$store.getters['organizations/organization'];
  }

  openInsertDongleDialog() {
    this.insertDongleDialogVisible = true;
  }

  async handleInsertDongle(serialCode: string) {
    const data = {
      serialCode,
      organizationId: this.organization.organizationId,
    };
    await this.$store.dispatch('dongles/insertDongle', data);
    await this.$store.dispatch('dongles/loadDongles');
  }

  async openDrawer(dongle: DongleDto) {
    this.$store.commit('dongles/SELECT_DONGLE', dongle);
    this.drawer = !this.drawer;
    await this.$store.dispatch('shop/getDongleRenewUpgradeOptions', dongle.dongleId);
    await this.$store.dispatch('dongles/loadDongleActivities', dongle.dongleId);
  }

  async created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'dongles/DONGLE_INSERTED') {
        snackbarPlugin.showMessage('Dongle successfully inserted.');
      } else if (mutation.type === 'dongles/DONGLE_INSERT_ERROR') {
        snackbarPlugin.showMessage(this.$store.getters['dongles/dongleInsertError']);
      }
    });
    await this.$store.dispatch('dongles/loadDongles');
  }

  customSort(items: DongleDto[], sortBy: string, sortDesc: string): DongleDto[] {
    const sortField = sortBy.length && sortBy[0];
    const desc = sortDesc.length && sortDesc[0];
    if (sortField) {
      return items.sort((item1, item2) => {
        const sign = desc ? -1 : 1;
        return this.getSortFieldValue(item1, sortField) >= this.getSortFieldValue(item2, sortField) ? sign : -sign;
      });
    }
    return items;
  }

  getSortFieldValue(item, sortField) {
    if (sortField === 'expiryDate') {
      return new Date(getExpiryOrSteppingDate(item));
    } else if (sortField === 'userRef') {
      return this.assigneeFullName(item);
    } else if (Object.keys(item).includes(sortField)) {
      return item[sortField];
    }
    return 0;
  }

  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
}
