
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VForm, VInput } from '@/shared/rules/rules';

@Component({})
export default class UploadPhotoDialog extends Vue {
  @Prop(Boolean) dialogVisible!: false;
  @Prop({ default: () => ({}) }) uploadCallback!: (file: string) => Promise<void>;

  private formValid = true;
  private previewUrl: null | string = null;
  private photo: null | string = null;
  private photoUploading = false;
  private error: null | string = null;

  get showDialog(): boolean {
    return this.dialogVisible;
  }

  set showDialog(value: boolean) {
    if (!value) {
      this.$emit('close');
    }
  }

  get sizeIsCorrect() {
    // 10mb * 4/3 (because base 64 increasing size)
    return (this.photo?.length || 0) < 13981013;
  }

  closeDialog(): void {
    (this.$refs.form as VForm)?.reset();
    if (this.$refs.fileInput) {
      (this.$refs.fileInput as VInput).value = null;
    }
    this.previewUrl = null;
    this.photo = null;
    this.error = null;
    this.showDialog = false;
  }

  onDragOrChoose(event) {
    const [file] = (event.dataTransfer || event.target).files;
    if (file) {
      this.previewUrl = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => (this.photo = reader.result?.toString() || null);
    }
  }

  async uploadPhoto() {
    if (!this.photo) {
      this.error = 'The photo is required';
      return;
    }
    this.photoUploading = true;
    try {
      await this.uploadCallback(this.photo);
    } finally {
      this.photoUploading = false;
      this.closeDialog();
    }
  }
}
