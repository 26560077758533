
import { rules } from '@/shared/rules/rules';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CreateOrganizationDialog extends Vue {
  @Prop({ default: false }) dialogVisible!: boolean;
  @Prop({ default: null }) organizationName!: string;

  private name: null | string = null;
  private countryRef = 1;

  async created() {
    this.name = this.organizationName;
    await this.$store.dispatch('dictionary/getCountries');
  }

  get showDialog(): boolean {
    return this.dialogVisible;
  }

  set showDialog(value: boolean) {
    if (!value) {
      this.$emit('close');
    }
  }

  get rules() {
    return rules;
  }

  get countries() {
    return this.$store.getters['dictionary/countries'];
  }

  closeDialog(): void {
    this.showDialog = false;
  }

  cancelClick() {
    this.closeDialog();
  }

  confirmClick() {
    this.$emit('create', { organizationName: this.name, countryRef: this.countryRef });
    this.closeDialog();
  }
}
