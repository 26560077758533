
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { OrganizationDto } from '@/castapi';
import { VForm } from '@/shared/rules/rules';
import { required } from 'vuelidate/lib/validators';
import { Validation } from 'vuelidate';

@Component
export default class OrganizationEditor extends Vue {
  @Prop({ default: {} }) value!: OrganizationDto;
  @Prop({ default: true }) editable!: boolean;

  // noinspection JSUnusedGlobalSymbols
  private $v!: {
    organization: {
      organizationName: Validation & { required };
    };
  };
  private touchMap = new WeakMap();
  private formValid = false;
  private organization: OrganizationDto | null = null;

  validations() {
    return {
      organization: {
        organizationName: { required },
      },
    };
  }

  get organizationNameValidationErrors() {
    if (!this.$v.organization.organizationName.$dirty) {
      return [];
    }
    const errors: string[] = [];
    if (!this.$v.organization.organizationName.required) {
      errors.push('This field is required');
    }
    return errors;
  }

  delayValidation($v) {
    $v.$reset();
    if (this.touchMap.has($v)) {
      clearTimeout(this.touchMap.get($v));
    }
    this.touchMap.set($v, setTimeout($v.$touch, 800));
  }

  created() {
    this.organization = { ...this.value };
  }

  mounted() {
    (this.$refs.form as VForm).validate();
  }

  get fieldsValid() {
    return this.formValid;
  }

  get currentData() {
    return { ...this.organization };
  }

  @Watch('fieldsValid')
  organizationValid() {
    this.$emit('validationChanged', this.fieldsValid);
  }

  @Watch('currentData')
  organizationChange() {
    this.$emit('dataChanged', this.currentData);
  }
}
