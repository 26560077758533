const initialState = (): {
  show: boolean;
  message: string;
  color: string;
  topAndCentered: boolean;
  timeout: number;
} => ({
  show: false,
  message: '',
  color: '',
  topAndCentered: false,
  timeout: 3000,
});

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SHOW_MESSAGE(state, payload) {
      state.show = true;
      state.message = payload.message;
      state.color = payload.color;
      state.topAndCentered = payload.topAndCentered;
      state.timeout = payload.timeout || 3000;
    },
    CLOSE(state) {
      state.show = false;
    },
    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE');
    },
  },
  getters: {
    show: state => state.show,
    message: state => state.message,
    color: state => state.color,
    topAndCentered: state => state.topAndCentered,
    timeout: state => state.timeout,
  },
};
