// Source https://github.com/urnix/vue-google-places

import loadJS from 'load-js';
import Vue from 'vue';

interface GMapsApi {
  // maps: {
  // Map: typeof google.maps.Map;
  // Marker: typeof google.maps.Marker;
  // LatLng: typeof google.maps.LatLng;
  // etc...
  // };
  element: HTMLInputElement;
}

let loadModulePromise: Promise<GMapsApi | null> | null = null;
const loadModule = (options: { key: string; v: string }): Promise<GMapsApi | null> => {
  if (Object.prototype.hasOwnProperty.call(window, 'google')) {
    return Promise.resolve(null);
  }
  const opt = Object.assign(
    {
      libraries: 'places',
    },
    options,
  );
  const parameters = Object.keys(opt)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(opt[key])}`)
    .join('&');
  const url = `https://maps.googleapis.com/maps/api/js?${parameters}&callback=Function.prototype`;
  return loadJS(url).catch(e => {
    loadModulePromise = null;
    console.warn('Error loading google maps script', e);
  });
};

export default Vue.extend({
  props: {
    apiKey: { type: String, required: true },
    // country: String,
    enableGeolocation: Boolean,
    enableGeocode: Boolean,
    // value: String,
    version: { type: String, required: true },
    // addressFields: Object,
    types: { type: [Array, String], required: true },
    fields: { type: Array, required: true },
    skipEnterPressListener: Boolean,
    // appendIcon: String,
    // component: HTMLElement,
    // $gMapsApiPromiseLazy: Function,
  },
  data(): {
    geoLocateSet: boolean;
    prepared: boolean;
    textValue: string;
    currentPlace: google.maps.places.PlaceResult | null;
    // eslint-disable-next-line @typescript-eslint/ban-types
    enterPressListener: ((this: HTMLInputElement, ev: KeyboardEvent) => void) | null;
    hasDownBeenPressed: boolean;
    autocomplete: google.maps.places.Autocomplete | null;
    parsedAddressFields: {
      street_number: string;
      route: string;
      locality: string;
      administrative_area_level_1: string;
      administrative_area_level_2: string;
      administrative_area_level_3: string;
      postal_code: string;
    };
    element: HTMLInputElement | null;
    componentRestrictions: { country: string } | null;
    geocoder: google.maps.Geocoder | null;
  } {
    return {
      geoLocateSet: false,
      prepared: false,
      textValue: '',
      currentPlace: null,
      enterPressListener: null,
      hasDownBeenPressed: false,
      autocomplete: null,
      parsedAddressFields: {
        street_number: '',
        route: '',
        locality: '',
        administrative_area_level_1: '',
        administrative_area_level_2: '',
        administrative_area_level_3: '',
        postal_code: '',
      },
      element: null,
      componentRestrictions: null,
      geocoder: null,
    };
  },
  computed: {
    // getAppendIcon(): string {
    //   return this.currentPlace ? 'close' : this.appendIcon;
    // },
  },
  watch: {
    country(newVal: string) {
      if (newVal && this.autocomplete) {
        this.autocomplete.setComponentRestrictions({ country: newVal });
      }
    },

    types(newVal: string | string[]) {
      if (newVal) {
        const types: string[] = Array.isArray(newVal) ? newVal : [newVal];
        this.autocomplete?.setTypes(types);
      }
    },
  },
  created() {
    // STUB for vue2-google-maps and vue-google-places work together
    // Plan: change this to @google/map module in future
    // if (typeof this.$gMapsApiPromiseLazy === 'function') {
    //   loadModulePromise = this.$gMapsApiPromiseLazy();
    // } else {
    loadModulePromise =
      loadModulePromise ||
      loadModule({
        key: this.apiKey,
        v: this.version,
      });
    // }
    this.parsedAddressFields = Object.assign(
      {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        administrative_area_level_2: 'short_name',
        administrative_area_level_3: 'short_name',
        postal_code: 'short_name',
      },
      // this.addressFields,
    );
  },
  async mounted(): Promise<void> {
    if (!loadModulePromise) {
      throw new Error('loadModulePromise is not defined');
    }
    await loadModulePromise;
    this.setupGoogle();
  },
  methods: {
    // enableEnterKey(input) {
    //   /* Store original event listener */
    //   const _addEventListener = input.addEventListener;
    //
    //   input.addEventListener = (type, listener) => {
    //     if (type === 'keydown') {
    //       /* Store existing listener function */
    //       const _listener = listener;
    //       listener = event => {
    //         /* Simulate a 'down arrow' keypress if no address has been selected */
    //         const suggestion_selected = document.getElementsByClassName('pac-item-selected').length;
    //         if (event.which === 13 && !suggestion_selected) {
    //           // let e = { keyCode: 40, which: 40 }
    //           // if (window.KeyboardEvent) {
    //           //   e = new window.KeyboardEvent('keydown', e)
    //           // }
    //           // _listener.apply(input, [e])
    //           const event2 = JSON.parse(JSON.stringify(event));
    //           event2.which = 40;
    //           event2.keyCode = 40;
    //           _listener.apply(input, [event2]);
    //         }
    //         _listener.apply(input, [event]);
    //       };
    //     }
    //     _addEventListener.apply(input, [type, listener]);
    //   };
    // },
    setupInput() {
      this.element?.addEventListener('keydown', e => {
        if (e.keyCode === 40) {
          this.hasDownBeenPressed = true;
        }
      });

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      this.enterPressListener = (e: KeyboardEvent & { hasRanOnce?: boolean }) => {
        e.cancelBubble = true;
        // If enter key, or tab key
        if (e.keyCode === 13 || e.keyCode === 9) {
          // If user isn't navigating using arrows and this hasn't ran yet
          if (!self.hasDownBeenPressed && !e.hasRanOnce) {
            let event: Partial<KeyboardEvent> & { hasRanOnce?: boolean } = { keyCode: 40, hasRanOnce: true };
            if (window.KeyboardEvent) {
              event = new window.KeyboardEvent('keydown', event);
            }
            google.maps.event.trigger(e.target, 'keydown', event);
          }
        }
      };
      if (!this.skipEnterPressListener) {
        this.element?.addEventListener<'keydown'>('keydown', this.enterPressListener);
      }

      this.element?.addEventListener('focus', () => {
        this.hasDownBeenPressed = false;
      });
    },
    setupGoogle() {
      const options: {
        fields?: string[];
        types?: string[];
        componentRestrictions?: {
          country: string;
        };
      } = {};

      options.fields = this.fields as string[];

      if (typeof this.types === 'string') {
        options.types = [this.types];
      } else if (Array.isArray(this.types)) {
        options.types = this.types;
      }

      // if (this.country) {
      //   options.componentRestrictions = {
      //     country: this.country,
      //   };
      // }

      this.element = this.$el as HTMLInputElement;
      // this.element = this.$refs.input.$el || this.$refs.input
      if (this.element?.tagName !== 'INPUT') {
        this.element = this.element?.querySelector('input');
      }
      if (!this.element) {
        // console.warn(`Input element was not found in ${this.component}`);
        return;
      }
      this.autocomplete = new window.google.maps.places.Autocomplete(this.element, options);
      // this.enableEnterKey(this.element)
      this.setupInput();

      this.autocomplete.addListener('place_changed', this.onPlaceChange); // skipcq: JS-0387
      this.geocoder = new window.google.maps.Geocoder();
      this.geoLocate();
    },
    parsePlace(place) {
      const returnData: {
        country?: string;
        country_code?: string;
        latitude?: string;
        longitude?: string;
        name?: string;
        formatted_address?: string;
        photos?: string;
        place_id?: string;
        place?: string;
      } = {};

      if (place.formatted_address !== undefined) {
        this.textValue = place.formatted_address;
        // document.getElementById(this.id).value = place.formatted_address
      }

      if (place.address_components !== undefined) {
        // Get each component of the address from the place details
        for (const address_component of place.address_components) {
          const addressType = address_component.types[0];
          if (this.parsedAddressFields[addressType]) {
            returnData[addressType] = address_component[this.parsedAddressFields[addressType]];
          }
          if (addressType === 'country') {
            returnData.country = address_component.long_name;
            returnData.country_code = address_component.short_name;
          }
        }

        returnData.latitude = place.geometry.location.lat();
        returnData.longitude = place.geometry.location.lng();

        // additional fields available in google places results
        returnData.name = place.name;
        returnData.formatted_address = place.formatted_address;
        returnData.photos = place.photos;
        returnData.place_id = place.place_id;
        returnData.place = place;
      }
      return returnData;
    },
    changePlace(place) {
      this.$emit('placeChanged', place);
      this.textValue = place ? this.textValue : '';
      this.$emit('input', this.textValue);
      this.currentPlace = place;
    },
    onPlaceChange(): void {
      this.hasDownBeenPressed = false;
      const place = this.autocomplete?.getPlace();

      if (!place?.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        this.$emit('noResult', place);
        return;
      }

      const pl = this.parsePlace(place);
      this.changePlace(pl);
    },
    geoLocate() {
      if (this.enableGeolocation && !this.geoLocateSet) {
        if (!navigator.geolocation) {
          return;
        }
        navigator.geolocation.getCurrentPosition(position => {
          const geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          const circle = new window.google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy,
          });
          if (this.enableGeocode) {
            this.geocoder?.geocode({ location: geolocation }, (results, status) => {
              if (status === 'OK' && results.length) {
                this.textValue = results[1].formatted_address;
                const pl = this.parsePlace(results[1]);
                this.changePlace(pl);
              }
            });
          }
          this.autocomplete?.setBounds(circle.getBounds());
          this.geoLocateSet = true;
        });
      }
    },
    renderInput(h) {
      return h('input', {
        attrs: {
          type: 'text',
          class: 'v-google-places__input',
          value: this.textValue,
          ...this.$attrs,
        },
      });
    },
  },
  render(h) {
    const inputNode = this.$slots.default || [this.renderInput(h)];
    return h(
      'div',
      {
        class: 'v-google-places',
      },
      inputNode,
    );
  },
  beforeDestroy() {
    if (this.enterPressListener) {
      this.element?.removeEventListener<'keydown'>('keydown', this.enterPressListener);
    }
  },
});
