
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Subtotal from '@/components/shared/cart/Subtotal.vue';
import { AddressDto, UserDto } from '@/castapi';
import AddressList from '@/components/shared/address/AddressList.vue';
import AddressEditor from '@/components/shared/address/AddressEditor.vue';
import UserEditor from '@/components/shared/user/UserEditor.vue';

@Component({
  components: {
    Subtotal,
    AddressList,
    AddressEditor,
    UserEditor,
  },
})
export default class EditOrderAddressDialog extends Vue {
  @Prop(Boolean) dialogVisible!: false;
  @Prop({ default: () => [] }) addressList!: AddressDto[];
  @Prop({ default: null }) defaultAddress!: AddressDto;
  @Prop({ default: null }) addressToCopy!: AddressDto;
  @Prop({ default: null }) user!: UserDto;
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: false }) orderValid!: boolean;
  @Prop({ default: null }) error!: Error;

  private valid = false;

  get showDialog(): boolean {
    return this.dialogVisible;
  }

  set showDialog(value: boolean) {
    if (!value) {
      this.$emit('close');
    }
  }

  get confirmButtonDisabled() {
    return this.isLoading || !this.orderValid || this.valid;
  }

  saveAddress(address) {
    this.$emit('saveAddress', address);
  }

  closeDialog(): void {
    this.showDialog = false;
  }

  backToRenewDialog() {
    this.closeDialog();
    this.$emit('backToRenew');
  }

  confirm() {
    this.closeDialog();
    this.$emit('confirm');
  }

  created() {
    this.addressesChanged();
  }

  @Watch('addressList')
  addressesChanged() {
    if (this.addressList.length && this.dialogVisible) {
      this.addressSelected(this.addressList[0]);
    }
  }

  @Watch('dialogVisible')
  dialogVisibilityChanged() {
    if (this.addressList.length && this.dialogVisible) {
      this.addressSelected(this.addressList[0]);
    }
  }

  addressSelected(address) {
    this.$emit('addressSelected', address);
  }
}
