
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { Validation } from 'vuelidate';

// Plan: why we need it?
Component.registerHooks(['validations']);

@Component
export default class InsertDongleDialog extends Vue {
  @Prop(Boolean) dialogVisible!: false;

  // noinspection JSUnusedGlobalSymbols
  private $v!: Validation & {
    code: Validation & { required };
  };
  private touchMap = new WeakMap();
  private formValid = true;
  private code = '';

  validations() {
    return {
      code: { required },
    };
  }

  delayValidation($v) {
    $v.$reset();
    if (this.touchMap.has($v)) {
      clearTimeout(this.touchMap.get($v));
    }
    this.touchMap.set($v, setTimeout($v.$touch, 800));
  }

  resetValidation() {
    this.$v.$reset();
  }

  get codeValidationErrors() {
    if (!this.$v.code.$dirty) {
      return [];
    }
    const errors: string[] = [];
    if (!this.$v.code.required) {
      errors.push('This field is required');
    }
    return errors;
  }

  get codeIsInvalid() {
    return this.$v.$anyError;
  }

  get showDialog(): boolean {
    return this.dialogVisible;
  }

  set showDialog(value: boolean) {
    if (!value) {
      this.$emit('close');
    }
  }

  closeDialog(): void {
    this.$v.$reset();
    this.showDialog = false;
  }

  addCode() {
    if (!this.codeIsInvalid) {
      this.$emit('insertDongle', this.code);
      this.closeDialog();
    }
  }
  @Watch('dialogVisible')
  onDialogVisibleChanged(value: boolean) {
    if (value) {
      this.$v.$reset();
    }
  }
}
