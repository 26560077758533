
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { AddressDto, RegionDto } from '@/castapi';
import ConfirmDialog from '@/components/shared/dialogs/ConfirmDialog.vue';
import { findBestMatch } from 'string-similarity';
import ProjectMixin from '@/mixins/ProjectMixin';
import { prepareToServer } from '@/castapi/helpers';
import { VForm } from '@/shared/rules/rules';
import { required } from 'vuelidate/lib/validators';
import VueGooglePlaces from '@/components/google-places-autocomplete/VueGooglePlaces';
import { Validation } from 'vuelidate';
import { CountryWithNativeDto } from '@/store/modules/dictionary';

@Component({ components: { ConfirmDialog, VueGooglePlaces } })
export default class AddressEditor extends Mixins(ProjectMixin) {
  @Prop({ default: () => ({}) }) value!: AddressDto;
  @Prop({ default: null }) addressForCopy!: AddressDto;
  @Prop({ default: false }) isBilling!: boolean;
  @Prop({ default: false }) deletable!: boolean;
  @Prop({ default: false }) inlineMode!: boolean;
  @Prop({ default: false }) disabled!: boolean;

  // noinspection JSUnusedGlobalSymbols
  private $v!: Validation & {
    address1: Validation & { required };
    apt: Validation;
    city: Validation & { required };
    province: Validation & { required };
    postalCode: Validation & { required };
    countryRef: Validation & { required };
    isDefault: Validation;
  };
  private touchMap = new WeakMap();
  private showConfirmDialog = false;
  private googleApiKey = process.env.VUE_APP_GOOGLE_API_KEY;
  private geolocationPermission = 'denied';
  private findPlaceByGeolocationInProgress = false;
  private autocompleteComponentKey = (Math.random() + 1).toString(36).substring(4);
  private formValid = false;
  private provinceToSet = '';
  private countryRef: number | null = null;
  private province: string | null = null;
  private postalCode: string | null = null;
  private apt: string | null = null;
  private city: string | null = null;
  private address1: string | null = null;
  private isDefault: boolean | null = false;
  private isSameAsShipping = false;
  private unsubscribe;
  private addressComponents = {
    street_number: { key: 'short_name', name: 'shippingAddress1', value: '' },
    route: { key: 'long_name', name: 'shippingAddress1', value: '' },
    locality: { key: 'long_name', name: 'city', value: '' },
    neighborhood: { key: 'long_name', name: 'city', value: '' },
    postal_town: { key: 'long_name', name: 'city', value: '' },
    administrative_area_level_1: { key: 'short_name', name: 'province', value: '' },
    administrative_area_level_2: { key: 'short_name', name: 'province', value: '' },
    country: { key: 'long_name', name: 'countryRef', value: '' },
    postal_code: { key: 'short_name', name: 'postalCode', value: '' },
  };
  private oldValue: AddressDto | null = null;

  validations() {
    return {
      address1: { required },
      countryRef: { required },
      province: { required },
      postalCode: { required },
      city: { required },
    };
  }

  delayValidation($v) {
    $v.$reset();
    if (this.touchMap.has($v)) {
      clearTimeout(this.touchMap.get($v));
    }
    this.touchMap.set($v, setTimeout($v.$touch, 800));
  }

  get address1ValidationErrors() {
    const errors: string[] = [];
    if (!this.$v.address1.$dirty) {
      return errors;
    }
    if (!this.$v.address1.required) {
      errors.push('This field is required');
    }
    return errors;
  }

  get countryRefValidationErrors() {
    if (!this.$v.countryRef.$dirty) {
      return [];
    }
    const errors: string[] = [];
    if (!this.$v.countryRef.required) {
      errors.push('This field is required');
    }
    return errors;
  }

  get provinceValidationErrors() {
    if (!this.$v.province.$dirty) {
      return [];
    }
    const errors: string[] = [];
    if (!this.$v.province.required) {
      errors.push('This field is required');
    }
    return errors;
  }

  get postalCodeValidationErrors() {
    if (!this.$v.postalCode.$dirty) {
      return [];
    }
    const errors: string[] = [];
    if (!this.$v.postalCode.required) {
      errors.push('This field is required');
    }
    return errors;
  }

  get cityValidationErrors() {
    if (!this.$v.city.$dirty) {
      return [];
    }
    const errors: string[] = [];
    if (!this.$v.city.required) {
      errors.push('This field is required');
    }
    return errors;
  }

  @Watch('fieldsValid')
  addressValid() {
    this.$emit('addressValid', this.fieldsValid);
  }

  @Watch('currentAddress')
  addressChange() {
    this.$emit('addressChange', this.currentAddress);
  }

  @Watch('isSameAsShipping')
  isSameAsDefaultChange() {
    if (this.isSameAsShipping) {
      this.oldValue = { ...this.currentAddress } as AddressDto;

      this.countryRef = this.addressForCopy.countryRef;
      this.province = this.addressForCopy.province;
      this.postalCode = this.addressForCopy.postalCode;
      this.apt = this.addressForCopy.apt;
      this.city = this.addressForCopy.city;
      this.address1 = this.addressForCopy.address1;
    } else {
      this.countryRef = this.oldValue?.countryRef || null;
      this.province = this.oldValue?.province || null;
      this.postalCode = this.oldValue?.postalCode || null;
      this.apt = this.oldValue?.apt || null;
      this.city = this.oldValue?.city || null;
      this.address1 = this.oldValue?.address1 || null;
    }
  }

  get currentAddress() {
    return {
      ...this.value,
      countryRef: this.countryRef,
      province: this.province,
      postalCode: this.postalCode,
      city: this.city,
      apt: this.apt || null,
      address1: this.address1,
      isDefault: this.isDefault,
      isBilling: this.isBilling,
    };
  }

  get isNewAddress() {
    return !this.value?.addressId;
  }

  get countries(): null | CountryWithNativeDto[] {
    return this.$store.getters['dictionary/countries'];
  }

  get countriesLoading(): boolean {
    return this.$store.getters['dictionary/countriesLoading'];
  }

  get countriesLoadError(): boolean {
    return this.$store.getters['dictionary/countriesLoadError'];
  }

  get regions(): null | RegionDto[] {
    return (this.countryRef && this.$store.getters['dictionary/regions'][this.countryRef]) || null;
  }

  get regionsLoading(): boolean {
    return this.$store.getters['dictionary/regionsLoading'];
  }

  get regionsLoadError(): boolean {
    return this.$store.getters['dictionary/regionsLoadError'];
  }

  get fieldsValid() {
    return this.formValid;
  }

  get addressChanging() {
    return this.$store.getters['organizations/addressChanging'];
  }

  get changeAddressError() {
    return this.$store.getters['organizations/changeAddressError'];
  }

  async onPlaceChanged({ place }): Promise<void> {
    this.findPlaceByGeolocationInProgress = false;
    this.clearForm();
    Object.keys(this.addressComponents).forEach(key => (this.addressComponents[key].value = ''));
    place.address_components.forEach(component => {
      const addressType = component.types[0];
      if (this.addressComponents[addressType]) {
        this.addressComponents[addressType].value = component[this.addressComponents[addressType].key];
      }
    });
    if (this.addressComponents.administrative_area_level_1.value) {
      this.provinceToSet = this.addressComponents.administrative_area_level_1.value;
    } else if (this.addressComponents.administrative_area_level_2.value) {
      this.provinceToSet = this.addressComponents.administrative_area_level_2.value;
    }
    const countryId = this.findCountry(this.addressComponents.country.value);
    if (countryId) {
      this.countryRef = countryId;
      await this.selectCountry(countryId);
      this.setProvince();
    }
    if (this.addressComponents.locality.value) {
      this.city = this.addressComponents.locality.value;
    } else if (this.addressComponents.neighborhood.value) {
      this.city = this.addressComponents.neighborhood.value;
    } else if (this.addressComponents.postal_town.value) {
      this.city = this.addressComponents.postal_town.value;
    } else if (
      this.addressComponents.administrative_area_level_2.value &&
      this.provinceToSet !== this.addressComponents.administrative_area_level_2.value
    ) {
      this.city = this.addressComponents.administrative_area_level_2.value;
    }
    if (this.addressComponents.postal_code.value) {
      this.postalCode = this.addressComponents.postal_code.value;
    }
    if (this.addressComponents.route.value) {
      const streetNumber = this.addressComponents.street_number.value;
      this.address1 = `${streetNumber ? `${streetNumber} ` : ''}${this.addressComponents.route.value}`;
    }
    (this.$refs.form as VForm).validate();
  }

  clearForm() {
    this.countryRef = null;
    this.province = null;
    this.postalCode = null;
    this.apt = null;
    this.city = null;
    this.address1 = null;
  }

  async selectCountry(countryId: number) {
    this.province = '';
    await this.$store.dispatch('dictionary/getCountryRegions', countryId);
  }

  async created(): Promise<void> {
    this.countryRef = this.value?.countryRef || null;
    this.province = this.value?.province || null;
    this.postalCode = this.value?.postalCode || null;
    this.apt = this.value?.apt || null;
    this.city = this.value?.city || null;
    this.address1 = this.value?.address1 || null;
    this.isDefault = this.value?.isDefault || false;
    this.oldValue = { ...this.value };
    await this.$store.dispatch('dictionary/getCountries');
    await this.$store.dispatch('dictionary/getCountryRegions', this.countryRef);
    this.$emit('addressValid', this.fieldsValid);

    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'dictionary/REGIONS_LOADED') {
        this.setProvince();
      }
    });

    const permission = await navigator.permissions.query({ name: 'geolocation' });
    this.geolocationPermission = permission.state;
  }

  saveClick() {
    if (this.fieldsValid) {
      this.$emit('saveClick', prepareToServer(this.currentAddress));
    }
  }

  private findCountry(countryName: string): null | number {
    if (!this.countries) {
      return null;
    }
    const matchesNative = findBestMatch(countryName, this.countries.map(r => r.native || '') || []);
    const matchesName = findBestMatch(countryName, this.countries.map(r => r.name || '') || []);
    const matches = matchesNative.bestMatch.rating > matchesName.bestMatch.rating ? matchesNative : matchesName;
    return matches.bestMatch.rating ? this.countries[matches.bestMatchIndex].id : null;
  }

  setProvince() {
    if (!this.regions?.length) {
      // don't try if `regions` haven't loaded yet
      return;
    }
    const names = this.regions.map(r => r.name);
    const provinceMatchesByName = findBestMatch(this.provinceToSet, names);
    if (provinceMatchesByName.bestMatch.rating) {
      this.province = this.regions[provinceMatchesByName.bestMatchIndex].name;
    }
    const provinceMatchesByISO2 = findBestMatch(
      this.provinceToSet,
      this.regions.map(r => r.iso2),
    );
    if (
      provinceMatchesByISO2.bestMatch.rating &&
      provinceMatchesByISO2.bestMatch.rating > provinceMatchesByName.bestMatch.rating
    ) {
      this.province = this.regions[provinceMatchesByISO2.bestMatchIndex].name;
    }
  }

  deleteClick() {
    this.showConfirmDialog = true;
  }

  async confirmDelete() {
    this.showConfirmDialog = false;
    await this.$store.dispatch('organizations/deleteAddress', this.value);
  }

  cancelDelete() {
    this.showConfirmDialog = false;
  }

  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
}
